import Amplify from "aws-amplify";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/bootstrap-vue";
import "./assets/css/style.scss";
import Vuelidate from "vuelidate";
import VueMask from "v-mask";
// import IdleVue from "idle-vue";

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(VueMask);
// const eventsHub = new Vue();
// Vue.use(IdleVue, {
//   eventEmitter: eventsHub,
//   store,
//   idleTime: 240000, // 5 minutes
//   startAtIdle: false,
// });

Amplify.configure({
  "aws_project_region": "eu-west-2",
  "aws_appsync_graphqlEndpoint": "https://nf4dnry3xjgqpnvmbebjo7tidu.appsync-api.eu-west-2.amazonaws.com/graphql",
  "aws_appsync_region": "eu-west-2",
  "aws_appsync_authenticationType": "API_KEY",
  "aws_appsync_apiKey": "da2-6pn3dk5kgrejhlt6zfl4whms6m"
});

new Vue({
  router,
  store,
  // vuetify,
  render: (h) => h(App),
}).$mount("#app");
