<template>
  <b-overlay :show="loading">
    <b-form class="my-3" @submit.stop.prevent="onSubmit">
      <Breadcrumb :page="5"></Breadcrumb>
      <b-card title="About You" class="mb-3">
        <b-card-text>
          <div class="row">
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label for="firstName">First name</label>
                <b-form-input
                  v-model="form.firstname"
                  id="firstName"
                  maxlength="255"
                  placeholder="Enter first name"
                  :state="validateState('firstname')"
                ></b-form-input>
                <b-form-invalid-feedback
                  >First name required</b-form-invalid-feedback
                >
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label for="lastName">Last name</label>
                <b-form-input
                  v-model="form.lastname"
                  id="lastName"
                  maxlength="255"
                  placeholder="Enter last name"
                  :state="validateState('lastname')"
                ></b-form-input>
                <b-form-invalid-feedback
                  >Last name required</b-form-invalid-feedback
                >
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col form-group">
              <label for="age">Age</label>
              <b-form-input
                v-model="$v.form.age.value.$model"
                :state="validateState('age')"
                aria-describedby="input-1-live-age"
                id="age"
                placeholder="Enter age"
                min="18"
                max="100"
              ></b-form-input>
              <b-form-invalid-feedback
                >Value must be between 18-100</b-form-invalid-feedback
              >
            </div>
          </div>

          <div class="row">
            <div class="col form-group">
              <b-form-group
                label="Please state your sex at birth"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-radio-group
                  v-model="form.sex"
                  size="sm"
                  :options="[
                    {
                      text: 'Female',
                      value: '2',
                    },
                    {
                      text: 'Male',
                      value: '3',
                    },
                  ]"
                  :state="validateState('sex')"
                  :aria-describedby="ariaDescribedby"
                  name="radio-options"
                ></b-form-radio-group>
                <b-form-invalid-feedback :state="validateState('sex')"
                  >Choose Male or Female</b-form-invalid-feedback
                >
              </b-form-group>
              <small class="form-text text-muted">
                <b-link v-b-modal.modal-center-sex>Why do we ask this?</b-link>
              </small>
            </div>
          </div>

          <div>
            <b-modal
              id="modal-center-sex"
              centered
              title="Sex at birth"
              ok-only
            >
              <p>
                We require this information as your sex will affect some of the
                risk scores we use to estimate your health status.
              </p>
            </b-modal>
          </div>

          <div class="row">
            <div class="col form-group">
              <label for="ethnicity">Select your ethnic group</label>
              <b-form-select
                v-model="form.ethnicity.value"
                :options="ethnicityOptions"
                :state="validateState('ethnicity')"
              ></b-form-select>
              <b-form-invalid-feedback
                >Ethnicity is required</b-form-invalid-feedback
              >
              <small class="form-text text-muted">
                <b-link v-b-modal.modal-center-ethnicity
                  >Why do we ask this?</b-link
                >
              </small>
            </div>
          </div>
          <div>
            <b-modal
              id="modal-center-ethnicity"
              centered
              title="What is your ethnicity?"
              ok-only
            >
              <p>
                The reason we ask this question is because research indicates
                that certain populations are at a higher risk of heart disease
                relative to others. Understanding individual's ethnicity
                therefore allows us to give more bespoke and accurate
                recommendations. If you are not sure or would rather not answer,
                please select the option 'Other ethnic group'.
              </p>
            </b-modal>
          </div>

          <!-- <div class="row">
            <div class="col form-group">
              <label for="jobRole">Current job role</label>
              <b-form-select
                v-model="form.occupation"
                :options="[
                  {
                    text: 'Account Manager',
                    value: '53',
                  },
                  {
                    text: 'Accountant',
                    value: '124',
                  },
                  {
                    text: 'Accounts',
                    value: '69',
                  },
                ]"
              ></b-form-select>
            </div>
          </div> -->
        </b-card-text>
        <b-card-footer>
          <b-button
            @click="setStep(4)"
            class="btn btn-hruk-btn"
            variant="primary"
            >Previous</b-button
          >
          <b-button
            type="submit"
            class="btn btn-hruk-btn mb-2 float-right"
            variant="primary"
            >Continue</b-button
          >
        </b-card-footer>
      </b-card>
    </b-form>
  </b-overlay>
</template>

<script>
import { minValue, maxValue } from "vuelidate/lib/validators";
import * as QuestionTemplate from "./../modules/QuestionTemplate";
import * as Answer from "./../modules/Answer";
import * as Patient from "./../modules/Patient";
import { required } from "vuelidate/lib/validators";
import Breadcrumb from "../components/Breadcrumb";
export default {
  components: {
    Breadcrumb,
  },
  data: () => ({
    form: {
      sex: null,
      firstname: null,
      lastname: null,
      // occupation: null,
      ethnicity: { id: 416, value: null },
      dobYear: null,
      dobMonth: null,
      dobDay: null,
      age: { id: 515, value: null },
    },
    loading: false,
    ethnicityOptions: null,
    answers: [],
  }),
  async created() {
    this.$store.dispatch("setPageNumber", 5);
    await QuestionTemplate.questionTemplate();
    await Answer.getAnswers();
    if (this.$store.state.patient !== null) {
      const patient = await Patient.getPatient(
        this.$store.state.patient.patient_id
      );
      this.form.sex = patient[0].sex_id;
      this.form.firstname = patient[0].firstname;
      this.form.lastname = patient[0].lastname;
      // this.form.occupation = patient[0].occupation_id;
    }

    this.ethnicityOptions = this.getOptions(
      this.form.ethnicity.id,
      this.$store.state.template
    );
    this.form.ethnicity.value = this.getAnswer(
      this.form.ethnicity.id,
      this.$store.state.answers
    );
    this.form.age.value = this.getAnswer(
      this.form.age.id,
      this.$store.state.answers
    );
  },
  validations: {
    form: {
      age: {
        value: {
          required,
          minValue: minValue(18),
          maxValue: maxValue(100),
        },
      },
      firstname: {
        required,
      },
      lastname: {
        required,
      },
      sex: {
        required,
      },
      ethnicity: {
        value: {
          required,
        },
      },
    },
  },
  methods: {
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.loading = true;
      let formDataSubmitted = await this.saveFormData();
      if (!formDataSubmitted) {
        // 2nd Attempt
        await new Promise((r) => setTimeout(r, 2000));
        formDataSubmitted = await this.saveFormData();
        if (!formDataSubmitted) {
          this.makeToast(
            "danger",
            "We have experienced a problem, please try again"
          );
          this.loading = false;
          return;
        }
      }
    },
    async saveFormData() {
      try {
        await Patient.savePatient({
          firstname: this.form.firstname,
          lastname: this.form.lastname,
          sex_id: this.form.sex,
          date_of_birth: this.form.age.value,
          // occupation_id: this.form.occupation,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.ethnicity.id,
          answer: this.form.ethnicity.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.age.id,
          answer: this.form.age.value,
        });
        this.loading = false;
        this.setStep(6);
        return true;
      } catch (e) {
        return false;
      }
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
    getOptions(id, template) {
      const options = template
        .filter((template) => template.id === id)
        .map((template) => ({
          text: template.question_option,
          value: template.question_option_id,
        }));
      return options;
    },
    getAnswer(id, answers) {
      if (answers) {
        const answer = answers
          .filter((answer) => id === answer.question_id)
          .map((answer) => answer.answer);
        return answer.length > 0 ? answer[0] : null;
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateStateBoolean(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(`${message}`, {
        title: `Error`,
        variant: variant,
        solid: true,
      });
    },
  },
  computed: {
    state() {
      return Boolean(this.value);
    },
  },
};
</script>
