<template>
  <b-overlay :show="loading">
    <b-form class="my-3" @submit.stop.prevent="onSubmit">
      <Breadcrumb :page="10"></Breadcrumb>
      <b-card title="Activity Levels Continued" class="mb-3">
        <b-card-text>
          <div class="row">
            <div class="col form-group">
              <label for="active"
                >There are places I can go to be active during the working
                day</label
              >
              <b-form-select
                v-model="form.placesToBeActive.value"
                :options="placesToBeActiveOptions"
              ></b-form-select>
              <small class="form-text text-muted">
                This could be somewhere to go for a walk such as a local park,
                or a gym.
              </small>
            </div>
          </div>
          <div class="row">
            <div class="col form-group">
              <label for="employee"
                >My employer encourages me to be more active</label
              >
              <b-form-select
                v-model="form.encourageToBeActive.value"
                :options="encourageToBeActiveOptions"
              ></b-form-select>
              <small class="form-text text-muted">
                This may be through intiatives such as the cycle to work scheme,
                discounted gym membership, and by encouraging regular breaks
                throughout the day.
              </small>
            </div>
          </div>

          <div class="row">
            <div class="col form-group">
              <b-form-group
                label="What reasons, if any, do you feel prevent you from being more
              active?"
              >
                <b-form-checkbox-group
                  v-model="form.reasonsToBeActive.value"
                  :options="reasonsToBeActiveOptions"
                  stacked
                  multiple
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>
          <div class="row" v-if="form.reasonsToBeActive.value.includes(247)">
            <div class="col form-group">
              <label for="moreActiveOther"
                >Other reasons that prevents you from being more active</label
              >
              <b-form-input
                v-model="form.reasonsToBeActiveOther.value"
                placeholder="Enter reasons"
                maxlength="255"
              ></b-form-input>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col form-group">
              <label for="physicalActivityReasons"
                >Is there anything about your environment, at work or otherwise
                , that helps or prevents you from getting enough physical
                activity?</label
              >
              <b-form-input
                v-model="form.environment.value"
                maxlength="255"
                id="physicalActivityReasons"
              ></b-form-input>
            </div>
          </div> -->
        </b-card-text>
        <b-card-footer>
          <b-button
            @click="setStep(9)"
            class="btn btn-hruk-btn"
            variant="primary"
            >Previous</b-button
          >
          <b-button
            type="submit"
            class="btn btn-hruk-btn mb-2 float-right"
            variant="primary"
            >Continue</b-button
          >
        </b-card-footer>
      </b-card>
    </b-form>
  </b-overlay>
</template>

<script>
import * as Answer from "./../modules/Answer";
import * as QuestionTemplate from "./../modules/QuestionTemplate";
import Breadcrumb from "../components/Breadcrumb";
export default {
  components: {
    Breadcrumb,
  },
  data: () => ({
    form: {
      placesToBeActive: { id: 440, value: null },
      encourageToBeActive: { id: 441, value: null },
      // environment: { id: 444, value: null },
      active: null,
      reasonsToBeActive: { id: 443, value: [] },
      reasonsToBeActiveOther: { id: 443, value: null },
    },
    placesToBeActiveOptions: null,
    encourageToBeActiveOptions: null,
    reasonsToBeActiveOptions: null,
    loading: false,
  }),
  async created() {
    this.$store.dispatch("setPageNumber", 10);
    await QuestionTemplate.questionTemplate();
    await Answer.getAnswers();

    this.placesToBeActiveOptions = this.getOptions(
      this.form.placesToBeActive.id,
      this.$store.state.template
    );
    this.form.placesToBeActive.value = this.getAnswer(
      this.form.placesToBeActive.id,
      this.$store.state.answers
    );
    this.encourageToBeActiveOptions = this.getOptions(
      this.form.encourageToBeActive.id,
      this.$store.state.template
    );
    this.form.encourageToBeActive.value = this.getAnswer(
      this.form.encourageToBeActive.id,
      this.$store.state.answers
    );
    // this.form.environment.value = this.getAnswer(
    //   this.form.environment.id,
    //   this.$store.state.answers
    // );
    this.reasonsToBeActiveOptions = this.getOptions(
      this.form.reasonsToBeActive.id,
      this.$store.state.template
    );
    this.form.reasonsToBeActive.value = this.getMultiSelectAnswer(
      this.form.reasonsToBeActive.id,
      this.$store.state.answers
    );
    this.form.reasonsToBeActiveOther.value = this.getAnswerDetails(
      this.form.reasonsToBeActiveOther.id,
      this.$store.state.answers
    );
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      let formDataSubmitted = await this.saveFormData();
      if (!formDataSubmitted) {
        // 2nd Attempt
        await new Promise((r) => setTimeout(r, 2000));
        formDataSubmitted = await this.saveFormData();
        if (!formDataSubmitted) {
          this.makeToast(
            "danger",
            "We have experienced a problem, please try again"
          );
          this.loading = false;
          return;
        }
      }
    },
    async saveFormData() {
      try {
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.placesToBeActive.id,
          answer: this.form.placesToBeActive.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.encourageToBeActive.id,
          answer: this.form.encourageToBeActive.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.reasonsToBeActive.id,
          answer: this.form.reasonsToBeActive.value,
          details: this.form.reasonsToBeActiveOther.value,
        });
        this.loading = false;
        this.setStep(11);
        return true;
      } catch (e) {
        return false;
      }
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
    getOptions(id, template) {
      const options = template
        .filter((template) => template.id === id)
        .map((template) => ({
          text: template.question_option,
          value: template.question_option_id,
        }));
      return options;
    },
    getAnswer(id, answers) {
      if (answers) {
        const answer = answers
          .filter((answer) => id === answer.question_id)
          .map((answer) => answer.answer);
        return answer.length > 0 ? answer[0] : null;
      }
    },
    getMultiSelectAnswer(id, answers) {
      const answer = answers
        .filter((answer) => id === answer.question_id && answer.answer !== null)
        .map((answer) => answer.answer);
      return answer.length > 0 ? JSON.parse(answer[0]) : [];
    },
    getAnswerDetails(id, answers) {
      const answer = answers
        .filter((answer) => id === answer.question_id)
        .map((answer) => answer.details);
      return answer.length > 0 ? answer[0] : null;
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(`${message}`, {
        title: `Error`,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
