<template>
  <b-overlay :show="loading">
    <b-form class="my-3" @submit.stop.prevent="onSubmit">
      <Breadcrumb :page="9"></Breadcrumb>
      <b-card title="Activity Levels" class="mb-3">
        <b-card-text>
          <div class="form-group row">
            <label class="col-md-8 col-form-label">
              In a typical day, how many hours do you spend sitting?<b-icon
                v-b-modal.modal-center-sitting
                icon="question-circle-fill"
                color="#6300bd"
                class="ml-2"
              ></b-icon
            ></label>
            <div class="col-md-4">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.hoursSitting.value"
                  :options="hoursSittingOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-8 col-form-label">
              Do you take regular breaks from sitting down throughout the
              day?</label
            >
            <div class="col-md-4">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.regularBreaks.value"
                  :options="regularBreaksOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-8 col-form-label">
              Are you generally active outside of your working hours?<b-icon
                v-b-modal.modal-center-active
                icon="question-circle-fill"
                color="#6300bd"
                class="ml-2"
              ></b-icon
            ></label>
            <div class="col-md-4">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.activeOutsideWorkingHours.value"
                  :options="activeOutsideWorkingHoursOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
            <b-modal
              id="modal-center-active"
              centered
              title="Active outside of working hours"
              ok-only
            >
              <p>
                Things that would not count as being active include watching TV
                or sitting at a computer, whilst more active tasks will include
                cleaning, exercising, or gardening.
              </p>
            </b-modal>
          </div>

          <b-modal
            id="modal-center-sitting"
            centered
            title="Time spent sitting"
            ok-only
          >
            <p>This includes commuting, working and leisure time.</p>
          </b-modal>
        </b-card-text>
        <b-card-footer>
          <b-button
            @click="setStep(8)"
            class="btn btn-hruk-btn"
            variant="primary"
            >Previous</b-button
          >
          <b-button
            type="submit"
            class="btn btn-hruk-btn mb-2 float-right"
            variant="primary"
            >Continue</b-button
          >
        </b-card-footer>
      </b-card>
    </b-form>
  </b-overlay>
</template>

<script>
import * as QuestionTemplate from "./../modules/QuestionTemplate";
import * as Answer from "./../modules/Answer";
import Breadcrumb from "../components/Breadcrumb";
export default {
  components: {
    Breadcrumb,
  },
  data: () => ({
    form: {
      hoursSitting: { id: 438, value: null },
      regularBreaks: { id: 439, value: null },
      activeOutsideWorkingHours: { id: 442, value: null },
      active: null,
    },
    hoursSittingOptions: null,
    regularBreaksOptions: null,
    activeOutsideWorkingHoursOptions: null,
    loading: false,
  }),
  async created() {
    this.$store.dispatch("setPageNumber", 9);
    await QuestionTemplate.questionTemplate();
    await Answer.getAnswers();

    this.hoursSittingOptions = this.getOptions(
      this.form.hoursSitting.id,
      this.$store.state.template
    );
    this.form.hoursSitting.value = this.getAnswer(
      this.form.hoursSitting.id,
      this.$store.state.answers
    );
    this.regularBreaksOptions = this.getOptions(
      this.form.regularBreaks.id,
      this.$store.state.template
    );
    this.form.regularBreaks.value = this.getAnswer(
      this.form.regularBreaks.id,
      this.$store.state.answers
    );
    this.activeOutsideWorkingHoursOptions = this.getOptions(
      this.form.activeOutsideWorkingHours.id,
      this.$store.state.template
    );
    this.form.activeOutsideWorkingHours.value = this.getAnswer(
      this.form.activeOutsideWorkingHours.id,
      this.$store.state.answers
    );
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      let formDataSubmitted = await this.saveFormData();
      if (!formDataSubmitted) {
        // 2nd Attempt
        await new Promise((r) => setTimeout(r, 2000));
        formDataSubmitted = await this.saveFormData();
        if (!formDataSubmitted) {
          this.makeToast(
            "danger",
            "We have experienced a problem, please try again"
          );
          this.loading = false;
          return;
        }
      }
    },
    async saveFormData() {
      try {
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.hoursSitting.id,
          answer: this.form.hoursSitting.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.regularBreaks.id,
          answer: this.form.regularBreaks.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.activeOutsideWorkingHours.id,
          answer: this.form.activeOutsideWorkingHours.value,
        });
        this.loading = false;
        this.setStep(10);
        return true;
      } catch (e) {
        return false;
      }
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
    getOptions(id, template) {
      const options = template
        .filter((template) => template.id === id)
        .map((template) => ({
          text: template.question_option,
          value: template.question_option_id,
        }));
      return options;
    },
    getAnswer(id, answers) {
      if (answers) {
        const answer = answers
          .filter((answer) => id === answer.question_id)
          .map((answer) => answer.answer);
        return answer.length > 0 ? answer[0] : null;
      }
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(`${message}`, {
        title: `Error`,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
