/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const update_answers = /* GraphQL */ `
  mutation Update_answers($updateAnswersInput: updateAnswersInput!) {
    update_answers(updateAnswersInput: $updateAnswersInput) {
      id
      question_id
      session_id
      answer
      details
    }
  }
`;
export const update_patient = /* GraphQL */ `
  mutation Update_patient($updatePatientInput: updatePatientInput!) {
    update_patient(updatePatientInput: $updatePatientInput) {
      id
      sex_id
      occupation_id
      contact_id
      firstname
      lastname
      known_as
      mobile
      email
    }
  }
`;
export const update_answers_pipeline = /* GraphQL */ `
  mutation Update_answers_pipeline($updateAnswersInput: updateAnswersInput!) {
    update_answers_pipeline(updateAnswersInput: $updateAnswersInput) {
      id
      question_id
      session_id
      answer
      details
    }
  }
`;
export const create_patient = /* GraphQL */ `
  mutation Create_patient($createPatientInput: createPatientInput!) {
    create_patient(createPatientInput: $createPatientInput) {
      id
      patient_id
      case_session_id
    }
  }
`;
export const update_case_appointment = /* GraphQL */ `
  mutation Update_case_appointment(
    $updateCaseAppointmentInput: updateCaseAppointmentInput!
  ) {
    update_case_appointment(
      updateCaseAppointmentInput: $updateCaseAppointmentInput
    ) {
      id
      patient_id
      status_id
    }
  }
`;
