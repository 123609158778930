<template>
  <b-overlay :show="loading">
    <b-form class="my-3" @submit.stop.prevent="onSubmit">
      <Breadcrumb :page="12"></Breadcrumb>
      <b-card title="Dietary Habits" class="mb-3">
        <b-card-text>
          <!-- <div class="row">
            <div class="col form-group">
              <label for="dietScore"
                >Please drag the slider below to indicate how healthy you feel
                your current diet is (1 = very unhealthy, 10 = very
                healthy).</label
              >
              <div>
                <b>Score: {{ form.dietScore.value }}</b>
              </div>
              <b-form-input
                v-model="form.dietScore.value"
                type="range"
                min="1"
                max="10"
                step="1"
              ></b-form-input>
            </div>
          </div> -->
          <div class="form-group row">
            <label class="col-md-8 col-form-label">
              On an average day, how many portions of fruit and vegetables do
              you eat?<b-icon
                v-b-modal.modal-center-fruit-veg
                icon="question-circle-fill"
                color="#6300bd"
                class="ml-2"
              ></b-icon
            ></label>
            <div class="col-md-4">
              <!-- <b-button-group> -->
              <!-- <b-form-radio-group
                  v-model="form.fruitVeg.value"
                  :options="fruitVegOptions"
                  buttons
                ></b-form-radio-group> -->
              <b-form-select
                v-model="form.fruitVeg.value"
                :options="fruitVegOptions"
              ></b-form-select>
              <!-- </b-button-group> -->
            </div>
          </div>
          <div>
            <b-modal
              id="modal-center-fruit-veg"
              centered
              title="Fruit & Vegetables"
              ok-only
            >
              <p>One portion would comprise of:</p>
              <ul style="padding-left: 10px">
                <li>
                  80 grams of fresh, canned or frozen fruit and veg (e.g. 2
                  plums, 1 apple, 1 banana, 3 heaped tablespoons of cooked veg).
                </li>
                <li>
                  80 grams of beans and pulses (e.g. 3 heaped tablespoons of
                  baked beans, chickpeas or kidney beans).
                </li>
                <li>
                  30 grams of dried fruit (e.g. 1 heaped tablespoon of raisins).
                </li>
                <li>
                  150ml of unsweetened fruit juice or smoothies = 1 portion (one
                  of these can only be counted once in a day) Potatoes do not
                  count as one of your 5 a day.
                </li>
              </ul>
            </b-modal>
          </div>
          <p>
            <b
              >In an average week, please state how often you would eat one
              portion of each of the following foods:</b
            >
          </p>
          <div class="form-group row">
            <label class="col-md-8 col-form-label"
              >Oily fish?<b-icon
                v-b-modal.modal-center-oily-fish
                icon="question-circle-fill"
                color="#6300bd"
                class="ml-2"
              ></b-icon
            ></label>
            <div class="col-md-4">
              <b-form-select
                v-model="form.oilyFish.value"
                :options="oilyFishOptions"
              ></b-form-select>
            </div>
            <div>
              <b-modal
                id="modal-center-oily-fish"
                centered
                title="Oily Fish"
                ok-only
              >
                <p>
                  Oily fish includes salmon, mackerel, sardines, herring and
                  trout.
                </p>
                <p>
                  Tuna, cod, haddock, sea bass and plaice are not categorised as
                  oily fish.
                </p>
              </b-modal>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-8 col-form-label">
              Processed meat products?<b-icon
                v-b-modal.modal-center-processed-meat
                icon="question-circle-fill"
                color="#6300bd"
                class="ml-2"
              ></b-icon
            ></label>
            <div class="col-md-4">
              <b-form-select
                v-model="form.processedMeat.value"
                :options="processedMeatOptions"
              ></b-form-select>
            </div>
            <div>
              <b-modal
                id="modal-center-processed-meat"
                centered
                title="Processed Meat"
                ok-only
              >
                <p>
                  Processed meat is meat that has been preserved by curing,
                  salting, smoking or adding preservatives. Examples include
                  bacon, sausages, ham, salami, meat pâtés and canned meats,
                  such as corned beef.
                </p>
              </b-modal>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-8 col-form-label"
              >Red meat?<b-icon
                v-b-modal.modal-center-red-meat
                icon="question-circle-fill"
                color="#6300bd"
                class="ml-2"
              ></b-icon
            ></label>
            <div class="col-md-4">
              <b-form-select
                v-model="form.redMeat.value"
                :options="redMeatOptions"
                buttons
              ></b-form-select>
            </div>
            <div>
              <b-modal
                id="modal-center-red-meat"
                centered
                title="Red Meat"
                ok-only
              >
                <p>
                  Red meat includes unprocessed beef, lamb, mutton, pork, veal,
                  venison and goat.
                </p>
              </b-modal>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-8 col-form-label"
              >Wholegrain food?<b-icon
                v-b-modal.modal-center-wholegrain
                icon="question-circle-fill"
                color="#6300bd"
                class="ml-2"
              ></b-icon
            ></label>
            <div class="col-md-4">
              <b-form-select
                v-model="form.wholegrainFood.value"
                :options="wholegrainFoodOptions"
                buttons
              ></b-form-select>
            </div>
            <div>
              <b-modal
                id="modal-center-wholegrain"
                centered
                title="Wholegrain Food"
                ok-only
              >
                <p>
                  One portion of wholegrain food would comprise of; 1-2 of
                  wholemeal bread, 45-65g of wholewheat pasta before cooking,
                  cooking, and 35-55g brown rice before cooking.
                </p>
              </b-modal>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-8 col-form-label">
              Less healthy snacks?<b-icon
                v-b-modal.modal-center-snacks
                icon="question-circle-fill"
                color="#6300bd"
                class="ml-2"
              ></b-icon
            ></label>
            <div class="col-md-4">
              <b-form-select
                v-model="form.snacks.value"
                :options="snacksOptions"
                buttons
              ></b-form-select>
            </div>
            <div>
              <b-modal id="modal-center-snacks" centered title="Snacks" ok-only>
                <p>
                  This could include snacks such as chocolate, crisps, salted
                  nuts, sweets, biscuits and cake.
                </p>
              </b-modal>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-8 col-form-label">Takeaways?</label>
            <div class="col-md-4">
              <b-form-select
                v-model="form.takeaway.value"
                :options="takeawayOptions"
              ></b-form-select>
            </div>
          </div>
        </b-card-text>
        <b-card-footer>
          <b-button
            @click="setStep(11)"
            class="btn btn-hruk-btn"
            variant="primary"
            >Previous</b-button
          >
          <b-button
            type="submit"
            class="btn btn-hruk-btn mb-2 float-right"
            variant="primary"
            >Continue</b-button
          >
        </b-card-footer>
      </b-card>
    </b-form>
  </b-overlay>
</template>

<script>
import * as QuestionTemplate from "./../modules/QuestionTemplate";
import * as Answer from "./../modules/Answer";
import Breadcrumb from "../components/Breadcrumb";
export default {
  components: {
    Breadcrumb,
  },
  data: () => ({
    form: {
      // dietScore: { id: 445, value: 1 },
      fruitVeg: { id: 448, value: null },
      oilyFish: { id: 446, value: null },
      processedMeat: { id: 454, value: null },
      redMeat: { id: 455, value: null },
      wholegrainFood: { id: 447, value: null },
      snacks: { id: 449, value: null },
      takeaway: { id: 450, value: null },
    },
    fruitVegOptions: null,
    oilyFishOptions: null,
    processedMeatOptions: null,
    redMeatOptions: null,
    wholegrainFoodOptions: null,
    snacksOptions: null,
    takeawayOptions: null,
    // dietScoreOptions: null,
    loading: false,
  }),
  async created() {
    this.$store.dispatch("setPageNumber", 12);
    await QuestionTemplate.questionTemplate();
    await Answer.getAnswers();

    // this.dietScoreOptions = this.getOptions(
    //   this.form.dietScore.id,
    //   this.$store.state.template
    // );

    // const dietScoreVal = this.getAnswer(
    //   this.form.dietScore.id,
    //   this.$store.state.answers
    // );

    // if (dietScoreVal) {
    //   const dietScore = this.dietScoreOptions.filter(function (option) {
    //     return option.value === dietScoreVal;
    //   });
    //   this.form.dietScore.value = dietScore[0].text;
    // }
    this.fruitVegOptions = this.getOptions(
      this.form.fruitVeg.id,
      this.$store.state.template
    );
    this.form.fruitVeg.value = this.getAnswer(
      this.form.fruitVeg.id,
      this.$store.state.answers
    );
    this.oilyFishOptions = this.getOptions(
      this.form.oilyFish.id,
      this.$store.state.template
    );
    this.form.oilyFish.value = this.getAnswer(
      this.form.oilyFish.id,
      this.$store.state.answers
    );
    this.processedMeatOptions = this.getOptions(
      this.form.processedMeat.id,
      this.$store.state.template
    );
    this.form.processedMeat.value = this.getAnswer(
      this.form.processedMeat.id,
      this.$store.state.answers
    );
    this.redMeatOptions = this.getOptions(
      this.form.redMeat.id,
      this.$store.state.template
    );
    this.form.redMeat.value = this.getAnswer(
      this.form.redMeat.id,
      this.$store.state.answers
    );
    this.wholegrainFoodOptions = this.getOptions(
      this.form.wholegrainFood.id,
      this.$store.state.template
    );
    this.form.wholegrainFood.value = this.getAnswer(
      this.form.wholegrainFood.id,
      this.$store.state.answers
    );
    this.snacksOptions = this.getOptions(
      this.form.snacks.id,
      this.$store.state.template
    );
    this.form.snacks.value = this.getAnswer(
      this.form.snacks.id,
      this.$store.state.answers
    );
    this.takeawayOptions = this.getOptions(
      this.form.takeaway.id,
      this.$store.state.template
    );
    this.form.takeaway.value = this.getAnswer(
      this.form.takeaway.id,
      this.$store.state.answers
    );
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      let formDataSubmitted = await this.saveFormData();
      if (!formDataSubmitted) {
        // 2nd Attempt
        await new Promise((r) => setTimeout(r, 2000));
        formDataSubmitted = await this.saveFormData();
        if (!formDataSubmitted) {
          this.makeToast(
            "danger",
            "We have experienced a problem, please try again"
          );
          this.loading = false;
          return;
        }
      }
    },
    async saveFormData() {
      try {
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.fruitVeg.id,
          answer: this.form.fruitVeg.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.oilyFish.id,
          answer: this.form.oilyFish.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.processedMeat.id,
          answer: this.form.processedMeat.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.redMeat.id,
          answer: this.form.redMeat.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.wholegrainFood.id,
          answer: this.form.wholegrainFood.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.snacks.id,
          answer: this.form.snacks.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.takeaway.id,
          answer: this.form.takeaway.value,
        });
        this.loading = false;
        this.setStep(13);
        return true;
      } catch (e) {
        return false;
      }
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
    getOptions(id, template) {
      const options = template
        .filter((template) => template.id === id)
        .map((template) => ({
          text: template.question_option,
          value: template.question_option_id,
        }));
      return options;
    },
    getAnswer(id, answers) {
      if (answers) {
        const answer = answers
          .filter((answer) => id === answer.question_id)
          .map((answer) => answer.answer);
        return answer.length > 0 ? answer[0] : null;
      }
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(`${message}`, {
        title: `Error`,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
