<template>
  <b-overlay :show="loading">
    <b-form class="my-3" @submit.stop.prevent="onSubmit">
      <Breadcrumb :page="17"></Breadcrumb>
      <b-card title="Sleep and Stress" class="mb-3">
        <b-card-text>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label">
              Typically how many hours of sleep do you get each night?</label
            >
            <div class="col-sm-6">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.hoursSleep.value"
                  :options="hoursSleepOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label">
              In general, how do you rate the quality of your sleep?</label
            >
            <div class="col-sm-6">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.qualitySleep.value"
                  :options="qualitySleepOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label">
              How often do you feel stressed at work?</label
            >
            <div class="col-sm-6">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.stressedAtWork.value"
                  :options="stressedAtWorkOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label">
              Over the last 6-8 weeks, how would you rate your stress
              levels?</label
            >
            <div class="col-sm-6">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.stressLevels.value"
                  :options="stressLevelsOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
          </div>
          <!-- <div class="form-group">
            <label for="hours_sleep"
              >On average, how many hours of sleep do you get each night,
              excluding time where you were awake in bed?</label
            >
            <b-form-input
              v-model="form.hoursSleep.value"
              type="number"
              placeholder="Enter hours sleep"
              min="0"
              max="24"
            ></b-form-input>
          </div> -->
          <!-- <div class="form-group">
            <label for="wakeUp"
              >On average, how many times do you wake up each night?</label
            >
            <b-form-select
              v-model="form.wakeUp.value"
              :options="wakeUpOptions"
            ></b-form-select>
          </div> -->
          <!-- <div class="form-group">
            <b-form-group
              label="If any, which of the following disturb your sleep on a regular basis?"
            >
              <b-form-checkbox-group
                v-model="form.disturbSleep.value"
                :options="disturbSleepOptions"
                stacked
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
          <div class="form-group" v-if="form.disturbSleep.value.includes(319)">
            <label for="sleepDisturbedOther"
              >Other reasons your sleep is disturbed</label
            >
            <b-form-input
              v-model="form.disturbSleepOther.value"
              maxlength="255"
              placeholder="Enter reasons"
            ></b-form-input>
          </div> -->
        </b-card-text>
        <b-card-footer>
          <b-button
            @click="setStep(16)"
            class="btn btn-hruk-btn"
            variant="primary"
            >Previous</b-button
          >
          <b-button
            type="submit"
            class="btn btn-hruk-btn mb-2 float-right"
            variant="primary"
            >Continue</b-button
          >
        </b-card-footer>
      </b-card>
    </b-form>
  </b-overlay>
</template>
<script>
import * as QuestionTemplate from "./../modules/QuestionTemplate";
import * as Answer from "./../modules/Answer";
import Breadcrumb from "../components/Breadcrumb";
export default {
  components: {
    Breadcrumb,
  },
  data: () => ({
    form: {
      hoursSleep: { id: 483, value: null },
      qualitySleep: { id: 539, value: null },
      // wakeUp: { id: 484, value: null },
      sufficentTime: null,
      regularBreaks: null,
      resources: null,
      workingRelationship: null,
      expectations: null,
      organisationalChanges: null,
      // disturbSleep: { id: 485, value: [] },
      // disturbSleepOther: { id: 485, value: null },
      stressLevels: { id: 540, value: null },
      stressedAtWork: { id: 543, value: null },
      absence: null,
      sickdays: null,
    },
    // disturbSleepOptions: null,
    hoursSleepOptions: null,
    qualitySleepOptions: null,
    stressLevelsOptions: null,
    stressedAtWorkOptions: null,
    // wakeUpOptions: null,
    loading: false,
  }),
  async created() {
    this.$store.dispatch("setPageNumber", 17);
    await QuestionTemplate.questionTemplate();
    await Answer.getAnswers();
    this.hoursSleepOptions = this.getOptions(
      this.form.hoursSleep.id,
      this.$store.state.template
    );
    this.qualitySleepOptions = this.getOptions(
      this.form.qualitySleep.id,
      this.$store.state.template
    );
    this.stressLevelsOptions = this.getOptions(
      this.form.stressLevels.id,
      this.$store.state.template
    );
    this.stressedAtWorkOptions = this.getOptions(
      this.form.stressedAtWork.id,
      this.$store.state.template
    );
    this.form.hoursSleep.value = this.getAnswer(
      this.form.hoursSleep.id,
      this.$store.state.answers
    );
    this.form.qualitySleep.value = this.getAnswer(
      this.form.qualitySleep.id,
      this.$store.state.answers
    );
    this.form.stressedAtWork.value = this.getAnswer(
      this.form.stressedAtWork.id,
      this.$store.state.answers
    );
    this.form.stressLevels.value = this.getAnswer(
      this.form.stressLevels.id,
      this.$store.state.answers
    );
    // this.wakeUpOptions = this.getOptions(
    //   this.form.wakeUp.id,
    //   this.$store.state.template
    // );
    // this.form.wakeUp.value = this.getAnswer(
    //   this.form.wakeUp.id,
    //   this.$store.state.answers
    // );
    // this.form.disturbSleep.value = this.getMultiSelectAnswer(
    //   this.form.disturbSleep.id,
    //   this.$store.state.answers
    // );
    // this.disturbSleepOptions = this.getOptions(
    //   this.form.disturbSleep.id,
    //   this.$store.state.template
    // );
    // this.form.disturbSleepOther.value = this.getAnswerDetails(
    //   this.form.disturbSleepOther.id,
    //   this.$store.state.answers
    // );
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      let formDataSubmitted = await this.saveFormData();
      if (!formDataSubmitted) {
        // 2nd Attempt
        await new Promise((r) => setTimeout(r, 2000));
        formDataSubmitted = await this.saveFormData();
        if (!formDataSubmitted) {
          this.makeToast(
            "danger",
            "We have experienced a problem, please try again"
          );
          this.loading = false;
          return;
        }
      }
    },
    async saveFormData() {
      try {
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.hoursSleep.id,
          answer: this.form.hoursSleep.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.qualitySleep.id,
          answer: this.form.qualitySleep.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.stressLevels.id,
          answer: this.form.stressLevels.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.stressedAtWork.id,
          answer: this.form.stressedAtWork.value,
        });
        this.loading = false;
        this.setStep(18);
      } catch (e) {
        return false;
      }
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
    getOptions(id, template) {
      const options = template
        .filter((template) => template.id === id)
        .map((template) => ({
          text: template.question_option,
          value: template.question_option_id,
        }));
      return options;
    },
    getAnswer(id, answers) {
      if (answers) {
        const answer = answers
          .filter((answer) => id === answer.question_id)
          .map((answer) => answer.answer);
        return answer.length > 0 ? answer[0] : null;
      }
    },
    getAnswerDetails(id, answers) {
      const answer = answers
        .filter((answer) => id === answer.question_id)
        .map((answer) => answer.details);
      return answer.length > 0 ? answer[0] : null;
    },
    getMultiSelectAnswer(id, answers) {
      const answer = answers
        .filter((answer) => id === answer.question_id && answer.answer !== null)
        .map((answer) => answer.answer);
      return answer.length > 0 ? JSON.parse(answer[0]) : [];
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(`${message}`, {
        title: `Error`,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
