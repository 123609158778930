<template>
  <div>
    <nav class="navbar">
      <!-- Navbar content -->
      <a href="/"
        ><img
          src="./assets/white-logo-210.jpeg"
          alt="Heart Research UK logo"
          height="79"
      /></a>
      <div class="d-block d-sm-none text-center">
        <!-- <b-button pill class="purple-color-btn mb-1" size="sm"
          >Visit our website</b-button
        >
        <br /> -->
        <!-- <b-button pill class="pink-color-btn" size="sm"
          >Terms &amp; conditions</b-button
        > -->
      </div>
      <div class="d-none d-sm-block">
        <!-- <b-button
          href="https://heartresearch.org.uk"
          pill
          class="purple-color-btn mr-4"
          target="_blank"
          >Visit our website</b-button
        > -->
        <!-- <b-button pill class="pink-color-btn mr-5"
          >Terms &amp; conditions</b-button
        > -->
      </div>
      <div class="pagenumber active" v-if="pagenumber > 0">
        {{ pagenumber }} of 20
      </div>
    </nav>
    <div class="container">
      <router-view />
    </div>
    <div class="text-center footer-text">
      <b-link to="/terms" target="_blank">Terms &amp; conditions</b-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    //
  }),
  computed: {
    pagenumber() {
      // this.$store.dispatch("incrementPageNumber");
      return this.$store.state.pagenumber;
    },
  },
};
</script>
