<template>
  <b-overlay :show="loading">
    <b-form class="my-3" @submit.stop.prevent="onSubmit">
      <Breadcrumb :page="15"></Breadcrumb>
      <b-card title="Alcohol and Smoking" class="mb-3">
        <b-card-text>
          <div class="row">
            <div class="col form-group">
              <b-form-group label="Have you consumed alcohol in the past year?">
                <b-form-radio-group
                  v-model="form.drinkAlcohol.value"
                  :options="drinkAlcoholOptions"
                ></b-form-radio-group>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col form-group" v-if="form.drinkAlcohol.value == '269'">
              <label for="alcohol"
                >In a typical week, on how many days would you normally consume
                at least one alcoholic drink?</label
              >
              <div>
                <b-input-group>
                  <b-form-select
                    v-model="form.alcoholConsumptionDays.value"
                    :options="alcoholConsumptionDaysOptions"
                  ></b-form-select>
                </b-input-group>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col form-group" v-if="form.drinkAlcohol.value == '269'">
              <label for="alcohol"
                >How many units of alcohol do you consume in a typical
                week?</label
              >
              <b-form-select
                v-model="form.alcoholConsumption.value"
                :options="alcoholConsumptionOptions"
              ></b-form-select>
              <div style="display: flex; justify-content: center">
                <img
                  src="../assets/alcohol-units.png"
                  alt="BMI graph"
                  width="80%"
                />
              </div>
              <small class="form-text text-muted">
                <b-link v-b-modal.modal-center-alcoholUnits
                  >Work out your exact units</b-link
                >
              </small>
            </div>
          </div>
          <div class="row">
            <div class="col form-group" v-if="form.drinkAlcohol.value == '269'">
              <label for="alcohol"
                >How often do you have six <i>(for women)</i> or eight
                <i>(for men)</i>, or more units on one occasion?</label
              ><b-icon
                v-b-modal.modal-center-drinkAlcohol
                icon="question-circle-fill"
                color="#6300bd"
                class="ml-2"
              ></b-icon>
              <b-form-select
                v-model="form.alcoholSixMoreDrinks.value"
                :options="alcoholSixMoreDrinksOptions"
              ></b-form-select>
            </div>
          </div>
          <div>
            <b-modal
              id="modal-center-drinkAlcohol"
              centered
              title="Alcohol units for men and women"
              ok-only
            >
              <p>
                That's equivalent to about four pints of normal strength beer
                for a man or three pints for a woman.
              </p>
            </b-modal>
          </div>
          <div>
            <b-modal
              id="modal-center-alcoholUnits"
              centered
              title="Work out your exact units"
              ok-only
              size="lg"
            >
              <div class="row">
                <b-container
                  class="my-2"
                  v-if="form.drinkAlcohol.value == '269'"
                >
                  <b-row>
                    <b-col md="5" xs="12">
                      How many drinks containing alcohol did you have on a
                      typical week when you were drinking in the past year?
                    </b-col>
                    <div class="w-100 mb-2"></div>
                    <b-col md="7" xs="12"
                      >Wine <small>small glass (125ml)</small></b-col
                    >
                    <b-col md="5" xs="12">
                      <b-form-spinbutton
                        size="sm"
                        placeholder="0"
                        min="0"
                        v-model="form.wineSmallGlassUnits.value"
                        @change="calculateDrinkUnits()"
                      ></b-form-spinbutton>
                    </b-col>
                    <div class="w-100 mb-2"></div>
                    <b-col sm="7" xs="12"
                      >Wine <small>standard glass (175ml)</small></b-col
                    >
                    <b-col sm="5" xs="12">
                      <b-form-spinbutton
                        size="sm"
                        placeholder="0"
                        min="0"
                        v-model="form.wineStandardGlassUnits.value"
                        @change="calculateDrinkUnits()"
                      ></b-form-spinbutton>
                    </b-col>
                    <div class="w-100 mb-2"></div>

                    <b-col sm="7" xs="12"
                      >Wine <small>large glass (250ml)</small></b-col
                    >
                    <b-col sm="5" xs="12">
                      <b-form-spinbutton
                        size="sm"
                        placeholder="0"
                        min="0"
                        v-model="form.wineLargeGlassUnits.value"
                        @change="calculateDrinkUnits()"
                      ></b-form-spinbutton>
                    </b-col>
                    <div class="w-100 mb-2"></div>

                    <b-col sm="7" xs="12"
                      >Wine <small>bottle (750ml)</small></b-col
                    >
                    <b-col sm="5" xs="12">
                      <b-form-spinbutton
                        size="sm"
                        placeholder="0"
                        min="0"
                        v-model="form.wineBottleUnits.value"
                        @change="calculateDrinkUnits()"
                      ></b-form-spinbutton>
                    </b-col>
                    <div class="w-100 mb-2"></div>

                    <b-col sm="7" xs="12"
                      >Lager/Beer/Cider
                      <small>large bottle (330ml)</small></b-col
                    >
                    <b-col sm="5" xs="12">
                      <b-form-spinbutton
                        size="sm"
                        placeholder="0"
                        min="0"
                        v-model="form.beerLargeBottleUnits.value"
                        @change="calculateDrinkUnits()"
                      ></b-form-spinbutton>
                    </b-col>
                    <div class="w-100 mb-2"></div>

                    <b-col sm="7" xs="12"
                      >Lager/Beer/Cider <small>can (440ml)</small></b-col
                    >
                    <b-col sm="5" xs="12">
                      <b-form-spinbutton
                        size="sm"
                        placeholder="0"
                        min="0"
                        v-model="form.beerCanUnits.value"
                        @change="calculateDrinkUnits()"
                      ></b-form-spinbutton>
                    </b-col>
                    <div class="w-100 mb-2"></div>

                    <b-col sm="7" xs="12"
                      >Lager/Beer/Cider
                      <small>pint low strength (3.6%)</small></b-col
                    >
                    <b-col sm="5" xs="12">
                      <b-form-spinbutton
                        size="sm"
                        placeholder="0"
                        min="0"
                        v-model="form.beerLowStrengthUnits.value"
                        @change="calculateDrinkUnits()"
                      ></b-form-spinbutton>
                    </b-col>
                    <div class="w-100 mb-2"></div>

                    <b-col sm="7" xs="12"
                      >Lager/Beer/Cider
                      <small>pint high strength (5.2%)</small></b-col
                    >
                    <b-col sm="5" xs="12">
                      <b-form-spinbutton
                        size="sm"
                        placeholder="0"
                        min="0"
                        v-model="form.beerHighStrengthUnits.value"
                        @change="calculateDrinkUnits()"
                      ></b-form-spinbutton>
                    </b-col>
                    <div class="w-100 mb-2"></div>

                    <b-col sm="7" xs="12"
                      >Spirits <small>single shot (25ml)</small></b-col
                    >
                    <b-col sm="5" xs="12">
                      <b-form-spinbutton
                        size="sm"
                        placeholder="0"
                        min="0"
                        v-model="form.singleShotUnits.value"
                        @change="calculateDrinkUnits()"
                      ></b-form-spinbutton>
                    </b-col>
                    <div class="w-100 mb-2"></div>

                    <b-col sm="7" xs="12"
                      >Spirits <small>standard shot (35ml)</small></b-col
                    >
                    <b-col sm="5" xs="12">
                      <b-form-spinbutton
                        size="sm"
                        placeholder="0"
                        min="0"
                        v-model="form.standardShotUnits.value"
                        @change="calculateDrinkUnits()"
                      ></b-form-spinbutton>
                    </b-col>
                    <div class="w-100 mb-2"></div>

                    <b-col sm="7" xs="12"
                      >Spirits <small>double shot (50ml)</small></b-col
                    >
                    <b-col sm="5" xs="12">
                      <b-form-spinbutton
                        size="sm"
                        placeholder="0"
                        min="0"
                        v-model="form.spiritsDoubleShotUnits.value"
                        @change="calculateDrinkUnits()"
                      ></b-form-spinbutton>
                    </b-col>
                    <div class="w-100 mb-2"></div>

                    <b-col sm="7" xs="12">Alcopop <small>(275ml)</small></b-col>
                    <b-col sm="5" xs="12">
                      <b-form-spinbutton
                        size="sm"
                        placeholder="0"
                        min="0"
                        v-model="form.alcopopUnits.value"
                        @change="calculateDrinkUnits()"
                      ></b-form-spinbutton>
                    </b-col>
                    <div class="w-100 mb-2"></div>

                    <b-col cols="7">
                      <strong>Total units</strong>
                      <!-- <small class="form-text text-muted">
                        <b-link v-b-modal.modal-center-alcohol-dependency
                          >Alcohol dependency</b-link
                        >
                      </small> -->
                      <!-- <div>
                        <b-modal
                          id="modal-center-alcohol-dependency"
                          centered
                          title="Alcohol dependency"
                          ok-only
                        >
                          <p>
                            Alcohol dependency is common in the UK. If you are
                            finding it difficult to control how much you drink
                            or find that your drinking causes problems in your
                            life, then you may benefit from some additional
                            support. As well as contacting your GP, you can use
                            one or more of the following resources to get help,
                            or to find out more about reducing your alcohol
                            consumption.
                          </p>
                          <p>Alcohol support:</p>
                          <ul>
                            <li>
                              <a
                                href="https://www.nhs.uk/live-well/alcohol-advice/alcohol-support"
                                target="_blank"
                                >NHS Support</a
                              >
                            </li>
                            <li>
                              <a
                                href="https://www.drinkaware.co.uk"
                                target="_blank"
                                >Drinkaware</a
                              >
                            </li>
                            <li>
                              <a
                                href="https://alcoholchange.org.uk"
                                target="_blank"
                                >Alcohol Change UK</a
                              >
                            </li>
                            <li>
                              <a
                                href="http://www.alcoholics-anonymous.org.uk"
                                target="_blank"
                                >Alcoholics Anonymous</a
                              >
                            </li>
                          </ul>
                        </b-modal>
                      </div> -->
                    </b-col>
                    <b-col cols="5" class="text-center">
                      <strong>{{ form.totalDrinkUnits.value }}</strong>
                    </b-col>
                    <div class="w-100 mb-2"></div>
                    <hr class="mt-2 mb-3" />
                  </b-row>
                </b-container>
              </div>
            </b-modal>
          </div>
          <!-- <div class="row">
            <div class="col form-group">
              <label for="sugarydrinks"
                >In an average week, how often do you consume sugary
                drinks?</label
              >
              <b-form-select
                v-model="form.sugaryDrinks.value"
                :options="sugaryDrinksOptions"
              ></b-form-select>
            </div>
          </div> -->
          <!-- <div class="row">
            <div class="col form-group">
              <b-form-group
                label="Do you add sugar to hot drinks such as tea or coffee?"
              >
                <b-form-radio-group
                  v-model="form.addSugar.value"
                  :options="addSugarOptions"
                ></b-form-radio-group>
              </b-form-group>
            </div>
          </div> -->

          <div class="row">
            <div class="col form-group">
              <label for="smoked">Do you currently smoke tobacco?</label>
              <b-form-select
                v-model="form.smokingStatus.value"
                :options="smokingStatusOptions"
              ></b-form-select>
            </div>
          </div>

          <div class="row">
            <div
              class="col form-group"
              v-if="form.smokingStatus.value == '349'"
            >
              <label for="frequency">What is your smoking frequency?</label>
              <b-form-select
                v-model="form.smokingFrequency.value"
                :options="smokingFrequencyOptions"
              ></b-form-select>
            </div>
          </div>

          <div class="row">
            <div
              class="col form-group"
              v-if="form.smokingStatus.value == '349'"
            >
              <label for="stoppedSmoking"
                >Have you tried to stop smoking in the past?</label
              >
              <b-form-select
                v-model="form.stopSmoking.value"
                :options="stopSmokingOptions"
              ></b-form-select>
            </div>
          </div>

          <div class="row">
            <div
              class="col form-group"
              v-if="form.smokingStatus.value == '349'"
            >
              <b-form-group
                label="What are some of the barriers you face towards stopping
            smoking?"
              >
                <b-form-checkbox-group
                  v-model="form.smokingBarrier.value"
                  :options="smokingBarrierOptions"
                  stacked
                  multiple
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div
              class="col form-group"
              v-if="form.smokingBarrier.value.includes(404)"
            >
              <label for="smokingBarrierOther"
                >Other barriers to stop smoking?</label
              >
              <b-form-input
                v-model="form.smokingBarrierOther.value"
                maxlength="255"
                placeholder="Enter barriers"
              ></b-form-input>
            </div>
          </div>

          <div class="row">
            <div class="col form-group">
              <b-form-group label="Do you use e-cigarettes?">
                <b-form-radio-group
                  v-model="form.ecigarettes.value"
                  :options="ecigarettesOptions"
                  stacked
                ></b-form-radio-group>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col form-group" v-if="form.ecigarettes.value == 'no'">
              <label for="ecigarette">More information required</label>
              <select class="form-control">
                <option selected>--</option>
                <option
                  v-for="ecigarette in [
                    'No, I use e-cigarettes and I also smoke tobacco',
                    'No, I smoke tobacco, but not e-cigarettes',
                  ]"
                  :key="ecigarette"
                  value="ecigarette"
                >
                  {{ ecigarette }}
                </option>
              </select>
            </div>
          </div>
        </b-card-text>
        <b-card-footer>
          <b-button @click="setStep(14)" class="btn btn-hruk-btn"
            >Previous</b-button
          >
          <b-button type="submit" class="btn btn-hruk-btn mb-2 float-right"
            >Continue</b-button
          >
        </b-card-footer>
      </b-card>
    </b-form>
  </b-overlay>
</template>
<script>
import * as QuestionTemplate from "./../modules/QuestionTemplate";
import * as Answer from "./../modules/Answer";
import Breadcrumb from "../components/Breadcrumb";
export default {
  components: {
    Breadcrumb,
  },
  data: () => ({
    form: {
      wineSmallGlassUnits: { id: 526, value: 0 },
      wineStandardGlassUnits: { id: 527, value: 0 },
      wineLargeGlassUnits: { id: 528, value: 0 },
      wineBottleUnits: { id: 529, value: 0 },
      beerLargeBottleUnits: { id: 530, value: 0 },
      beerCanUnits: { id: 531, value: 0 },
      beerLowStrengthUnits: { id: 532, value: 0 },
      beerHighStrengthUnits: { id: 533, value: 0 },
      singleShotUnits: { id: 534, value: 0 },
      standardShotUnits: { id: 535, value: 0 },
      spiritsDoubleShotUnits: { id: 536, value: 0 },
      alcopopUnits: { id: 537, value: 0 },
      totalDrinkUnits: { id: 462, value: null },
      alcoholConsumptionDays: { id: 518, value: null },
      alcoholConsumption: { id: 546, value: null },
      // alcoholFrequency: { id: 461, value: null },
      alcoholSixMoreDrinks: { id: 513, value: null },
      // sugaryDrinks: { id: 463, value: null },
      // addSugar: { id: 464, value: null },
      smokingStatus: { id: 465, value: null },
      smokingFrequency: { id: 468, value: null },
      stopSmoking: { id: 469, value: null },
      smokingBarrier: { id: 470, value: [] },
      smokingBarrierOther: { id: 470, value: null },
      drinkAlcohol: { id: 514, value: null },
      ecigarettes: { id: 467, value: null },
    },
    alcoholConsumptionDaysOptions: null,
    alcoholConsumptionOptions: null,
    // alcoholFrequencyOptions: null,
    // sugaryDrinksOptions: null,
    smokingStatusOptions: null,
    smokingFrequencyOptions: null,
    stopSmokingOptions: null,
    smokingBarrierOptions: null,
    drinkAlcoholOptions: null,
    ecigarettesOptions: null,
    // addSugarOptions: null,
    alcoholSixMoreDrinksOptions: null,
    loading: false,
  }),
  async created() {
    this.$store.dispatch("setPageNumber", 15);
    await QuestionTemplate.questionTemplate();
    await Answer.getAnswers();

    this.drinkAlcoholOptions = this.getOptions(
      this.form.drinkAlcohol.id,
      this.$store.state.template
    );
    this.form.drinkAlcohol.value = this.getAnswer(
      this.form.drinkAlcohol.id,
      this.$store.state.answers
    );
    this.alcoholConsumptionDaysOptions = this.getOptions(
      this.form.alcoholConsumptionDays.id,
      this.$store.state.template
    );
    this.form.alcoholConsumptionDays.value = this.getAnswer(
      this.form.alcoholConsumptionDays.id,
      this.$store.state.answers
    );
    this.alcoholConsumptionOptions = this.getOptions(
      this.form.alcoholConsumption.id,
      this.$store.state.template
    );
    this.form.alcoholConsumption.value = this.getAnswer(
      this.form.alcoholConsumption.id,
      this.$store.state.answers
    );
    // this.alcoholFrequencyOptions = this.getOptions(
    //   this.form.alcoholFrequency.id,
    //   this.$store.state.template
    // );
    // this.form.alcoholFrequency.value = this.getAnswer(
    //   this.form.alcoholFrequency.id,
    //   this.$store.state.answers
    // );
    this.alcoholSixMoreDrinksOptions = this.getOptions(
      this.form.alcoholSixMoreDrinks.id,
      this.$store.state.template
    );
    this.form.alcoholSixMoreDrinks.value = this.getAnswer(
      this.form.alcoholSixMoreDrinks.id,
      this.$store.state.answers
    );
    // this.sugaryDrinksOptions = this.getOptions(
    //   this.form.sugaryDrinks.id,
    //   this.$store.state.template
    // );
    // this.form.sugaryDrinks.value = this.getAnswer(
    //   this.form.sugaryDrinks.id,
    //   this.$store.state.answers
    // );
    // this.addSugarOptions = this.getOptions(
    //   this.form.addSugar.id,
    //   this.$store.state.template
    // );
    // this.form.addSugar.value = this.getAnswer(
    //   this.form.addSugar.id,
    //   this.$store.state.answers
    // );
    this.smokingStatusOptions = this.getOptions(
      this.form.smokingStatus.id,
      this.$store.state.template
    );
    this.form.smokingStatus.value = this.getAnswer(
      this.form.smokingStatus.id,
      this.$store.state.answers
    );
    this.smokingFrequencyOptions = this.getOptions(
      this.form.smokingFrequency.id,
      this.$store.state.template
    );
    this.form.smokingFrequency.value = this.getAnswer(
      this.form.smokingFrequency.id,
      this.$store.state.answers
    );
    this.stopSmokingOptions = this.getOptions(
      this.form.stopSmoking.id,
      this.$store.state.template
    );
    this.form.stopSmoking.value = this.getAnswer(
      this.form.stopSmoking.id,
      this.$store.state.answers
    );
    this.smokingBarrierOptions = this.getOptions(
      this.form.smokingBarrier.id,
      this.$store.state.template
    );
    this.form.smokingBarrier.value = this.getMultiSelectAnswer(
      this.form.smokingBarrier.id,
      this.$store.state.answers
    );
    this.form.smokingBarrierOther.value = this.getAnswerDetails(
      this.form.smokingBarrierOther.id,
      this.$store.state.answers
    );
    this.ecigarettesOptions = this.getOptions(
      this.form.ecigarettes.id,
      this.$store.state.template
    );
    this.form.ecigarettes.value = this.getAnswer(
      this.form.ecigarettes.id,
      this.$store.state.answers
    );
    // this.form.totalDrinkUnits.value = this.getAnswerInt(
    //   this.form.totalDrinkUnits.id,
    //   this.$store.state.answers
    // );
    // this.form.wineSmallGlassUnits.value = this.getAnswerInt(
    //   this.form.wineSmallGlassUnits.id,
    //   this.$store.state.answers
    // );
    // this.form.wineStandardGlassUnits.value = this.getAnswerInt(
    //   this.form.wineStandardGlassUnits.id,
    //   this.$store.state.answers
    // );
    // this.form.wineLargeGlassUnits.value = this.getAnswerInt(
    //   this.form.wineLargeGlassUnits.id,
    //   this.$store.state.answers
    // );
    // this.form.wineBottleUnits.value = this.getAnswerInt(
    //   this.form.wineBottleUnits.id,
    //   this.$store.state.answers
    // );
    // this.form.beerLargeBottleUnits.value = this.getAnswerInt(
    //   this.form.beerLargeBottleUnits.id,
    //   this.$store.state.answers
    // );
    // this.form.beerCanUnits.value = this.getAnswerInt(
    //   this.form.beerCanUnits.id,
    //   this.$store.state.answers
    // );
    // this.form.beerLowStrengthUnits.value = this.getAnswerInt(
    //   this.form.beerLowStrengthUnits.id,
    //   this.$store.state.answers
    // );
    // this.form.beerHighStrengthUnits.value = this.getAnswerInt(
    //   this.form.beerHighStrengthUnits.id,
    //   this.$store.state.answers
    // );
    // this.form.singleShotUnits.value = this.getAnswerInt(
    //   this.form.singleShotUnits.id,
    //   this.$store.state.answers
    // );
    // this.form.standardShotUnits.value = this.getAnswerInt(
    //   this.form.standardShotUnits.id,
    //   this.$store.state.answers
    // );
    // this.form.spiritsDoubleShotUnits.value = this.getAnswerInt(
    //   this.form.spiritsDoubleShotUnits.id,
    //   this.$store.state.answers
    // );
    // this.form.alcopopUnits.value = this.getAnswerInt(
    //   this.form.alcopopUnits.id,
    //   this.$store.state.answers
    // );
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      let formDataSubmitted = await this.saveFormData();
      if (!formDataSubmitted) {
        // 2nd Attempt
        await new Promise((r) => setTimeout(r, 2000));
        formDataSubmitted = await this.saveFormData();
        if (!formDataSubmitted) {
          this.makeToast(
            "danger",
            "We have experienced a problem, please try again"
          );
          this.loading = false;
          return;
        }
      }
    },
    async saveFormData() {
      try {
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.alcoholConsumptionDays.id,
          answer: this.form.alcoholConsumptionDays.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.alcoholConsumption.id,
          answer: this.form.alcoholConsumption.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.alcoholSixMoreDrinks.id,
          answer: this.form.alcoholSixMoreDrinks.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.smokingStatus.id,
          answer: this.form.smokingStatus.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.smokingFrequency.id,
          answer: this.form.smokingFrequency.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.stopSmoking.id,
          answer: this.form.stopSmoking.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.smokingBarrier.id,
          answer: this.form.smokingBarrier.value,
          details: this.form.smokingBarrierOther.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.drinkAlcohol.id,
          answer: this.form.drinkAlcohol.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.ecigarettes.id,
          answer: this.form.ecigarettes.value,
        });
        this.loading = false;
        this.setStep(16);
        return true;
      } catch (e) {
        return false;
      }
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
    getOptions(id, template) {
      const options = template
        .filter((template) => template.id === id)
        .map((template) => ({
          text: template.question_option,
          value: template.question_option_id,
        }));
      return options;
    },
    getAnswer(id, answers) {
      if (answers) {
        const answer = answers
          .filter((answer) => id === answer.question_id)
          .map((answer) => answer.answer);
        return answer.length > 0 ? answer[0] : null;
      }
    },
    getAnswerInt(id, answers) {
      if (answers) {
        const answer = answers
          .filter((answer) => id === answer.question_id)
          .map((answer) => answer.answer);
        return answer.length > 0 ? answer[0] : 0;
      }
    },
    calculateDrinkUnits() {
      let totalUnits = 0;
      totalUnits =
        parseInt(this.form.wineSmallGlassUnits.value) * 1.5 +
        parseInt(this.form.wineStandardGlassUnits.value) * 2.1 +
        parseInt(this.form.wineLargeGlassUnits.value) * 3 +
        parseInt(this.form.wineBottleUnits.value) * 9 +
        parseInt(this.form.beerLargeBottleUnits.value) * 1.7 +
        parseInt(this.form.beerCanUnits.value) * 2 +
        parseInt(this.form.beerLowStrengthUnits.value) * 2 +
        parseInt(this.form.beerHighStrengthUnits.value) * 3 +
        parseInt(this.form.singleShotUnits.value) * 1 +
        parseInt(this.form.standardShotUnits.value) * 1.4 +
        parseInt(this.form.spiritsDoubleShotUnits.value) * 2 +
        parseInt(this.form.alcopopUnits.value) * 1.8;

      this.form.totalDrinkUnits.value = Math.round(totalUnits * 100) / 100;
    },
    getMultiSelectAnswer(id, answers) {
      const answer = answers
        .filter((answer) => id === answer.question_id && answer.answer !== null)
        .map((answer) => answer.answer);
      return answer.length > 0 ? JSON.parse(answer[0]) : [];
    },
    getAnswerDetails(id, answers) {
      const answer = answers
        .filter((answer) => id === answer.question_id)
        .map((answer) => answer.details);
      return answer.length > 0 ? answer[0] : null;
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(`${message}`, {
        title: `Error`,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
