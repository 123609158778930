<template>
  <b-overlay :show="loading">
    <b-form class="my-3" @submit.stop.prevent="onSubmit">
      <Breadcrumb :page="6"></Breadcrumb>

      <b-card title="Your Body Composition" class="mb-3">
        <b-card-text>
          <b-alert show variant="warning"
            >If you have your results available, please enter them below. If
            not, please leave them blank.</b-alert
          >
          <div class="row mb-2">
            <div class="col">
              <b-card title="Enter your weight">
                <b-card-text>
                  <div class="row" v-if="weightUnit == 'imperial'">
                    <div class="col">
                      <div class="form-group">
                        <label for="weight_stones">Weight (stone)</label>
                        <b-form-input
                          v-model="form.weightStones.value"
                          :state="validateState('weightStones')"
                          type="number"
                          aria-describedby="input-1-live-feedback"
                          id="weight_stones"
                          placeholder="Stones"
                          v-on:keyup="calculateBMI()"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          >Value must be between 3-50 and be one decimal
                          place</b-form-invalid-feedback
                        >
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label for="weight_pounds">Weight (pounds)</label>
                        <b-form-input
                          v-model="$v.form.weightPounds.value.$model"
                          :state="validateState('weightPounds')"
                          type="number"
                          id="weight_pounds"
                          placeholder="Pounds"
                          min="0"
                          max="14"
                          v-on:keyup="calculateBMI()"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          >Value must be between 1-14</b-form-invalid-feedback
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="weightUnit == 'metric'">
                    <div class="col">
                      <div class="form-group">
                        <label for="weight_kilograms">Weight (kilograms)</label>
                        <b-form-input
                          v-model="$v.form.weightKilograms.value.$model"
                          :state="validateState('weightKilograms')"
                          id="weight_kilograms"
                          placeholder="Kilograms"
                          min="20"
                          max="300"
                          v-on:keyup="calculateBMI()"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          >Value must be between 20-300 with 1 decimal
                          point</b-form-invalid-feedback
                        >
                      </div>
                    </div>
                  </div>
                  <b-link
                    v-if="weightUnit == 'imperial'"
                    @click.stop="
                      () => {
                        weightUnit = 'metric';
                        convertWeightImperialToMetric();
                      }
                    "
                    >Switch to metric</b-link
                  >
                  <b-link
                    v-else
                    @click.stop="
                      () => {
                        weightUnit = 'imperial';
                        convertWeightMetricToImperial();
                      }
                    "
                    >Switch to imperial</b-link
                  >
                </b-card-text>
              </b-card>
            </div>
            <div class="col">
              <b-card title="Enter your height">
                <b-card-text>
                  <div class="row" v-if="heightUnit == 'imperial'">
                    <div class="col">
                      <div class="form-group">
                        <label for="height_feet">Height (feet)</label>
                        <b-form-input
                          v-model="$v.form.heightFeet.value.$model"
                          :state="validateState('heightFeet')"
                          type="number"
                          id="height_feet"
                          placeholder="Feet"
                          v-on:keyup="calculateBMI()"
                          min="3"
                          max="7"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          >Value must be between 3-7</b-form-invalid-feedback
                        >
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label for="height_inches">Height (inches)</label>
                        <b-form-input
                          v-model="$v.form.heightInches.value.$model"
                          :state="validateState('heightInches')"
                          type="number"
                          id="height_inches"
                          placeholder="Inches"
                          v-on:keyup="calculateBMI()"
                          min="0"
                          max="12"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          >Value must be between 0-12</b-form-invalid-feedback
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="heightUnit == 'metric'">
                    <div class="col">
                      <div class="form-group">
                        <label for="height_centimetres"
                          >Height (centimetres)</label
                        >
                        <b-form-input
                          v-model="$v.form.heightCentimeters.value.$model"
                          :state="validateState('heightCentimeters')"
                          type="number"
                          id="height_inches"
                          placeholder="Centimetres"
                          v-on:keyup="calculateBMI()"
                          min="90"
                          max="250"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          >Value must be between 90-250</b-form-invalid-feedback
                        >
                      </div>
                    </div>
                  </div>
                  <b-link
                    v-if="heightUnit == 'imperial'"
                    @click.stop="
                      () => {
                        heightUnit = 'metric';
                        convertHeightImperialToMetric();
                      }
                    "
                    >Switch to metric</b-link
                  >
                  <b-link
                    v-else
                    @click.stop="
                      () => {
                        heightUnit = 'imperial';
                        convertHeightMetricToImperial();
                      }
                    "
                    >Switch to imperial</b-link
                  >
                </b-card-text>
              </b-card>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col form-group">
              <label for="southAsian"
                >Which of the following best desribes your current weight
                status?</label
              >
              <b-form-select
                v-model="form.weightStatus.value"
                :options="weightStatusOptions"
              ></b-form-select>
            </div>
          </div> -->

          <!-- <div class="row" v-if="weightUnit == 'imperial'">
            <div class="col">
              <div class="form-group">
                <label for="weight_stones">Weight (stone)</label>
                <b-form-input
                  v-model="form.weightStones.value"
                  :state="validateState('weightStones')"
                  type="number"
                  aria-describedby="input-1-live-feedback"
                  id="weight_stones"
                  placeholder="Enter stones"
                  v-on:keyup="calculateBMI()"
                ></b-form-input>
                <b-form-invalid-feedback
                  >Value must be between 3-50 and be one decimal
                  place</b-form-invalid-feedback
                >
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="weight_pounds">Weight (pounds)</label>
                <b-form-input
                  v-model="$v.form.weightPounds.value.$model"
                  :state="validateState('weightPounds')"
                  type="number"
                  id="weight_pounds"
                  placeholder="Enter pounds"
                  min="0"
                  max="14"
                  v-on:keyup="calculateBMI()"
                ></b-form-input>
                <b-form-invalid-feedback
                  >Value must be between 1-14</b-form-invalid-feedback
                >
              </div>
            </div>
          </div>
          <div class="row" v-if="weightUnit == 'metric'">
            <div class="col">
              <div class="form-group">
                <label for="weight_kilograms">Weight (kilograms)</label>
                <b-form-input
                  v-model="$v.form.weightKilograms.value.$model"
                  :state="validateState('weightKilograms')"
                  id="weight_kilograms"
                  placeholder="Enter kilograms"
                  min="20"
                  max="300"
                  v-on:keyup="calculateBMI()"
                ></b-form-input>
                <b-form-invalid-feedback
                  >Value must be between 20-300 with 1 decimal
                  point</b-form-invalid-feedback
                >
              </div>
            </div>
          </div>
          <b-link
            v-if="weightUnit == 'imperial'"
            @click.stop="
              () => {
                weightUnit = 'metric';
                convertWeightImperialToMetric();
              }
            "
            class="float-right"
            >Switch to metric</b-link
          >
          <b-link
            v-else
            @click.stop="
              () => {
                weightUnit = 'imperial';
                convertWeightMetricToImperial();
              }
            "
            class="float-right"
            >Switch to imperial</b-link
          >

          <br />
          <div class="row" v-if="heightUnit == 'imperial'">
            <div class="col">
              <div class="form-group">
                <label for="height_feet">Height (feet)</label>
                <b-form-input
                  v-model="$v.form.heightFeet.value.$model"
                  :state="validateState('heightFeet')"
                  type="number"
                  id="height_feet"
                  placeholder="Enter feet"
                  v-on:keyup="calculateBMI()"
                  min="3"
                  max="7"
                ></b-form-input>
                <b-form-invalid-feedback
                  >Value must be between 3-7</b-form-invalid-feedback
                >
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="height_inches">Height (inches)</label>
                <b-form-input
                  v-model="$v.form.heightInches.value.$model"
                  :state="validateState('heightInches')"
                  type="number"
                  id="height_inches"
                  placeholder="Enter inches"
                  v-on:keyup="calculateBMI()"
                  min="0"
                  max="12"
                ></b-form-input>
                <b-form-invalid-feedback
                  >Value must be between 0-12</b-form-invalid-feedback
                >
              </div>
            </div>
          </div>
          <div class="row" v-if="heightUnit == 'metric'">
            <div class="col">
              <div class="form-group">
                <label for="height_centimetres">Height (centimetres)</label>
                <b-form-input
                  v-model="$v.form.heightCentimeters.value.$model"
                  :state="validateState('heightCentimeters')"
                  type="number"
                  id="height_inches"
                  placeholder="Enter centimetres"
                  v-on:keyup="calculateBMI()"
                  min="90"
                  max="250"
                ></b-form-input>
                <b-form-invalid-feedback
                  >Value must be between 90-250</b-form-invalid-feedback
                >
              </div>
            </div>
          </div>
          <b-link
            v-if="heightUnit == 'imperial'"
            @click.stop="
              () => {
                heightUnit = 'metric';
                convertHeightImperialToMetric();
              }
            "
            class="float-right"
            >Switch to metric</b-link
          >
          <b-link
            v-else
            @click.stop="
              () => {
                heightUnit = 'imperial';
                convertHeightMetricToImperial();
              }
            "
            class="float-right"
            >Switch to imperial</b-link
          >
          <br /> -->
          <div>
            <b-alert show variant="success" v-if="this.form.bmi.value != null">
              <h4 class="alert-heading">Your BMI</h4>
              <p>
                Using your height and weight, we've calculated your BMI to be
                <strong>{{ form.bmi.value }}</strong>
              </p>
              <!-- <small class="form-text text-muted">
                <b-link v-b-modal.modal-center-bmi-graph
                  >What does this mean?</b-link
                >
              </small> -->
            </b-alert>

            <div>
              <b-modal
                id="modal-center-bmi-graph"
                centered
                title="BMI graphs"
                ok-only
                size="lg"
                ><p class="mb-0"><strong>BMI graph</strong></p>
                <img
                  src="../assets/bmi-graph.jpg"
                  alt="BMI graph"
                  width="100%"
                />
                <p class="mb-0"><strong>Other Ethnic Groups</strong></p>
                <img
                  src="../assets/bmi-graph-south-asian.jpg"
                  alt="BMI graph"
                  width="100%"
                />
              </b-modal>
            </div>
          </div>

          <div class="row" v-if="waistUnit == 'imperial'">
            <div class="col">
              <div class="form-group">
                <label for="waist">Waist (inches)</label>
                <b-form-input
                  v-model="$v.form.waistInches.value.$model"
                  :state="validateState('waistInches')"
                  type="number"
                  id="waistInches"
                  placeholder="Enter inches"
                  min="10"
                  max="100"
                ></b-form-input>
                <b-form-invalid-feedback
                  >Value must be between 10-100</b-form-invalid-feedback
                >
                <small class="form-text text-muted mb-2">
                  <b-link v-b-modal.modal-center-waist
                    >How do I measure my waist circumference?</b-link
                  >
                </small>
                <b-link
                  v-if="waistUnit == 'imperial'"
                  @click.stop="
                    () => {
                      waistUnit = 'metric';
                      convertWaistImperialToMetric();
                    }
                  "
                  >Switch to metric</b-link
                >
              </div>
            </div>
          </div>
          <div class="row" v-if="waistUnit == 'metric'">
            <div class="col">
              <div class="form-group">
                <label for="waist">Waist (centimeters)</label>
                <b-form-input
                  v-model="$v.form.waistCentimeters.value.$model"
                  :state="validateState('waistCentimeters')"
                  type="number"
                  id="waistCentimeters"
                  placeholder="Enter centimeters"
                  min="30"
                  max="250"
                ></b-form-input>
                <b-form-invalid-feedback
                  >Value must be between 30-250</b-form-invalid-feedback
                >
                <small class="form-text text-muted mb-2">
                  <b-link v-b-modal.modal-center-waist
                    >How do I measure my waist circumference?</b-link
                  >
                </small>
                <b-link
                  v-if="waistUnit == 'metric'"
                  @click.stop="
                    () => {
                      waistUnit = 'imperial';
                      convertWaistMetricToImperial();
                    }
                  "
                  >Switch to imperial</b-link
                >
              </div>
            </div>
          </div>
          <div>
            <b-modal
              id="modal-center-waist"
              centered
              title="How do I measure my waist circumference?"
              ok-only
            >
              <p>
                To measure your waist, you will need a tape measure. Find your
                bottom rib and the top part of your hip bone with your fingers.
                Wrap a tape measure around your waist, midway between these two
                points. Breathe out naturally and relax before taking the
                measurement.
              </p>
            </b-modal>
          </div>
          <br />
        </b-card-text>
        <b-card-footer>
          <b-button
            @click="setStep(5)"
            class="btn btn-hruk-btn"
            variant="primary"
            >Previous</b-button
          >
          <b-button
            type="submit"
            class="btn btn-hruk-btn mb-2 float-right"
            variant="primary"
            >Continue</b-button
          >
        </b-card-footer>
      </b-card>
    </b-form>
  </b-overlay>
</template>

<script>
import { minValue, maxValue, helpers } from "vuelidate/lib/validators";
import * as QuestionTemplate from "./../modules/QuestionTemplate";
import * as Answer from "./../modules/Answer";
import Breadcrumb from "../components/Breadcrumb";
const floatcheck = helpers.regex("val", /^\d{0,3}(?:\.\d)?$/);
export default {
  components: {
    Breadcrumb,
  },
  data: () => ({
    weightUnit: "imperial",
    heightUnit: "imperial",
    waistUnit: "imperial",
    bmi: null,
    loading: false,
    form: {
      // weightStatus: { id: 409, value: null },
      weightStones: { id: 522, value: null },
      weightPounds: { id: 523, value: null },
      weightKilograms: { id: 412, value: null },
      heightInches: { id: 525, value: null },
      heightFeet: { id: 524, value: null },
      heightCentimeters: { id: 411, value: null },
      waistInches: { id: 538, value: null },
      waistCentimeters: { id: 410, value: null },
      bmi: { id: 413, value: null },
    },
    // weightStatusOptions: null,
    options: [
      { text: "Yes", value: "yes" },
      { text: "No", value: "no" },
      { text: "Don't know", value: "dontknow" },
    ],
  }),
  async created() {
    this.$store.dispatch("setPageNumber", 6);
    await QuestionTemplate.questionTemplate();
    await Answer.getAnswers();

    // this.weightStatusOptions = this.getOptions(
    //   this.form.weightStatus.id,
    //   this.$store.state.template
    // );
    // this.form.weightStatus.value = this.getAnswer(
    //   this.form.weightStatus.id,
    //   this.$store.state.answers
    // );
    this.form.weightKilograms.value = this.getAnswer(
      this.form.weightKilograms.id,
      this.$store.state.answers
    );
    this.form.weightStones.value = this.getAnswer(
      this.form.weightStones.id,
      this.$store.state.answers
    );
    this.form.weightPounds.value = this.getAnswer(
      this.form.weightPounds.id,
      this.$store.state.answers
    );
    this.weightUnit =
      this.form.weightKilograms.value === null ? "imperial" : "metric";

    this.form.heightCentimeters.value = this.getAnswer(
      this.form.heightCentimeters.id,
      this.$store.state.answers
    );
    this.form.heightFeet.value = this.getAnswer(
      this.form.heightFeet.id,
      this.$store.state.answers
    );
    this.form.heightInches.value = this.getAnswer(
      this.form.heightInches.id,
      this.$store.state.answers
    );
    this.heightUnit =
      this.form.heightCentimeters.value === null ? "imperial" : "metric";

    this.form.waistCentimeters.value = this.getAnswer(
      this.form.waistCentimeters.id,
      this.$store.state.answers
    );
    this.form.waistInches.value = this.getAnswer(
      this.form.waistInches.id,
      this.$store.state.answers
    );
    this.waistUnit =
      this.form.waistCentimeters.value === null ? "imperial" : "metric";

    this.form.bmi.value = this.getAnswer(
      this.form.bmi.id,
      this.$store.state.answers
    );
  },
  validations: {
    form: {
      weightStones: {
        value: {
          minValue: minValue(3),
          maxValue: maxValue(50),
        },
      },
      weightPounds: {
        value: { minValue: minValue(0), maxValue: maxValue(14) },
      },
      weightKilograms: {
        value: { minValue: minValue(20), maxValue: maxValue(300), floatcheck },
      },
      heightFeet: {
        value: {
          minValue: minValue(3),
          maxValue: maxValue(7),
        },
      },
      heightInches: {
        value: { minValue: minValue(0), maxValue: maxValue(12) },
      },
      heightCentimeters: {
        value: { minValue: minValue(90), maxValue: maxValue(250) },
      },
      waistInches: {
        value: { minValue: minValue(10), maxValue: maxValue(100) },
      },
      waistCentimeters: {
        value: { minValue: minValue(30), maxValue: maxValue(250) },
      },
    },
  },
  methods: {
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.loading = true;
      let formDataSubmitted = await this.saveFormData();
      if (!formDataSubmitted) {
        // 2nd Attempt
        await new Promise((r) => setTimeout(r, 2000));
        formDataSubmitted = await this.saveFormData();
        if (!formDataSubmitted) {
          this.makeToast(
            "danger",
            "We have experienced a problem, please try again"
          );
          this.loading = false;
          return;
        }
      }
    },
    async saveFormData() {
      try {
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.weightKilograms.id,
          answer: this.form.weightKilograms.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.heightCentimeters.id,
          answer: this.form.heightCentimeters.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.heightInches.id,
          answer: this.form.heightInches.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.heightFeet.id,
          answer: this.form.heightFeet.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.waistCentimeters.id,
          answer: this.form.waistCentimeters.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.waistInches.id,
          answer: this.form.waistInches.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.weightStones.id,
          answer: this.form.weightStones.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.weightPounds.id,
          answer: this.form.weightPounds.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.bmi.id,
          answer: this.form.bmi.value,
        });
        this.loading = false;
        this.setStep(7);
        return true;
      } catch (e) {
        return false;
      }
    },
    getOptions(id, template) {
      const options = template
        .filter((template) => template.id === id)
        .map((template) => ({
          text: template.question_option,
          value: template.question_option_id,
        }));
      return options;
    },
    getAnswer(id, answers) {
      if (answers) {
        const answer = answers
          .filter((answer) => id === answer.question_id)
          .map((answer) => answer.answer);
        return answer.length > 0 ? answer[0] : null;
      }
    },
    convertWeightImperialToMetric() {
      this.form.weightPounds.value = "";
      this.form.weightStones.value = "";
      this.form.weightKilograms.value = "";
      // const weightPounds = this.form.weightPounds.value
      //   ? this.form.weightPounds.value
      //   : 0;
      // const weightStones = this.form.weightStones.value
      //   ? this.form.weightStones.value
      //   : 0;
      // const pounds = parseInt(weightPounds) + parseInt(weightStones) * 14;
      // this.form.weightKilograms.value = null;
      // if (pounds > 0) {
      //   const weightKilograms = pounds / 2.2046;
      //   this.form.weightKilograms.value = Math.round(weightKilograms * 10) / 10;
      // }
    },
    convertWeightMetricToImperial() {
      this.form.weightPounds.value = "";
      this.form.weightStones.value = "";
      this.form.weightKilograms.value = "";
    },
    convertHeightImperialToMetric() {
      this.form.heightFeet.value = "";
      this.form.heightInches.value = "";
      this.form.heightCentimeters.value = "";
      // const heightInches = this.form.heightInches.value
      //   ? this.form.heightInches.value
      //   : 0;
      // const heightFeet = this.form.heightFeet.value
      //   ? this.form.heightFeet.value
      //   : 0;
      // const inches = parseInt(heightInches) + parseInt(heightFeet) * 12;
      // this.form.heightCentimeters.value = null;
      // if (inches > 0) {
      //   const heightCentimeters = inches / 0.3937;
      //   this.form.heightCentimeters.value =
      //     Math.round(heightCentimeters * 10) / 10;
      // }
    },
    convertHeightMetricToImperial() {
      this.form.heightFeet.value = "";
      this.form.heightInches.value = "";
      this.form.heightCentimeters.value = "";
    },
    convertWaistImperialToMetric() {
      this.form.waistCentimeters.value = "";
      this.form.waistInches.value = "";
      // const waistInches = this.form.waistInches.value
      //   ? this.form.waistInches.value
      //   : 0;
      // const inches = waistInches;
      // if (inches > 0) {
      //   const waistCentimeters = inches / 0.3937;
      //   this.form.waistCentimeters.value =
      //     Math.round(waistCentimeters * 10) / 10;
      // }
    },
    convertWaistMetricToImperial() {
      this.form.waistCentimeters.value = "";
      this.form.waistInches.value = "";
    },
    calculateBMI() {
      let heightCentimeters = 0;
      let weightKilograms = 0;
      // Do we have height?
      if (this.heightUnit === "imperial") {
        const heightInches = this.form.heightInches.value
          ? parseInt(this.form.heightInches.value)
          : 0;
        const heightFeet = this.form.heightFeet.value
          ? parseInt(this.form.heightFeet.value)
          : 0;
        if (heightFeet + heightInches === 0) {
          return false;
        }
        // convert to cm
        const inches = parseInt(heightInches) + parseInt(heightFeet) * 12;
        heightCentimeters = inches / 0.3937;
      } // metric
      else {
        heightCentimeters = this.form.heightCentimeters.value
          ? parseInt(this.form.heightCentimeters.value)
          : 0;
        if (heightCentimeters === 0) {
          return false;
        }
      }
      if (this.weightUnit === "imperial") {
        // Do we have weight?
        const weightPounds = this.form.weightPounds.value
          ? parseInt(this.form.weightPounds.value)
          : 0;
        const weightStones = this.form.weightStones.value
          ? parseInt(this.form.weightStones.value)
          : 0;
        if (weightStones + weightPounds === 0) {
          return false;
        }
        const pounds = parseInt(weightPounds) + parseInt(weightStones) * 14;
        weightKilograms = pounds / 2.2046;
      } else {
        // metric
        weightKilograms = this.form.weightKilograms.value
          ? parseFloat(this.form.weightKilograms.value)
          : 0;
        if (weightKilograms === 0) {
          return false;
        }
      }
      const heightmeters = heightCentimeters / 100;
      // calculate BMI
      // console.log(
      //   heightmeters,
      //   "heightmeters",
      //   weightKilograms,
      //   "weightKilograms"
      // );
      const bmi = weightKilograms / Math.pow(heightmeters, 2);
      this.form.bmi.value = bmi.toFixed(2);
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(`${message}`, {
        title: `Error`,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
<style>
.btn.active {
  background: #6300bd !important;
}
label.btn {
  background: #e0e0e0;
  border-color: #868686;
  color: #565656;
}
</style>
