// import { API } from "aws-amplify";
import store from "../store";
import * as template from "./Template";
// import { get_question_templates } from "./../graphql/queries";

export async function questionTemplate() {
  store.dispatch("setQuestionTemplate", template.getTemplate);
  return template.getTemplate;
  // const get_question_templates = /* GraphQL */ `
  //   query Get_question_templates {
  //     get_question_templates {
  //       id
  //       question_option_id
  //       question_option
  //     }
  //   }
  // `;
  // try {
  //   if (store.state.template === null) {
  //     var {
  //       data: { get_question_templates: templates },
  //     } = await API.graphql({
  //       query: get_question_templates,
  //       authMode: "API_KEY",
  //     });
  //   } else {
  //     templates = store.state.template;
  //   }
  //   store.dispatch("setQuestionTemplate", templates);
  //   // const jsonTemplates = JSON.stringify(templates);
  //   // console.log(jsonTemplates);
  //   console.log(templates);
  //   return templates;
  // } catch (e) {
  //   throw new Error(e.errors[0].message);
  // }
}
