<template>
  <div class="my-3">
    <b-form @submit.stop.prevent="onSubmit">
      <b-card title="Completing the Questionnaire" class="mb-3">
        <b-card-text>
          <div class="row">
            <div class="col">
              <p>
                This questionnaire will take approximately 10 to 15 minutes to
                complete. When filling in the questionnaire, you will be asked
                to provide details of your weight, height, waist measurement,
                blood pressure, cholesterol, family history of heart disease and
                other related conditions, as well as information about your
                diet, physical activity, smoking, alcohol consumption and mental
                wellbeing. We understand that you may not have all this
                information available, or may not want to share it, so we have
                tried to keep the majority of the questions optional. A small
                number of the questions are mandatory, and these are for
                questions where we would simply not be able to provide this
                service without collecting this information.
              </p>
            </div>
          </div>
          <b-card-footer>
            <!-- <b-button
              @click="setStep(1)"
              class="btn btn-hruk-btn"
              variant="primary"
              >Previous</b-button
            > -->
            <b-button
              type="submit"
              class="btn btn-hruk-btn mb-2 float-right"
              variant="primary"
              >Continue</b-button
            >
          </b-card-footer>
        </b-card-text>
      </b-card>
    </b-form>
  </div>
</template>

<script>
export default {
  name: "yourConsent",
  components: {},
  data() {
    return {};
  },
  async created() {
    this.$store.dispatch("setPageNumber", 2);
  },
  methods: {
    async onSubmit() {
      this.$store.dispatch("setStep", 3);
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
  },
};
</script>
