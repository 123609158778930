/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const get_campaign = /* GraphQL */ `
  query Get_campaign($code: String!) {
    get_campaign(code: $code) {
      id
      company_id
      code
      start_date
      end_date
      campaign_active
      name
      company_active
    }
  }
`;
export const get_question_templates = /* GraphQL */ `
  query Get_question_templates {
    get_question_templates {
      id
      name
      type
      unit_before
      unit_after
      information
      question_id
      question_description
      question_option_id
      question_option
    }
  }
`;
export const get_answers = /* GraphQL */ `
  query Get_answers($sessionId: Int!) {
    get_answers(sessionId: $sessionId) {
      id
      question_id
      session_id
      answer
      details
    }
  }
`;
export const get_patient = /* GraphQL */ `
  query Get_patient($id: Int!) {
    get_patient(id: $id) {
      id
      sex_id
      occupation_id
      contact_id
      firstname
      lastname
      known_as
      mobile
      email
    }
  }
`;
