<template>
  <b-overlay :show="loading">
    <b-form class="my-3" @submit.stop.prevent="onSubmit">
      <Breadcrumb :page="19"></Breadcrumb>
      <b-card title="You're almost there" class="mb-3">
        <b-card-text>
          <p>
            <i>You can be part of the next big breakthrough</i>
          </p>
          <p>
            We would like to keep in touch and send you updates about how our
            work is preventing heart disease and improving the lives of those
            affected. We can't do what we do without you, and we would love to
            keep you updated with what we do and other ways you can support
            Heart Research UK.
          </p>
          <b-form-group class="mt-3">
            <b-form-checkbox
              id="consentMarketingEmail"
              v-model="form.consentMarketingEmail.value"
              name="consentMarketingEmail"
              trim
            >
              <label class="form-check-label" for="consentMarketingEmail"
                >I would like to receive regular emails containing healthy tips
                and recipes.</label
              >
            </b-form-checkbox>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox
              id="consentHeartResearchSupport"
              v-model="form.consentHeartResearchSupport.value"
              name="consentHeartResearchSupport"
              trim
            >
              <label class="form-check-label" for="consentHeartResearchSupport"
                >I would like to receive email updates about Heart Research work
                and the ways I can support.</label
              >
            </b-form-checkbox>
          </b-form-group>
        </b-card-text>
        <b-card-footer>
          <b-button
            @click="setStep(18)"
            class="btn btn-hruk-btn"
            variant="primary"
            >Previous</b-button
          >
          <b-button
            type="submit"
            class="btn btn-hruk-btn mb-2 float-right complete-submit"
            variant="primary"
            >Complete and submit your answers</b-button
          >
        </b-card-footer>
      </b-card>
    </b-form>
  </b-overlay>
</template>
<script>
import * as QuestionTemplate from "./../modules/QuestionTemplate";
import * as Answer from "./../modules/Answer";
import Breadcrumb from "../components/Breadcrumb";
export default {
  components: {
    Breadcrumb,
  },
  data: () => ({
    form: {
      consentMarketingEmail: { id: 520, value: null },
      consentHeartResearchSupport: { id: 521, value: null },
    },
    loading: false,
  }),
  async created() {
    this.$store.dispatch("setPageNumber", 19);
    await QuestionTemplate.questionTemplate();
    await Answer.getAnswers();
    this.form.consentMarketingEmail.value = this.getAnswerBoolean(
      this.form.consentMarketingEmail.id,
      this.$store.state.answers
    );
    this.form.consentHeartResearchSupport.value = this.getAnswerBoolean(
      this.form.consentHeartResearchSupport.id,
      this.$store.state.answers
    );
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      let formDataSubmitted = await this.saveFormData();
      if (!formDataSubmitted) {
        // 2nd Attempt
        await new Promise((r) => setTimeout(r, 2000));
        formDataSubmitted = await this.saveFormData();
        if (!formDataSubmitted) {
          this.makeToast(
            "danger",
            "We have experienced a problem, please try again"
          );
          this.loading = false;
          return;
        }
      }
    },
    async saveFormData() {
      try {
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.consentMarketingEmail.id,
          answer: this.form.consentMarketingEmail.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.consentHeartResearchSupport.id,
          answer: this.form.consentHeartResearchSupport.value,
        });
        this.setStep(20);
        return true;
      } catch (e) {
        return false;
      }
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
    getOptions(id, template) {
      const options = template
        .filter((template) => template.id === id)
        .map((template) => ({
          text: template.question_option,
          value: template.question_option_id,
        }));
      return options;
    },
    getAnswer(id, answers) {
      if (answers) {
        const answer = answers
          .filter((answer) => id === answer.question_id)
          .map((answer) => answer.answer);
        return answer.length > 0 ? answer[0] : null;
      }
    },
    getAnswerBoolean(id, answers) {
      if (answers) {
        const answer = answers
          .filter((answer) => id === answer.question_id)
          .map((answer) => answer.answer);
        return answer[0] === "true" ? true : false;
      }
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(`${message}`, {
        title: `Error`,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
