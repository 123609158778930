<template>
  <b-overlay :show="loading">
    <b-form class="my-3" @submit.stop.prevent="onSubmit">
      <Breadcrumb :page="14"></Breadcrumb>
      <b-card title="Dietary Habits Continued" class="mb-3">
        <b-card-text>
          <div class="row">
            <div class="col form-group">
              <b-form-group
                label="What reasons, if any, do you feel prevent you from eating more
            healthily?"
              >
                <b-form-checkbox-group
                  v-model="form.reasonsToEatHealthily.value"
                  :options="reasonsToEatHealthilyOptions"
                  stacked
                  multiple
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div
              class="col form-group"
              v-if="form.reasonsToEatHealthily.value.includes(446)"
            >
              <label for="otherFoodHealthy"
                >Other reasons that prevent you from eating more
                healthily?</label
              >
              <b-form-input
                v-model="form.reasonsToEatHealthilyOther.value"
                maxlength="255"
                placeholder="Enter reasons"
              ></b-form-input>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col form-group">
              <label for="healthyDiet"
                >Is there anything about your workplace that helps or prevents
                you from eating a healthy diet?</label
              >
              <b-form-input
                v-model="form.preventsEatingHealthily.value"
                placeholder="Enter what prevents you eating a healthy diet"
                maxlength="255"
              ></b-form-input>
            </div>
          </div> -->
        </b-card-text>
        <b-card-footer>
          <b-button
            @click="setStep(13)"
            class="btn btn-hruk-btn"
            variant="primary"
            >Previous</b-button
          >
          <b-button
            type="submit"
            class="btn btn-hruk-btn mb-2 float-right"
            variant="primary"
            >Continue</b-button
          >
        </b-card-footer>
      </b-card>
    </b-form>
  </b-overlay>
</template>

<script>
import * as QuestionTemplate from "./../modules/QuestionTemplate";
import * as Answer from "./../modules/Answer";
import Breadcrumb from "../components/Breadcrumb";
export default {
  components: {
    Breadcrumb,
  },
  data: () => ({
    form: {
      // preventsEatingHealthily: { id: 460, value: null },
      reasonsToEatHealthily: { id: 459, value: [] },
      reasonsToEatHealthilyOther: { id: 459, value: null },
    },
    reasonsToEatHealthilyOptions: null,
    loading: false,
  }),
  async created() {
    this.$store.dispatch("setPageNumber", 14);
    await QuestionTemplate.questionTemplate();
    await Answer.getAnswers();

    // this.form.preventsEatingHealthily.value = this.getAnswer(
    //   this.form.preventsEatingHealthily.id,
    //   this.$store.state.answers
    // );
    this.reasonsToEatHealthilyOptions = this.getOptions(
      this.form.reasonsToEatHealthily.id,
      this.$store.state.template
    );
    this.form.reasonsToEatHealthily.value = this.getMultiSelectAnswer(
      this.form.reasonsToEatHealthily.id,
      this.$store.state.answers
    );
    this.form.reasonsToEatHealthilyOther.value = this.getAnswerDetails(
      this.form.reasonsToEatHealthilyOther.id,
      this.$store.state.answers
    );
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      let formDataSubmitted = await this.saveFormData();
      if (!formDataSubmitted) {
        // 2nd Attempt
        await new Promise((r) => setTimeout(r, 2000));
        formDataSubmitted = await this.saveFormData();
        if (!formDataSubmitted) {
          this.makeToast(
            "danger",
            "We have experienced a problem, please try again"
          );
          this.loading = false;
          return;
        }
      }
    },
    async saveFormData() {
      try {
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.reasonsToEatHealthily.id,
          answer: this.form.reasonsToEatHealthily.value,
          details: this.form.reasonsToEatHealthilyOther.value,
        });
        this.loading = false;
        this.setStep(15);
        return true;
      } catch (e) {
        return false;
      }
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
    getOptions(id, template) {
      const options = template
        .filter((template) => template.id === id)
        .map((template) => ({
          text: template.question_option,
          value: template.question_option_id,
        }));
      return options;
    },
    getAnswer(id, answers) {
      if (answers) {
        const answer = answers
          .filter((answer) => id === answer.question_id)
          .map((answer) => answer.answer);
        return answer.length > 0 ? answer[0] : null;
      }
    },
    getMultiSelectAnswer(id, answers) {
      const answer = answers
        .filter((answer) => id === answer.question_id && answer.answer !== null)
        .map((answer) => answer.answer);
      return answer.length > 0 ? JSON.parse(answer[0]) : [];
    },
    getAnswerDetails(id, answers) {
      const answer = answers
        .filter((answer) => id === answer.question_id)
        .map((answer) => answer.details);
      return answer.length > 0 ? answer[0] : null;
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(`${message}`, {
        title: `Error`,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
