import { API } from "aws-amplify";
import store from "../store";
import { get_patient } from "./../graphql/queries";
import { update_patient, create_patient } from "./../graphql/mutations";

export async function getPatient(patientId) {
  try {
    var {
      data: { get_patient: patient },
    } = await API.graphql({
      query: get_patient,
      variables: { id: patientId },
      authMode: "API_KEY",
    });
    return patient;
  } catch (e) {
    throw new Error(e.errors[0].message);
  }
}

export async function savePatient(payload) {
  if (store.state.sessionId === null) {
    Object.assign(payload, {
      company_id: store.state.campaign[0].company_id,
    });
    var {
      data: { create_patient: patient },
    } = await API.graphql({
      query: create_patient,
      variables: {
        createPatientInput: payload,
      },
      authMode: "API_KEY",
    });
    store.dispatch("setSession", patient);
    store.dispatch("setPatient", patient);
    return patient;
  } else {
    Object.assign(payload, { id: store.state.patient.patient_id });
    var {
      data: { update_patient: updatedPatient },
    } = await API.graphql({
      query: update_patient,
      variables: {
        updatePatientInput: payload,
      },
      authMode: "API_KEY",
    });
    return updatedPatient;
  }
}
