<template>
  <b-overlay :show="loading">
    <b-form class="my-3" @submit.stop.prevent="onSubmit">
      <Breadcrumb :page="11"></Breadcrumb>
      <b-card title="Physical Activity" class="mb-3">
        <b-card-text>
          <p>
            <i
              >Please indicate the number of minutes you spend doing different
              types of physical activity in an average week</i
            >
          </p>

          <div class="form-group row">
            <div class="col-sm-7">
              <label class="col-form-label">
                Low intensity activity<b-icon
                  v-b-modal.modal-center-low-activity
                  class="pt-2"
                  icon="question-circle-fill"
                  color="#6300bd"
                  style="width: 30px; height: 25px;"
                ></b-icon
              ></label>
              <small class="form-text text-muted">
                Leisure activities such as walking, light gardening or DIY.
              </small>
            </div>
            <div class="col-sm-5">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.lowIntense.value"
                  :options="lowIntenseOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-7">
              <label class="col-form-label">
                Moderate intensity activity<b-icon
                  v-b-modal.modal-center-moderate-activity
                  class="pt-2"
                  icon="question-circle-fill"
                  color="#6300bd"
                  style="width: 30px; height: 25px"
                ></b-icon
              ></label>
              <small class="form-text text-muted">
                Brisk walking, cycling, light jogging or moderate.
              </small>
            </div>
            <div class="col-sm-5">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.moderateIntense.value"
                  :options="moderateIntenseOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-7">
              <label class="col-form-label">
                Vigorous activity<b-icon
                  v-b-modal.modal-center-vigorous-activity
                  class="pt-2"
                  icon="question-circle-fill"
                  color="#6300bd"
                  style="width: 30px; height: 25px"
                ></b-icon
              ></label>
              <small class="form-text text-muted">
                Swimming, running, aerobics, football, tennis or cardio gym workouts.
              </small>
            </div>
            <div class="col-sm-5">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.vigorousIntense.value"
                  :options="vigorousIntenseOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-7">
              <label class="col-form-label"
                >Weekly number of resistance sessions
                <!-- <b-icon
                  v-b-modal.modal-center-resistance
                  class="pt-2"
                  icon="question-circle-fill"
                  color="#6300bd"
                  style="width: 30px; height: 25px"
                ></b-icon
              > -->
              </label>
              <small class="form-text text-muted">
                Lifting weights, body weight exercises (e.g. sit-ups, squats etc,) or working with resistance bands.
              </small>
            </div>
            <div class="col-sm-5">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.resistanceSessions.value"
                  :options="resistanceSessionsOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
          </div>

          <div>
            <b-modal
              id="modal-center-low-activity"
              centered
              title="Low Intensity Activity"
              ok-only
            >
              <p>
                Any activity where changes to your breathing, heart rate and
                temperature go largely unnoticed and you feel like you can
                maintain this intensity for hours.
              </p>
            </b-modal>
          </div>

          <div>
            <b-modal
              id="modal-center-moderate-activity"
              centered
              title="Brisk walking, cycling or light jogging"
              ok-only
            >
              <p>
                Any activity which somewhat increases your heart rate, breathing
                rate and temperature but is not uncomfortable and enables you to
                still hold a short conversation.
              </p>
            </b-modal>
          </div>

          <div>
            <b-modal
              id="modal-center-vigorous-activity"
              centered
              title="Vigorous Activity"
              ok-only
            >
              <p>
                Any activity that significantly increases your heart rate,
                breathing rate and temperature, and makes it difficult to speak
                more than one sentence.
              </p>
            </b-modal>
          </div>

          <div>
            <b-modal
              id="modal-center-resistance"
              centered
              title="Resistance Exercise"
              ok-only
            >
              <p>
                This type of exercise does not feel like a persistent effort like cycling or jogging.
              </p>
            </b-modal>
          </div>
          </div>
        </b-card-text>
        <b-card-footer>
          <b-button
            @click="setStep(10)"
            class="btn btn-hruk-btn"
            variant="primary"
            >Previous</b-button
          >
          <b-button
            type="submit"
            class="btn btn-hruk-btn mb-2 float-right"
            variant="primary"
            >Continue</b-button
          >
        </b-card-footer>
      </b-card>
    </b-form>
  </b-overlay>
</template>

<script>
import * as QuestionTemplate from "./../modules/QuestionTemplate";
import * as Answer from "./../modules/Answer";
import Breadcrumb from "../components/Breadcrumb";
export default {
  components: {
    Breadcrumb,
  },
  data: () => ({
    form: {
      lowIntense: { id: 436, value: null },
      moderateIntense: { id: 435, value: null },
      vigorousIntense: { id: 434, value: null },
      resistanceSessions: { id: 437, value: null },
      // moderateActivity: { id: 435, value: null },
      // weeklyVigorous: { id: 434, value: null },
    },
    lowIntenseOptions: null,
    moderateIntenseOptions: null,
    vigorousIntenseOptions: null,
    // moderateActivityOptions: {},
    resistanceSessionsOptions: null,
    loading: false,
  }),
  async created() {
    this.$store.dispatch("setPageNumber", 11);
    await QuestionTemplate.questionTemplate();
    await Answer.getAnswers();

    this.lowIntenseOptions = this.getOptions(
      this.form.lowIntense.id,
      this.$store.state.template
    );
    this.moderateIntenseOptions = this.getOptions(
      this.form.moderateIntense.id,
      this.$store.state.template
    );
    this.vigorousIntenseOptions = this.getOptions(
      this.form.moderateIntense.id,
      this.$store.state.template
    );
    this.resistanceSessionsOptions = this.getOptions(
      this.form.resistanceSessions.id,
      this.$store.state.template
    );
    this.form.lowIntense.value = this.getAnswer(
      this.form.lowIntense.id,
      this.$store.state.answers
    );
    this.form.resistanceSessions.value = this.getAnswer(
      this.form.resistanceSessions.id,
      this.$store.state.answers
    );
    this.form.lowIntense.value = this.getAnswer(
      this.form.lowIntense.id,
      this.$store.state.answers
    );
    this.form.moderateIntense.value = this.getAnswer(
      this.form.moderateIntense.id,
      this.$store.state.answers
    );
    this.form.vigorousIntense.value = this.getAnswer(
      this.form.vigorousIntense.id,
      this.$store.state.answers
    );
    // this.form.moderateActivity.value = this.getAnswer(
    //   this.form.moderateActivity.id,
    //   this.$store.state.answers
    // );
    // this.form.weeklyVigorous.value = this.getAnswer(
    //   this.form.weeklyVigorous.id,
    //   this.$store.state.answers
    // );
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      let formDataSubmitted = await this.saveFormData();
      if (!formDataSubmitted) {
        // 2nd Attempt
        await new Promise((r) => setTimeout(r, 2000));
        formDataSubmitted = await this.saveFormData();
        if (!formDataSubmitted) {
          this.makeToast(
            "danger",
            "We have experienced a problem, please try again"
          );
          this.loading = false;
          return;
        }
      }
    },
    async saveFormData() {
      try {
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.lowIntense.id,
          answer: this.form.lowIntense.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.resistanceSessions.id,
          answer: this.form.resistanceSessions.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.lowIntense.id,
          answer: this.form.lowIntense.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.moderateIntense.id,
          answer: this.form.moderateIntense.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.vigorousIntense.id,
          answer: this.form.vigorousIntense.value,
        });
        this.loading = false;
        this.setStep(12);
        return true;
      } catch (e) {
        return false;
      }
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
    getOptions(id, template) {
      const options = template
        .filter((template) => template.id === id)
        .map((template) => ({
          text: template.question_option,
          value: template.question_option_id,
        }));
      return options;
    },
    getAnswer(id, answers) {
      if (answers) {
        const answer = answers
          .filter((answer) => id === answer.question_id)
          .map((answer) => answer.answer);
        return answer.length > 0 ? answer[0] : null;
      }
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(`${message}`, {
        title: `Error`,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
