<template>
  <b-overlay :show="loading">
    <b-form class="my-3" @submit.stop.prevent="onSubmit">
      <Breadcrumb :page="4"></Breadcrumb>
      <b-card title="Accessing your personal report" class="mb-3">
        <b-card-text>
          <p class="m-0">
            <i
              >Once you have completed the questionnaire, we will send you a
              personalised Healthy Hearts Lifestyle Report by email.</i
            >
          </p>
          <!-- <b-alert show variant="warning">
          Your personalised report will be sent to you by email and will be
          encrypted using your chosen Password. To ensure your personal
          information is kept safe we recommend that you use a secure account
          and avoid using a shared inbox where possible. We also recommend that
          you choose a Password which is not easily guessed. Please enter the
          email address you would like your report to be sent to and your chosen
          Password below.</b-alert
        > -->
          <b-form-group
            label="Please enter the email address you would like your report to be sent to"
          >
            <small class="form-text text-muted">
              To ensure your personal information is kept safe, please use a
              secure email account and avoid using a shared inbox.
            </small>
            <b-form-input
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              placeholder="Email address"
              aria-describedby="input-1-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback
              >Valid email required</b-form-invalid-feedback
            >
            <!-- <small class="form-text text-muted">
            Your personalised report will be sent to this email address,
            therefore we recommend that you use a secure account and avoid using
            a shared inbox where possible.
          </small> -->
          </b-form-group>
          <b-form-group>
            <b-form-input
              v-model="$v.form.confirmEmail.$model"
              placeholder="Confirm your email address"
              :state="validateState('confirmEmail')"
            ></b-form-input>
            <b-form-invalid-feedback
              >Email addresses don't match, please
              re-enter</b-form-invalid-feedback
            >
          </b-form-group>
          <!-- <div class="row">
            <div class="col form-group">
              <label for="additionalInformation">Password clue</label>
              <b-form-select
                v-model="$v.form.additionalInformation.value.$model"
                :state="validateState('additionalInformation')"
                :options="additionalInformationOptions"
              ></b-form-select>
              <b-form-invalid-feedback
                >Password clue and password is required</b-form-invalid-feedback
              >
            </div>
          </div> -->
          <hr style="background-color: #6300bd" />
          <div class="row">
            <div class="col form-group">
              <label class="m-0 p-0">Choose your password</label>
              <small class="form-text text-muted">
                Your report will be encrypted using a password of your choice.
                We recommend you choose a password that is easy to remember but
                cannot be easily guessed. Use a mix of numbers and letters
                including upper case letters.
              </small>
              <input
                v-model="form.additionalInformationAnswer.value"
                type="text"
                class="form-control"
                maxlength="30"
                minlength="5"
                placeholder="Choose your password"
                required
              />
            </div>
          </div>
          <b-form-group>
            <b-form-input
              v-model="$v.form.confirmPassword.$model"
              placeholder="Confirm your password"
              :state="validateState('confirmPassword')"
            ></b-form-input>
            <b-form-invalid-feedback
              >Passwords don't match, please re-enter</b-form-invalid-feedback
            >
          </b-form-group>
          <hr style="background-color: #6300bd" />
          <b-form-group label="Enter a password reminder">
            <small class="form-text text-muted">
              We will use this to try and help you remember your password if you
              forget it.
            </small>
            <b-form-input
              v-model="form.additionalInformation.value"
              placeholder="Password reminder"
            ></b-form-input>
          </b-form-group>
        </b-card-text>
        <b-card-footer>
          <b-button
            type="submit"
            class="btn btn-hruk-btn mb-2 float-right"
            variant="primary"
            >Continue</b-button
          >
        </b-card-footer>
      </b-card>
    </b-form>
  </b-overlay>
</template>

<script>
import { required, email, sameAs } from "vuelidate/lib/validators";
import * as QuestionTemplate from "./../modules/QuestionTemplate";
import * as Answer from "./../modules/Answer";
import * as Patient from "./../modules/Patient";
import Breadcrumb from "../components/Breadcrumb";
export default {
  components: {
    Breadcrumb,
  },
  data: () => ({
    form: {
      additionalInformation: { id: 516, value: null },
      additionalInformationAnswer: { id: 517, value: null },
      confirmPassword: null,
      email: null,
      confirmEmail: null,
    },
    loading: false,
    additionalInformationOptions: null,
    step: 1,
  }),
  async created() {
    this.$store.dispatch("setPageNumber", 4);
    await QuestionTemplate.questionTemplate();
    if (this.$store.state.patient !== null) {
      const patient = await Patient.getPatient(
        this.$store.state.patient.patient_id
      );
      this.form.email = patient[0].email;
    }

    this.additionalInformationOptions = this.getOptions(
      this.form.additionalInformation.id,
      this.$store.state.template
    );
    await Answer.getAnswers();
    this.form.additionalInformation.value = this.getAnswer(
      this.form.additionalInformation.id,
      this.$store.state.answers
    );
    this.form.additionalInformationAnswer.value = this.getAnswer(
      this.form.additionalInformationAnswer.id,
      this.$store.state.answers
    );
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      additionalInformation: {
        value: { required },
      },
      confirmEmail: {
        required,
        email,
        sameAs: sameAs(function () {
          return this.form.email;
        }),
      },
      confirmPassword: {
        required,
        sameAs: sameAs(function () {
          return this.form.additionalInformationAnswer.value;
        }),
      },
    },
  },
  // mounted() {
  //   this.$refs["warning-message-modal"].show();
  // },
  methods: {
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.loading = true;
      let formDataSubmitted = await this.saveFormData();
      if (!formDataSubmitted) {
        // 2nd Attempt
        await new Promise((r) => setTimeout(r, 2000));
        formDataSubmitted = await this.saveFormData();
        if (!formDataSubmitted) {
          this.makeToast(
            "danger",
            "We have experienced a problem, please try again"
          );
          this.loading = false;
          return;
        }
        this.setStep(5);
      }
    },
    async saveFormData() {
      try {
        await Patient.savePatient({
          email: this.form.email,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.additionalInformation.id,
          answer: this.form.additionalInformation.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.additionalInformationAnswer.id,
          answer: this.form.additionalInformationAnswer.value,
        });
        this.form.additionalInformationAnswer.value;
        this.loading = false;
        this.setStep(5);
        return true;
      } catch (e) {
        return false;
      }
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
    getOptions(id, template) {
      const options = template
        .filter((template) => template.id === id)
        .map((template) => ({
          text: template.question_option,
          value: template.question_option_id,
        }));
      return options;
    },
    getAnswer(id, answers) {
      if (answers) {
        const answer = answers
          .filter((answer) => id === answer.question_id)
          .map((answer) => answer.answer);
        return answer.length > 0 ? answer[0] : null;
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    handleInput(event) {
      const additionalInformationAnswerLower = event.target.value
        .toLowerCase()
        .trim();

      this.form.additionalInformationAnswer.value =
        additionalInformationAnswerLower.replace(/[^a-z0-9]/g, "");
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(`${message}`, {
        title: `Error`,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>

<style>
legend {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
