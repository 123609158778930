<template>
  <b-overlay :show="loading">
    <b-form class="my-3" @submit.stop.prevent="onSubmit">
      <Breadcrumb :page="16"></Breadcrumb>
      <b-card title="Work-life balance" class="mb-3">
        <b-card-text>
          <div class="form-group">
            <label>My employer cares about my health and wellbeing</label>
            <b-form-select
              v-model="form.healthWellbeing.value"
              :options="healthWellbeingOptions"
            ></b-form-select>
          </div>

          <!-- <div class="form-group">
            <label>I enjoy my job</label>
            <b-form-select
              v-model="form.enjoyJob.value"
              :options="enjoyJobOptions"
            ></b-form-select>
          </div> -->

          <div class="form-group">
            <label
              >I have sufficient time and resources to effectively complete my
              allocated tasks</label
            >
            <b-form-select
              v-model="form.sufficientTimeAllocated.value"
              :options="sufficientTimeAllocatedOptions"
            ></b-form-select>
          </div>

          <div class="form-group">
            <label
              >I am able to take regular breaks throughout the working
              day</label
            >
            <b-form-select
              v-model="form.regularBreaks.value"
              :options="regularBreaksOptions"
            ></b-form-select>
          </div>

          <!-- <div class="form-group">
            <label
              >I am given the resources I need to do my job effectively</label
            >
            <b-form-select
              v-model="form.resources.value"
              :options="resourcesOptions"
            ></b-form-select>
          </div> -->

          <!-- <div class="form-group">
            <label
              >I have excellent working relationships with my Line Manager and
              colleagues</label
            >
            <b-form-select
              v-model="form.workingRelationship.value"
              :options="workingRelationshipOptions"
            ></b-form-select>
          </div> -->

          <!-- <div class="form-group">
            <label>I understand what is expected of me at work</label>
            <b-form-select
              v-model="form.expectations.value"
              :options="expectationsOptions"
            ></b-form-select>
          </div> -->

          <!-- <div class="form-group">
            <label>I am kept informed about organisational changes</label>
            <b-form-select
              v-model="form.informed.value"
              :options="informedOptions"
            ></b-form-select>
          </div> -->

          <!-- <div class="form-group">
            <label for="sickness_absence"
              >How many days sickness absence have you had in the last 12
              months?</label
            >
            <input
              v-model="form.sickDays.value"
              type="number"
              class="form-control"
              id="sickness_absence"
              placeholder="Enter sickness absence"
              min="0"
              max="365"
              v-mask="'###'"
            />
          </div> -->

          <div class="form-group">
            <label>I have a good work-life balance</label>
            <b-form-select
              v-model="form.workLifeBalance.value"
              :options="workLifeBalanceOptions"
            ></b-form-select>
          </div>

          <div class="form-group">
            <b-form-group
              label="What steps (if any) could your employer take to make the workplace healthier for you?"
            >
              <b-form-checkbox-group
                v-model="form.healthierWorkplace.value"
                :options="healthierWorkplaceOptions"
                stacked
                multiple
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
          <!-- <div class="form-group">
            <label for="sick_days_worked"
              >On how many days did you work when you felt unwell or unable to
              work to the best of your ability in the past 12 months?</label
            >
            <input
              v-model="form.sickDaysWorked.value"
              type="number"
              class="form-control"
              id="sick_days_worked"
              placeholder="Enter days worked whilst sick"
              min="0"
              max="365"
              v-mask="'###'"
            />
          </div> -->
          <!-- <div class="row">
            <div class="col form-group">
              <label for="beyondHours"
                >How often do you work beyond your contracted hours because you
                have a deadline to meet?</label
              >
              <b-form-select
                v-model="form.beyondHours.value"
                :options="beyondHoursOptions"
              ></b-form-select>
            </div>
          </div> -->
          <!-- <div class="row">
            <div class="col form-group">
              <label for="checkEmails"
                >How often do you check work emails or a work phone outside of
                your working hours?</label
              >
              <b-form-select
                v-model="form.checkEmails.value"
                :options="checkEmailsOptions"
              ></b-form-select>
            </div>
          </div> -->
        </b-card-text>
        <b-card-footer>
          <b-button
            @click="setStep(15)"
            class="btn btn-hruk-btn"
            variant="primary"
            >Previous</b-button
          >
          <b-button
            type="submit"
            class="btn btn-hruk-btn mb-2 float-right"
            variant="primary"
            >Continue</b-button
          >
        </b-card-footer>
      </b-card>
    </b-form>
  </b-overlay>
</template>
<script>
import * as QuestionTemplate from "./../modules/QuestionTemplate";
import * as Answer from "./../modules/Answer";
import Breadcrumb from "../components/Breadcrumb";
export default {
  components: {
    Breadcrumb,
  },
  data: () => ({
    form: {
      healthWellbeing: { id: 471, value: null },
      // enjoyJob: { id: 472, value: null },
      regularBreaks: { id: 474, value: null },
      sufficientTimeAllocated: { id: 473, value: null },
      // resources: { id: 475, value: null },
      // informed: { id: 478, value: null },
      // workingRelationship: { id: 476, value: null },
      // expectations: { id: 477, value: null },
      // beyondHours: { id: 481, value: null },
      // checkEmails: { id: 482, value: null },
      // sickDays: { id: 479, value: null },
      // sickDaysWorked: { id: 480, value: null },
      workLifeBalance: { id: 544, value: null },
      healthierWorkplace: { id: 545, value: [] },
    },
    healthWellbeingOptions: null,
    // enjoyJobOptions: null,
    regularBreaksOptions: null,
    sufficientTimeAllocatedOptions: null,
    // resourcesOptions: null,
    // informedOptions: null,
    // workingRelationshipOptions: null,
    // expectationsOptions: null,
    // beyondHoursOptions: null,
    // checkEmailsOptions: null,
    workLifeBalanceOptions: null,
    healthierWorkplaceOptions: null,
    loading: false,
  }),
  async created() {
    this.$store.dispatch("setPageNumber", 16);
    await QuestionTemplate.questionTemplate();
    await Answer.getAnswers();

    this.healthWellbeingOptions = this.getOptions(
      this.form.healthWellbeing.id,
      this.$store.state.template
    );
    this.form.healthWellbeing.value = this.getAnswer(
      this.form.healthWellbeing.id,
      this.$store.state.answers
    );
    // this.enjoyJobOptions = this.getOptions(
    //   this.form.enjoyJob.id,
    //   this.$store.state.template
    // );
    // this.form.enjoyJob.value = this.getAnswer(
    //   this.form.enjoyJob.id,
    //   this.$store.state.answers
    // );
    this.regularBreaksOptions = this.getOptions(
      this.form.regularBreaks.id,
      this.$store.state.template
    );
    this.form.regularBreaks.value = this.getAnswer(
      this.form.regularBreaks.id,
      this.$store.state.answers
    );
    this.sufficientTimeAllocatedOptions = this.getOptions(
      this.form.sufficientTimeAllocated.id,
      this.$store.state.template
    );
    this.form.sufficientTimeAllocated.value = this.getAnswer(
      this.form.sufficientTimeAllocated.id,
      this.$store.state.answers
    );
    // this.resourcesOptions = this.getOptions(
    //   this.form.resources.id,
    //   this.$store.state.template
    // );
    // this.form.resources.value = this.getAnswer(
    //   this.form.resources.id,
    //   this.$store.state.answers
    // );
    // this.form.resources.value = this.getAnswer(
    //   this.form.resources.id,
    //   this.$store.state.answers
    // );
    // this.workingRelationshipOptions = this.getOptions(
    //   this.form.workingRelationship.id,
    //   this.$store.state.template
    // );
    // this.form.workingRelationship.value = this.getAnswer(
    //   this.form.workingRelationship.id,
    //   this.$store.state.answers
    // );
    // this.expectationsOptions = this.getOptions(
    //   this.form.expectations.id,
    //   this.$store.state.template
    // );
    // this.form.expectations.value = this.getAnswer(
    //   this.form.expectations.id,
    //   this.$store.state.answers
    // );
    // this.informedOptions = this.getOptions(
    //   this.form.informed.id,
    //   this.$store.state.template
    // );
    // this.form.informed.value = this.getAnswer(
    //   this.form.informed.id,
    //   this.$store.state.answers
    // );
    // this.beyondHoursOptions = this.getOptions(
    //   this.form.beyondHours.id,
    //   this.$store.state.template
    // );
    // this.form.beyondHours.value = this.getAnswer(
    //   this.form.beyondHours.id,
    //   this.$store.state.answers
    // );
    // this.checkEmailsOptions = this.getOptions(
    //   this.form.checkEmails.id,
    //   this.$store.state.template
    // );
    // this.form.checkEmails.value = this.getAnswer(
    //   this.form.checkEmails.id,
    //   this.$store.state.answers
    // );
    // this.checkEmailsOptions = this.getOptions(
    //   this.form.checkEmails.id,
    //   this.$store.state.template
    // );
    this.form.workLifeBalance.value = this.getAnswer(
      this.form.workLifeBalance.id,
      this.$store.state.answers
    );
    this.workLifeBalanceOptions = this.getOptions(
      this.form.workLifeBalance.id,
      this.$store.state.template
    );
    this.healthierWorkplaceOptions = this.getOptions(
      this.form.healthierWorkplace.id,
      this.$store.state.template
    );
    this.form.healthierWorkplace.value = this.getMultiSelectAnswer(
      this.form.healthierWorkplace.id,
      this.$store.state.answers
    );
    // this.form.checkEmails.value = this.getAnswer(
    //   this.form.checkEmails.id,
    //   this.$store.state.answers
    // );
    // this.form.sickDays.value = this.getAnswer(
    //   this.form.sickDays.id,
    //   this.$store.state.answers
    // );
    // this.form.sickDaysWorked.value = this.getAnswer(
    //   this.form.sickDaysWorked.id,
    //   this.$store.state.answers
    // );
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      let formDataSubmitted = await this.saveFormData();
      if (!formDataSubmitted) {
        // 2nd Attempt
        await new Promise((r) => setTimeout(r, 2000));
        formDataSubmitted = await this.saveFormData();
        if (!formDataSubmitted) {
          this.makeToast(
            "danger",
            "We have experienced a problem, please try again"
          );
          this.loading = false;
          return;
        }
      }
    },
    async saveFormData() {
      try {
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.healthWellbeing.id,
          answer: this.form.healthWellbeing.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.regularBreaks.id,
          answer: this.form.regularBreaks.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.sufficientTimeAllocated.id,
          answer: this.form.sufficientTimeAllocated.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.workLifeBalance.id,
          answer: this.form.workLifeBalance.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.healthierWorkplace.id,
          answer: this.form.healthierWorkplace.value,
        });
        this.loading = false;
        this.setStep(17);
        return true;
      } catch (e) {
        return false;
      }
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
    getOptions(id, template) {
      const options = template
        .filter((template) => template.id === id)
        .map((template) => ({
          text: template.question_option,
          value: template.question_option_id,
        }));
      return options;
    },
    getAnswer(id, answers) {
      if (answers) {
        const answer = answers
          .filter((answer) => id === answer.question_id)
          .map((answer) => answer.answer);
        return answer.length > 0 ? answer[0] : null;
      }
    },
    getMultiSelectAnswer(id, answers) {
      const answer = answers
        .filter((answer) => id === answer.question_id && answer.answer !== null)
        .map((answer) => answer.answer);
      return answer.length > 0 ? JSON.parse(answer[0]) : [];
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(`${message}`, {
        title: `Error`,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
