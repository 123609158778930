<template>
  <div class="my-3">
    <b-card
      title="Thank you for completing your Healthy Heart Assessment!"
      class="mb-3"
    >
      <p>
        After a few minutes, you will receive your personalised Lifestyle
        Report, which is sent to the email address that you provided. You will
        need to use the password you gave us to access the report. If you do not
        receive your report within one hour, please check your junk mail and
        contact us at the below email address if you have any technical
        problems.
      </p>
      <a href="mailto:heartsatwork@heartresearch.org.uk"
        >heartsatwork@heartresearch.org.uk</a
      >
    </b-card>
  </div>
</template>
<script>
// import * as QuestionTemplate from "./../modules/QuestionTemplate";
// import * as Answer from "./../modules/Answer";
import * as CaseAppointment from "./../modules/CaseAppointment";
export default {
  async created() {
    this.$store.dispatch("setPageNumber", 20);
    await CaseAppointment.updateCaseAppointment({
      statusId: 17,
    });
    // await QuestionTemplate.questionTemplate();
    // await Answer.getAnswers();
    // this.form.consentPersonalData.value = this.getAnswerBoolean(
    //   this.form.consentPersonalData.id,
    //   this.$store.state.answers
    // );
    // this.form.consentHeartResearchSupport.value = this.getAnswerBoolean(
    //   this.form.consentHeartResearchSupport.id,
    //   this.$store.state.answers
    // );
  },
  methods: {
    // setStep(step) {
    //   this.$store.dispatch("setStep", step);
    // },
  },
};
</script>
<style>
.btn.active {
  background: #6300bd !important;
}
</style>
