<template>
  <div class="my-3">
    <b-form @submit.stop.prevent="onSubmit">
      <b-card
        title="Welcome to your Healthy Hearts Lifestyle Questionnaire"
        class="mb-3"
      >
        <b-card-text>
          <div class="row">
            <div class="col">
              <p>
                By completing this confidential lifestyle questionnaire, you
                will be helping yourself and your employer to make positive
                changes to support your health and wellbeing. Once you have
                completed the questionnaire, we will send you a bespoke report
                with individual feedback on your responses as well as tips for
                looking after your heart health.
              </p>
              <p>
                All employees can complete this questionnaire, regardless of whether you work remotely or on-site.
              </p>
            </div>
          </div>
        </b-card-text>
        <b-card-footer>
          <b-button
            type="submit"
            class="btn btn-hruk-btn mb-2 float-right"
            variant="primary"
            >Continue</b-button
          >
        </b-card-footer>
      </b-card>
    </b-form>
  </div>
</template>

<script>
export default {
  name: "yourConsent",
  components: {},
  data() {
    return {};
  },
  async created() {
    this.$store.dispatch("setPageNumber", 1);
  },
  methods: {
    async onSubmit() {
      this.$store.dispatch("setStep", 2);
    },
  },
};
</script>
