<template>
  <div class="container">
    <YourConsent v-if="step === 1 && campaign.length > 0" />

    <CompletingQuestionnaire v-if="step === 2 && campaign.length > 0" />

    <YourData v-if="step === 3 && campaign.length > 0" />

    <Passphrase v-if="step === 4 && campaign.length > 0" />

    <AboutYou v-if="step === 5 && campaign.length > 0" />

    <BodyComposition v-if="step === 6 && campaign.length > 0" />

    <MedicalHistory v-if="step === 7 && campaign.length > 0" />

    <YourMeasurements v-if="step === 8 && campaign.length > 0" />

    <GeneralActivity v-if="step === 9 && campaign.length > 0" />

    <GeneralActivityContinued v-if="step === 10 && campaign.length > 0" />

    <PhysicalActivity v-if="step === 11 && campaign.length > 0" />

    <DietaryHabits v-if="step === 12 && campaign.length > 0" />

    <DietaryHabitsContinued v-if="step === 13 && campaign.length > 0" />

    <DietaryHabitsContinued2 v-if="step === 14 && campaign.length > 0" />

    <DrinkingSmoking v-if="step === 15 && campaign.length > 0" />

    <WorkStress v-if="step === 16 && campaign.length > 0" />

    <Sleep v-if="step === 17 && campaign.length > 0" />

    <ReadinessToChange v-if="step === 18 && campaign.length > 0" />

    <NextBigBreakthrough v-if="step === 19 && campaign.length > 0" />

    <Finish v-if="step === 20 && campaign.length > 0" />

    <b-alert v-if="campaignError" show variant="success" class="mt-3"
      >The questionnaire you are trying to access has now closed or does not
      exist, please contact the person who provided you with this link for
      support</b-alert
    >
  </div>
</template>

<script>
import YourConsent from "../components/LifestyleQuestionsYourConsent";
import CompletingQuestionnaire from "../components/CompletingQuestionnaire";
import Passphrase from "../components/LifestyleQuestionsPassphrase";
import YourData from "../components/YourData";
import AboutYou from "../components/LifestyleQuestionsAboutYou";
import BodyComposition from "../components/LifestyleQuestionsBodyComposition";
import YourMeasurements from "../components/LifestyleQuestionsYourMeasurement";
import PhysicalActivity from "../components/LifestyleQuestionsPhysicalActivity";
import GeneralActivity from "../components/LifestyleQuestionsGeneralActivity";
import GeneralActivityContinued from "../components/LifestyleQuestionsGeneralActivityContinued";
import DietaryHabits from "../components/LifestyleQuestionsDietaryHabits";
import DietaryHabitsContinued from "../components/LifestyleQuestionsDietaryHabitsContinued";
import DietaryHabitsContinued2 from "../components/LifestyleQuestionsDietaryHabitsContinued2";
import DrinkingSmoking from "../components/LifestyleQuestionsDrinkingSmoking";
import WorkStress from "../components/LifestyleQuestionsWorkStress";
import Sleep from "../components/LifestyleQuestionsSleep";
import ReadinessToChange from "../components/LifestyleQuestionsReadinessToChange";
import NextBigBreakthrough from "../components/LifestyleNextBigBreakthrough";
import MedicalHistory from "../components/LifestyleQuestionsMedicalHistory";
import Finish from "../components/LifestyleQuestionsFinish";
import * as Campaign from "./../modules/Campaign";
export default {
  name: "lifestyleQuestionnaire",
  components: {
    YourConsent,
    CompletingQuestionnaire,
    YourData,
    AboutYou,
    BodyComposition,
    Passphrase,
    YourMeasurements,
    PhysicalActivity,
    GeneralActivity,
    GeneralActivityContinued,
    DietaryHabits,
    DietaryHabitsContinued,
    DietaryHabitsContinued2,
    DrinkingSmoking,
    WorkStress,
    Sleep,
    ReadinessToChange,
    NextBigBreakthrough,
    MedicalHistory,
    Finish,
  },
  data: () => ({
    answers: [],
    campaignError: false,
  }),
  async created() {
    const campaign = await Campaign.getCampaign(this.$route.params.code);
    if (campaign.length === 0) {
      this.campaignError = true;
    }
  },
  methods: {
    changeStep(step) {
      this.$store.dispatch("setStep", step);
    },
  },
  computed: {
    step() {
      return this.$store.state.step;
    },
    campaign() {
      return this.$store.state.campaign === null
        ? []
        : this.$store.state.campaign;
    },
  },
};
</script>
<style scoped>
.checkbox-row {
  margin-top: 0 !important;
}
</style>
