<template>
  <b-overlay :show="loading">
    <b-form class="my-3" @submit.stop.prevent="onSubmit">
      <Breadcrumb :page="13"></Breadcrumb>
      <b-card title="Dietary Habits Continued" class="mb-3">
        <b-card-text>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label">
              Do you add salt to food when cooking?</label
            >
            <div class="col-sm-6">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.saltCooking.value"
                  :options="saltCookingOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label">
              Do you add salt to food at the table?</label
            >
            <div class="col-sm-6">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.saltTable.value"
                  :options="saltTableOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label">
              Do you eat breakfast?</label
            >
            <div class="col-sm-6">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.breakfast.value"
                  :options="breakfastOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label">
              Do you feel that you eat more than you need?
            </label>
            <div class="col-sm-6">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.eatMore.value"
                  :options="eatMoreOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label">
              Do you choose foods that are lower in fat or sugar?<b-icon
                v-b-modal.modal-center-lowfat
                icon="question-circle-fill"
                color="#6300bd"
                class="ml-2"
              ></b-icon>
            </label>
            <div class="col-sm-6">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.lowFatFoods.value"
                  :options="lowFatFoodsOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label">
              Do you add sugar to hot drinks such as tea or coffee?<b-icon
                v-b-modal.modal-center-addSugar
                icon="question-circle-fill"
                color="#6300bd"
                class="ml-2"
              ></b-icon>
            </label>
            <div class="col-sm-6">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.addSugar.value"
                  :options="addSugarOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col form-group">
              <b-form-group
                label="Do you add sugar to hot drinks such as tea or coffee?"
              >
                <b-form-radio-group
                  v-model="form.addSugar.value"
                  :options="addSugarOptions"
                ></b-form-radio-group>
              </b-form-group>
            </div>
          </div> -->
          <div class="form-group row">
            <label class="col-sm-6 col-form-label">
              In an average week, how often do you consume sugary drinks?<b-icon
                v-b-modal.modal-center-sugaryDrinks
                icon="question-circle-fill"
                color="#6300bd"
                class="ml-2"
              ></b-icon>
            </label>
            <div class="col-sm-6">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.sugaryDrinks.value"
                  :options="sugaryDrinksOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
          </div>
          <div>
            <b-modal
              id="modal-center-lowfat"
              centered
              title="What are some examples of low fat foods?"
              ok-only
            >
              <p>
                Low fat options include skimmed or semi-skimmed milk, low-fat
                spreads or cheeses, reduced fat or reduced sugar yogurts,
                reduced fat cuts of meat.
              </p>
            </b-modal>
          </div>
          <div>
            <b-modal
              id="modal-center-addSugar"
              centered
              title="Add sugar?"
              ok-only
            >
              <p>If you add low calorie sweetener to your drink, select no.</p>
            </b-modal>
          </div>
          <div>
            <b-modal
              id="modal-center-sugaryDrinks"
              centered
              title="Sugary drinks?"
              ok-only
            >
              <p>
                Sugary drinks include fizzy drinks made with sugar (e.g. cola,
                lemonade), fruit juice made with added sugar, squash made with
                sugar, milkshakes made with added sugar, energy drinks made with
                sugar and hot drinks with added sugar or syrups, such as tea,
                coffee, hot chocolate or lattes.
              </p>
            </b-modal>
          </div>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label">
              Does your work environment, whether at home or otherwise, support
              you in making healthier food and drink choices?</label
            >
            <div class="col-sm-5">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.healthierEnvironment.value"
                  :options="healthierEnvironmentOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
          </div>
        </b-card-text>
        <b-card-footer>
          <b-button
            @click="setStep(12)"
            class="btn btn-hruk-btn"
            variant="primary"
            >Previous</b-button
          >
          <b-button
            type="submit"
            class="btn btn-hruk-btn mb-2 float-right"
            variant="primary"
            >Continue</b-button
          >
        </b-card-footer>
      </b-card>
    </b-form>
  </b-overlay>
</template>

<script>
import * as QuestionTemplate from "./../modules/QuestionTemplate";
import * as Answer from "./../modules/Answer";
import Breadcrumb from "../components/Breadcrumb";
export default {
  components: {
    Breadcrumb,
  },
  data: () => ({
    form: {
      saltCooking: { id: 452, value: null },
      saltTable: { id: 453, value: null },
      breakfast: { id: 456, value: null },
      eatMore: { id: 458, value: null },
      lowFatFoods: { id: 451, value: null },
      healthierEnvironment: { id: 457, value: null },
      sugaryDrinks: { id: 463, value: null },
      addSugar: { id: 464, value: null },
    },
    saltCookingOptions: null,
    saltTableOptions: null,
    breakfastOptions: null,
    eatMoreOptions: null,
    lowFatFoodsOptions: null,
    healthierEnvironmentOptions: null,
    sugaryDrinksOptions: null,
    addSugarOptions: null,
    loading: false,
  }),
  async created() {
    this.$store.dispatch("setPageNumber", 13);
    await QuestionTemplate.questionTemplate();
    await Answer.getAnswers();

    this.form.saltCooking.value = this.getAnswer(
      this.form.saltCooking.id,
      this.$store.state.answers
    );
    this.saltCookingOptions = this.getOptions(
      this.form.saltCooking.id,
      this.$store.state.template
    );
    this.form.saltCooking.value = this.getAnswer(
      this.form.saltCooking.id,
      this.$store.state.answers
    );
    this.saltTableOptions = this.getOptions(
      this.form.saltTable.id,
      this.$store.state.template
    );
    this.form.saltTable.value = this.getAnswer(
      this.form.saltTable.id,
      this.$store.state.answers
    );
    this.breakfastOptions = this.getOptions(
      this.form.breakfast.id,
      this.$store.state.template
    );
    this.form.breakfast.value = this.getAnswer(
      this.form.breakfast.id,
      this.$store.state.answers
    );
    this.eatMoreOptions = this.getOptions(
      this.form.eatMore.id,
      this.$store.state.template
    );
    this.form.eatMore.value = this.getAnswer(
      this.form.eatMore.id,
      this.$store.state.answers
    );
    this.lowFatFoodsOptions = this.getOptions(
      this.form.lowFatFoods.id,
      this.$store.state.template
    );
    this.form.lowFatFoods.value = this.getAnswer(
      this.form.lowFatFoods.id,
      this.$store.state.answers
    );
    this.healthierEnvironmentOptions = this.getOptions(
      this.form.healthierEnvironment.id,
      this.$store.state.template
    );
    this.form.healthierEnvironment.value = this.getAnswer(
      this.form.healthierEnvironment.id,
      this.$store.state.answers
    );
    this.sugaryDrinksOptions = this.getOptions(
      this.form.sugaryDrinks.id,
      this.$store.state.template
    );
    this.form.sugaryDrinks.value = this.getAnswer(
      this.form.sugaryDrinks.id,
      this.$store.state.answers
    );
    this.addSugarOptions = this.getOptions(
      this.form.addSugar.id,
      this.$store.state.template
    );
    this.form.addSugar.value = this.getAnswer(
      this.form.addSugar.id,
      this.$store.state.answers
    );
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      let formDataSubmitted = await this.saveFormData();
      if (!formDataSubmitted) {
        // 2nd Attempt
        await new Promise((r) => setTimeout(r, 2000));
        formDataSubmitted = await this.saveFormData();
        if (!formDataSubmitted) {
          this.makeToast(
            "danger",
            "We have experienced a problem, please try again"
          );
          this.loading = false;
          return;
        }
      }
    },
    async saveFormData() {
      try {
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.saltCooking.id,
          answer: this.form.saltCooking.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.saltTable.id,
          answer: this.form.saltTable.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.breakfast.id,
          answer: this.form.breakfast.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.eatMore.id,
          answer: this.form.eatMore.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.lowFatFoods.id,
          answer: this.form.lowFatFoods.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.healthierEnvironment.id,
          answer: this.form.healthierEnvironment.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.sugaryDrinks.id,
          answer: this.form.sugaryDrinks.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.addSugar.id,
          answer: this.form.addSugar.value,
        });
        this.loading = false;
        this.setStep(14);
        return true;
      } catch (e) {
        return false;
      }
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
    getOptions(id, template) {
      const options = template
        .filter((template) => template.id === id)
        .map((template) => ({
          text: template.question_option,
          value: template.question_option_id,
        }));
      return options;
    },
    getAnswer(id, answers) {
      if (answers) {
        const answer = answers
          .filter((answer) => id === answer.question_id)
          .map((answer) => answer.answer);
        return answer.length > 0 ? answer[0] : null;
      }
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(`${message}`, {
        title: `Error`,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
