import { API } from "aws-amplify";
import store from "../store";
import { get_campaign } from "./../graphql/queries";

export async function getCampaign(code) {
  try {
    if (store.state.campaign === null) {
      var {
        data: { get_campaign: campaign },
      } = await API.graphql({
        query: get_campaign,
        variables: { code: code },
        authMode: "API_KEY",
      });
    } else {
      campaign = store.state.campaign;
    }
    if(campaign.length > 0) { // Catch non active campaign
      if(campaign[0].campaign_active === false) {
        store.dispatch("setCampaign", []);
        return [];
      }
    }
    store.dispatch("setCampaign", campaign);
    return campaign;
  } catch (e) {
    throw new Error(e.errors[0].message);
  }
}
