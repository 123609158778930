<template>
  <b-breadcrumb>
    <!-- <b-breadcrumb-item v-if="page >= 1" :active="page <= 1" @click="setStep(1)"
      >Your consent</b-breadcrumb-item
    > -->
    <!-- <b-breadcrumb-item v-if="page >= 2" :active="page <= 2" @click="setStep(2)"
      >Completing questionnaire</b-breadcrumb-item
    >
    <b-breadcrumb-item v-if="page >= 3" :active="page <= 3" @click="setStep(3)"
      >Your data</b-breadcrumb-item
    > -->
    <b-breadcrumb-item v-if="page >= 4" :active="page <= 4" @click="setStep(4)"
      >Password</b-breadcrumb-item
    >
    <b-breadcrumb-item v-if="page >= 5" :active="page <= 5" @click="setStep(5)"
      >About you</b-breadcrumb-item
    >
    <b-breadcrumb-item v-if="page >= 6" :active="page <= 6" @click="setStep(6)"
      >Body composition</b-breadcrumb-item
    >
    <b-breadcrumb-item v-if="page >= 7" :active="page <= 7" @click="setStep(7)"
      >Medical history</b-breadcrumb-item
    >
    <b-breadcrumb-item v-if="page >= 8" :active="page <= 8" @click="setStep(8)"
      >Medical history continued</b-breadcrumb-item
    >
    <b-breadcrumb-item v-if="page >= 9" :active="page <= 9" @click="setStep(9)"
      >Activity levels</b-breadcrumb-item
    >
    <b-breadcrumb-item
      v-if="page >= 10"
      :active="page <= 10"
      @click="setStep(10)"
      >Activity continued</b-breadcrumb-item
    >
    <b-breadcrumb-item
      v-if="page >= 11"
      :active="page <= 11"
      @click="setStep(11)"
      >Physical activity</b-breadcrumb-item
    >
    <b-breadcrumb-item
      v-if="page >= 12"
      :active="page <= 12"
      @click="setStep(12)"
      >Dietary habits</b-breadcrumb-item
    >
    <b-breadcrumb-item
      v-if="page >= 13"
      :active="page <= 13"
      @click="setStep(13)"
      >Dietary habits continued</b-breadcrumb-item
    >
    <b-breadcrumb-item
      v-if="page >= 14"
      :active="page <= 14"
      @click="setStep(14)"
      >Dietary habits continued</b-breadcrumb-item
    >
    <b-breadcrumb-item
      v-if="page >= 15"
      :active="page <= 15"
      @click="setStep(15)"
      >Alcohol and smoking</b-breadcrumb-item
    >
    <b-breadcrumb-item
      v-if="page >= 16"
      :active="page <= 16"
      @click="setStep(16)"
      >Work and stress</b-breadcrumb-item
    >
    <b-breadcrumb-item
      v-if="page >= 17"
      :active="page <= 17"
      @click="setStep(17)"
      >Sleep</b-breadcrumb-item
    >
    <b-breadcrumb-item
      v-if="page >= 18"
      :active="page <= 18"
      @click="setStep(18)"
      >Readiness to change</b-breadcrumb-item
    >
    <b-breadcrumb-item
      v-if="page >= 19"
      :active="page <= 19"
      @click="setStep(19)"
      >Next big breakthrough</b-breadcrumb-item
    >
  </b-breadcrumb>
</template>

<script>
export default {
  props: {
    page: Number,
  },
  methods: {
    setStep(step) {
      if (step !== this.page) {
        this.$store.dispatch("setStep", step);
      }
    },
  },
};
</script>
