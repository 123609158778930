<template>
  <b-overlay :show="loading">
    <b-form class="my-3" @submit.stop.prevent="onSubmit">
      <Breadcrumb :page="8"></Breadcrumb>
      <b-card title="Medical History" class="mb-3">
        <b-card-text>
          <div class="form-group row">
            <label class="col-sm-7 col-form-label">
              Have you ever been told that your blood pressure is high?<b-icon
                v-b-modal.modal-center-bp-high
                icon="question-circle-fill"
                color="#6300bd"
                class="ml-2"
              ></b-icon
            ></label>
            <div class="col-sm-5">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.highBloodPressure.value"
                  :options="bpOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
            <div>
              <b-modal
                id="modal-center-bp-high"
                centered
                title="Blood Pressure"
                ok-only
              >
                <p>
                  A blood pressure reading of greater than 140/90mmHg is classed
                  as high.
                </p>
              </b-modal>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-7 col-form-label">
              Have you had your blood pressure checked in the last 5
              years?</label
            >
            <div class="col-sm-5">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.bloodPressureChecked.value"
                  :options="bpCheckedOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-7 col-form-label">
              Are you currently taking medication to lower your blood pressure?
              <b-icon
                v-b-modal.modal-center-medication
                class="ml-2"
                icon="question-circle-fill"
                color="#6300bd"
              ></b-icon>
            </label>
            <div class="col-sm-5">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.bloodPressureMedication.value"
                  :options="bpMedsOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
            <b-modal
              id="modal-center-medication"
              centered
              title="Medication"
              ok-only
            >
              <p>
                Common blood pressure medications include Atenolol, Ramipril,
                Losartan and Amlodipine.
              </p>
            </b-modal>
          </div>
          <div class="form-group row">
            <label class="col-sm-7 col-form-label">
              Have you had your cholesterol checked in the last 5 years?
              <b-icon
                v-b-modal.modal-center-cholesterol
                class="ml-2"
                icon="question-circle-fill"
                color="#6300bd"
              ></b-icon
            ></label>
            <div class="col-sm-5">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.cholesterolChecked.value"
                  :options="cholCheckedOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
            <b-modal
              id="modal-center-cholesterol"
              centered
              title="Cholesterol"
              ok-only
            >
              <p>
                Cholesterol is a fatty substance in your blood. A cholesterol
                test involves taking a small blood sample (either a finger prick
                test or from a blood sample taken from your arm).
              </p>
            </b-modal>
          </div>
          <div class="form-group row">
            <label class="col-sm-7 col-form-label">
              Have you ever been told that your cholesterol is high?
            </label>
            <div class="col-sm-5">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.cholesterolHigh.value"
                  :options="cholHighOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-7 col-form-label">
              Are you currently taking medication to lower your cholesterol?
              <b-icon
                v-b-modal.modal-center-cholesterol-medication
                class="ml-2"
                b-icon
                icon="question-circle-fill"
                color="#6300bd"
              ></b-icon>
            </label>
            <div class="col-sm-5">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.cholesterolMedication.value"
                  :options="cholMedicalOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
            <b-modal
              v-b-modal.modal-center-cholesterol-medication
              id="modal-center-cholesterol-medication"
              centered
              title="Cholesterol medication"
              ok-only
            >
              <p>
                Statins are the most common cholesterol lowering medication so
                if you are taking these then answer 'yes'.
              </p>
            </b-modal>
          </div>
          <!-- <div class="form-group row">
            <label class="col-sm-7 col-form-label">
              Have any of your first-degree relatives (father, mother brother or
              sister) been diagnosed with angina, hypertension (high blood
              pressure), heart attack, stroke or type 2 diabetes?
            </label>
            <div class="col-sm-5">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.relativeWithChestHeartStroke.value"
                  :options="optionsPreferNotToSay"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-7 col-form-label">
              Have you ever been diagnosed with angina, hypertension (high blood
              pressure), heart attack, stroke or type 2 diabetes?
            </label>
            <div class="col-sm-5">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.diagnosedWithChestHeartStroke.value"
                  :options="optionsPreferNotToSay"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
          </div> -->
          <b-alert
            show
            variant="warning"
            v-if="
              (form.bloodPressureChecked.value == '226' &&
                form.bloodPressureChecked.value != null) ||
              (form.cholesterolChecked.value == '226' &&
                form.cholesterolChecked.value != null)
            "
            >If you have you results available, please enter them below. Leave
            blank any readings you do not have or are not sure about.</b-alert
          >
          <div
            class="row"
            v-if="
              form.bloodPressureChecked.value == '226' &&
              form.bloodPressureChecked.value != null
            "
          >
            <div class="col-12">
              <p class="m-0 p-0"><i>Your blood pressure readings</i></p>
            </div>
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label for="blood_pressure"
                  >Blood Pressure Systolic<b-icon
                    v-b-modal.modal-center-bp-systolic
                    class="pt-2"
                    icon="question-circle-fill"
                    color="#6300bd"
                    style="width: 30px; height: 25px"
                  ></b-icon
                ></label>
                <b-form-input
                  v-model="$v.form.bpSystolic.value.$model"
                  :state="validateState('bpSystolic')"
                  type="number"
                  id="blood_pressure_systolic"
                  placeholder="Enter Blood Pressure"
                  min="50"
                  max="250"
                ></b-form-input>
                <b-form-invalid-feedback
                  >Value must be between 50-250</b-form-invalid-feedback
                >
                <div>
                  <b-modal
                    id="modal-center-bp-systolic"
                    centered
                    title="Blood Pressure"
                    ok-only
                  >
                    <p>
                      When entering your blood pressure, please enter the
                      highest number (systolic pressure) first, and the lower
                      number (diastolic pressure) second. The top number is your
                      systolic blood pressure, which is the highest level your
                      blood pressure reaches when your heart beats and forces
                      blood around your body. The bottom number is your
                      diastolic blood pressure and this represents your blood
                      pressure when your heart is at rest, in between beats.
                      Blood pressure is measured in millimetres of mercury
                      (mmHg). If the top number is 120 and the bottom number is
                      80, this would be written as 120/80mmHg and you would say
                      that your blood pressure reading is '120 over 80'.
                    </p>
                  </b-modal>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label for="blood_pressure">Blood Pressure Diastolic</label>
                <b-form-input
                  v-model="$v.form.bpDiastolic.value.$model"
                  :state="validateState('bpDiastolic')"
                  type="number"
                  id="blood_pressure_diastolic"
                  placeholder="Enter Blood Pressure"
                  min="30"
                  max="150"
                ></b-form-input>
                <b-form-invalid-feedback
                  >Value must be between 30-150</b-form-invalid-feedback
                >
                <div>
                  <b-modal
                    id="modal-center-bp-diastolic"
                    centered
                    title="Blood Pressure"
                    ok-only
                  >
                    <p>
                      When your blood pressure is measured, you will be given
                      two readings (a top number and a bottom number). The top
                      number is your systolic blood pressure, which is the
                      highest level your blood pressure reaches when your heart
                      beats and forces blood around your body. The bottom number
                      is your diastolic blood pressure and this represents your
                      blood pressure when your heart is at rest, in between
                      beats. Blood pressure is measured in millimetres of
                      mercury (mmHg). If the top number is 120 and the bottom
                      number is 80, this would be written as 120/80mmHg and you
                      would say that your blood pressure reading is '120 over
                      80'.
                    </p>
                  </b-modal>
                </div>
              </div>
            </div>
          </div>

          <div
            class="row"
            v-if="
              form.cholesterolChecked.value == '226' &&
              form.cholesterolChecked.value != null
            "
          >
            <div class="col-12">
              <p class="m-0 p-0"><i>Your cholesterol readings</i></p>
            </div>
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label for="total_cholesterol"
                  >Total Cholesterol
                  <!-- <b-icon
                  v-b-modal.modal-center-total-cholesterol
                  class="pt-2"
                  icon="question-circle-fill"
                  color="#6300bd"
                  style="width: 30px; height: 25px"
                ></b-icon> -->
                </label>
                <b-form-input
                  v-model="$v.form.totalCholesterol.value.$model"
                  :state="validateState('totalCholesterol')"
                  id="total_cholesterol"
                  placeholder="Enter Total Cholesterol"
                ></b-form-input>
                <b-form-invalid-feedback
                  >Value must be between 1.0-10.0</b-form-invalid-feedback
                >
                <!-- <div>
                <b-modal
                  id="modal-center-total-cholesterol"
                  centered
                  title="Total Cholesterol"
                  ok-only
                >
                  <p>
                    This is sometimes written as TC or serum cholesterol, and it
                    refers to the total amount of cholesterol in your blood
                  </p>
                </b-modal>
              </div> -->
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label for="hdl_cholesterol"
                  >HDL Cholesterol
                  <!-- <b-icon
                  v-b-modal.modal-center-hdl-cholesterol
                  class="pt-2"
                  icon="question-circle-fill"
                  color="#6300bd"
                  style="width: 30px; height: 25px"
                ></b-icon> -->
                </label>

                <!-- <input
                type="number"
                class="form-control"
                id="hdl_cholesterol"
                placeholder="Enter HDL Cholesterol"
              /> -->
                <b-form-input
                  v-model="$v.form.hdlCholesterol.value.$model"
                  :state="validateState('hdlCholesterol')"
                  id="hdl_cholesterol"
                  placeholder="Enter HDL Cholesterol"
                ></b-form-input>
                <b-form-invalid-feedback
                  >Value must be between 0.2-5.0</b-form-invalid-feedback
                >
                <!-- <div>
                <b-modal
                  id="modal-center-hdl-cholesterol"
                  centered
                  title="HDL Cholesterol"
                  ok-only
                >
                  <p>
                    HDL stands for 'high density lipoprotein' and is typically
                    known as 'good' cholesterol as it gets rid of excess LDL
                    from the bloodstream
                  </p>
                </b-modal>
              </div> -->
              </div>
            </div>
            <!-- <div class="col-12 col-sm-6">
              <div class="form-group">
                <label for="non_hdl_cholesterol">Non-HDL Cholesterol </label>
                <b-form-input
                  v-model="$v.form.nonHdlCholesterol.value.$model"
                  :state="validateState('nonHdlCholesterol')"
                  id="non_hdl_cholesterol"
                  placeholder="Enter Non-HDL Cholesterol"
                ></b-form-input>
                <b-form-invalid-feedback
                  >Value must be between 1.0-9.0</b-form-invalid-feedback
                >
              </div>
            </div> -->
          </div>
          <div
            class="row"
            v-if="
              form.cholesterolChecked.value == '226' &&
              form.cholesterolChecked.value != null
            "
          >
            <div class="col-12 col-sm-12">
              <div class="form-group">
                <label for="total_cholesterol_hdl_ratio"
                  >Total Cholesterol:HDL ratio
                  <!-- <b-icon
                  v-b-modal.modal-center-cholesterol-hdl-ratio
                  class="pt-2"
                  icon="question-circle-fill"
                  color="#6300bd"
                  style="width: 30px; height: 25px"
                ></b-icon> -->
                </label>
                <b-form-input
                  v-model="$v.form.totalHdlCholesterol.value.$model"
                  :state="validateState('totalHdlCholesterol')"
                  id="total_cholesterol_hdl_ratio"
                  placeholder="Enter Total Cholesterol:HDL ratio"
                ></b-form-input>
                <b-form-invalid-feedback
                  >Value must be between 1.5-10</b-form-invalid-feedback
                >
                <!-- <div>
                <b-modal
                  id="modal-center-cholesterol-hdl-ratio"
                  centered
                  title="Total Cholesterol:HDL ratio"
                  ok-only
                >
                  <p>
                    This refers to the amount of good cholesterol in comparison
                    to bad cholesterol in your bloodstream.
                  </p>
                </b-modal>
              </div> -->
              </div>
            </div>
          </div>
        </b-card-text>
        <b-card-footer>
          <b-button
            @click="setStep(7)"
            class="btn btn-hruk-btn"
            variant="primary"
            >Previous</b-button
          >
          <b-button
            type="submit"
            class="btn btn-hruk-btn mb-2 float-right"
            variant="primary"
            >Continue</b-button
          >
        </b-card-footer>
      </b-card>
    </b-form>
  </b-overlay>
</template>

<script>
import { minValue, maxValue } from "vuelidate/lib/validators";
import * as QuestionTemplate from "./../modules/QuestionTemplate";
import * as Answer from "./../modules/Answer";
import Breadcrumb from "../components/Breadcrumb";
export default {
  components: {
    Breadcrumb,
  },
  data: () => ({
    form: {
      highBloodPressure: { id: 418, value: null },
      bloodPressureMedication: { id: 421, value: null },
      bloodPressureChecked: { id: 419, value: null },
      cholesterolChecked: { id: 422, value: null },
      cholesterolHigh: { id: 423, value: null },
      cholesterolMedication: { id: 431, value: null },
      bp: null,
      bpSystolic: { id: 420, value: null },
      bpDiastolic: { id: 512, value: null },
      totalCholesterol: { id: 426, value: null },
      // nonHdlCholesterol: { id: 427, value: null },
      hdlCholesterol: { id: 428, value: null },
      totalHdlCholesterol: { id: 429, value: null },
      relativeWithChestHeartStroke: { id: 0, value: null },
      diagnosedWithChestHeartStroke: { id: 0, value: null },
    },
    options: [
      { text: "Yes", value: "yes" },
      { text: "No", value: "no" },
      { text: "Don't know", value: "dontknow" },
    ],
    optionsPreferNotToSay: [
      { text: "Yes", value: "yes" },
      { text: "No", value: "no" },
      { text: "Don't know/Prefer not to say", value: "dontknow" },
    ],
    bpOptions: {},
    bpCheckedOptions: {},
    bpMedsOptions: {},
    cholCheckedOptions: {},
    cholHighOptions: {},
    cholMedicalOptions: {},
    loading: false,
  }),
  async created() {
    this.$store.dispatch("setPageNumber", 8);
    await QuestionTemplate.questionTemplate();
    await Answer.getAnswers();
    this.bpOptions = this.getOptions(
      this.form.highBloodPressure.id,
      this.$store.state.template
    );
    this.bpCheckedOptions = this.getOptions(
      this.form.bloodPressureChecked.id,
      this.$store.state.template
    );
    this.bpMedsOptions = this.getOptions(
      this.form.bloodPressureMedication.id,
      this.$store.state.template
    );
    this.cholCheckedOptions = this.getOptions(
      this.form.cholesterolChecked.id,
      this.$store.state.template
    );
    this.cholHighOptions = this.getOptions(
      this.form.cholesterolHigh.id,
      this.$store.state.template
    );
    this.cholMedicalOptions = this.getOptions(
      this.form.cholesterolMedication.id,
      this.$store.state.template
    );

    await Answer.getAnswers();
    this.form.highBloodPressure.value = this.getAnswer(
      this.form.highBloodPressure.id,
      this.$store.state.answers
    );
    this.form.bloodPressureChecked.value = this.getAnswer(
      this.form.bloodPressureChecked.id,
      this.$store.state.answers
    );
    this.form.bloodPressureMedication.value = this.getAnswer(
      this.form.bloodPressureMedication.id,
      this.$store.state.answers
    );
    this.form.cholesterolChecked.value = this.getAnswer(
      this.form.cholesterolChecked.id,
      this.$store.state.answers
    );
    this.form.cholesterolHigh.value = this.getAnswer(
      this.form.cholesterolHigh.id,
      this.$store.state.answers
    );
    this.form.cholesterolMedication.value = this.getAnswer(
      this.form.cholesterolMedication.id,
      this.$store.state.answers
    );
    this.form.totalCholesterol.value = this.getAnswer(
      this.form.totalCholesterol.id,
      this.$store.state.answers
    );
    // this.form.nonHdlCholesterol.value = this.getAnswer(
    //   this.form.nonHdlCholesterol.id,
    //   this.$store.state.answers
    // );
    this.form.hdlCholesterol.value = this.getAnswer(
      this.form.hdlCholesterol.id,
      this.$store.state.answers
    );
    this.form.totalHdlCholesterol.value = this.getAnswer(
      this.form.totalHdlCholesterol.id,
      this.$store.state.answers
    );
    this.form.bpSystolic.value = this.getAnswer(
      this.form.bpSystolic.id,
      this.$store.state.answers
    );
    this.form.bpDiastolic.value = this.getAnswer(
      this.form.bpDiastolic.id,
      this.$store.state.answers
    );
  },
  validations: {
    form: {
      bpSystolic: {
        value: { minValue: minValue(50), maxValue: maxValue(250) },
      },
      bpDiastolic: {
        value: { minValue: minValue(30), maxValue: maxValue(150) },
      },
      totalCholesterol: {
        value: { minValue: minValue(1.0), maxValue: maxValue(10) },
      },
      // nonHdlCholesterol: {
      //   value: { minValue: minValue(1), maxValue: maxValue(9) },
      // },
      hdlCholesterol: {
        value: { minValue: minValue(0.2), maxValue: maxValue(5) },
      },
      totalHdlCholesterol: {
        value: { minValue: minValue(1.5), maxValue: maxValue(10) },
      },
    },
  },
  methods: {
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.loading = true;
      let formDataSubmitted = await this.saveFormData();
      if (!formDataSubmitted) {
        // 2nd Attempt
        await new Promise((r) => setTimeout(r, 2000));
        formDataSubmitted = await this.saveFormData();
        if (!formDataSubmitted) {
          this.makeToast(
            "danger",
            "We have experienced a problem, please try again"
          );
          this.loading = false;
          return;
        }
      }
    },
    async saveFormData() {
      try {
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.highBloodPressure.id,
          answer: this.form.highBloodPressure.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.bloodPressureMedication.id,
          answer: this.form.bloodPressureMedication.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.bloodPressureChecked.id,
          answer: this.form.bloodPressureChecked.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.cholesterolChecked.id,
          answer: this.form.cholesterolChecked.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.cholesterolHigh.id,
          answer: this.form.cholesterolHigh.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.cholesterolMedication.id,
          answer: this.form.cholesterolMedication.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.cholesterolMedication.id,
          answer: this.form.cholesterolMedication.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.totalCholesterol.id,
          answer: this.form.totalCholesterol.value,
        });
        // await Answer.saveAnswer({
        //   session_id: this.$store.state.sessionId,
        //   question_id: this.form.nonHdlCholesterol.id,
        //   answer: this.form.nonHdlCholesterol.value,
        // });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.hdlCholesterol.id,
          answer: this.form.hdlCholesterol.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.totalHdlCholesterol.id,
          answer: this.form.totalHdlCholesterol.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.bpSystolic.id,
          answer: this.form.bpSystolic.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.bpDiastolic.id,
          answer: this.form.bpDiastolic.value,
        });
        this.loading = false;
        this.setStep(9);
        return true;
      } catch (e) {
        return false;
      }
    },
    getOptions(id, template) {
      const options = template
        .filter((template) => template.id === id)
        .map((template) => ({
          text: template.question_option,
          value: template.question_option_id,
        }));
      return options;
    },
    getAnswer(id, answers) {
      if (answers) {
        const answer = answers
          .filter((answer) => id === answer.question_id)
          .map((answer) => answer.answer);
        return answer.length > 0 ? answer[0] : null;
      }
    },
    convertWeightImperialToMetric() {
      const weightPounds = this.form.weightPounds ? this.form.weightPounds : 0;
      const weightStones = this.form.weightStones ? this.form.weightStones : 0;
      const pounds = parseInt(weightPounds) + parseInt(weightStones) * 14;
      this.form.weightKilograms = null;
      if (pounds > 0) {
        const weightKilograms = pounds / 2.2046;
        this.form.weightKilograms = Math.round(weightKilograms * 10) / 10;
      }
    },
    convertWeightMetricToImperial() {
      // No need to do anything yet
    },
    convertHeightImperialToMetric() {
      const heightInches = this.form.heightInches ? this.form.heightInches : 0;
      const heightFeet = this.form.heightFeet ? this.form.heightFeet : 0;
      const inches = parseInt(heightInches) + parseInt(heightFeet) * 12;
      this.form.heightCentimeters = null;
      if (inches > 0) {
        const heightCentimeters = inches / 0.3937;
        this.form.heightCentimeters = Math.round(heightCentimeters * 10) / 10;
      }
    },
    convertHeightMetricToImperial() {
      // No need to do anything yet
    },
    calculateBMI() {
      let heightCentimeters = 0;
      let weightKilograms = 0;
      // Do we have height?
      if (this.heightUnit === "imperial") {
        const heightInches = this.form.heightInches
          ? parseInt(this.form.heightInches)
          : 0;
        const heightFeet = this.form.heightFeet
          ? parseInt(this.form.heightFeet)
          : 0;
        if (heightFeet + heightInches === 0) {
          return false;
        }
        // convert to cm
        const inches = parseInt(heightInches) + parseInt(heightFeet) * 12;
        heightCentimeters = Math.round(inches / 0.3937);
      } // metric
      else {
        heightCentimeters = this.form.heightCentimeters
          ? parseInt(this.form.heightCentimeters)
          : 0;
        if (heightCentimeters === 0) {
          return false;
        }
      }
      if (this.weightUnit === "imperial") {
        // Do we have weight?
        const weightPounds = this.form.weightPounds
          ? parseInt(this.form.weightPounds)
          : 0;
        const weightStones = this.form.weightStones
          ? parseInt(this.form.weightStones)
          : 0;
        if (weightStones + weightPounds === 0) {
          return false;
        }
        const pounds = parseInt(weightPounds) + parseInt(weightStones) * 14;
        weightKilograms = Math.round(pounds / 2.2046);
      } else {
        // metric
        weightKilograms = this.form.weightKilograms
          ? parseInt(this.form.weightKilograms)
          : 0;
        if (weightKilograms === 0) {
          return false;
        }
      }
      const heightmeters = heightCentimeters / 100;
      // calculate BMI
      const bmi = weightKilograms / Math.pow(heightmeters, 2);
      this.bmi = Math.round(bmi * 10) / 10;
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(`${message}`, {
        title: `Error`,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
<style>
.btn.active {
  background: #6300bd !important;
}
label.btn {
  background: #e0e0e0;
  border-color: #868686;
  color: #565656;
}
</style>
