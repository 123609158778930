import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    step: 1,
    template: null,
    answers: [],
    campaign: null,
    patient: null,
    sessionId: null,
    pagenumber: 0,
  },
  mutations: {
    STEP(state, payload) {
      state.step = payload;
    },
    SETTEMPLATEANSWERS(state, payload) {
      state.template = payload;
    },
    SETANSWERS(state, payload) {
      state.answers = payload;
    },
    SETCAMPAIGN(state, payload) {
      state.campaign = payload;
    },
    SETPATIENT(state, payload) {
      state.patient = payload;
    },
    SETSESSION(state, payload) {
      state.sessionId = payload.case_session_id;
    },
    SETANSWER(state, payload) {
      const index = state.answers.findIndex(
        (answer) => answer.question_id === payload.question_id
      );

      if (index === -1) {
        state.answers.push(payload);
      } else {
        const index = state.answers.findIndex(
          (answer) => answer.question_id === payload.question_id
        );
        state.answers[index] = payload;
      }
    },
    SETPAGENUMBER(state, payload) {
      state.pagenumber = payload;
    },
  },
  actions: {
    setAnswers({ commit }, payload) {
      commit("SETANSWERS", payload);
    },
    setAnswer({ commit }, payload) {
      commit("SETANSWER", payload);
    },
    setCampaign({ commit }, payload) {
      commit("SETCAMPAIGN", payload);
    },
    setPatient({ commit }, payload) {
      commit("SETPATIENT", payload);
    },
    setSession({ commit }, payload) {
      commit("SETSESSION", payload);
    },
    setQuestionTemplate({ commit }, payload) {
      commit("SETTEMPLATEANSWERS", payload);
    },
    setStep({ commit }, payload) {
      commit("STEP", payload);
    },
    setPageNumber({ commit }, payload) {
      commit("SETPAGENUMBER", payload);
    },
  },
  modules: {},
});
