import { API } from "aws-amplify";
import store from "../store";
import { get_answers } from "./../graphql/queries";
import { update_answers_pipeline } from "./../graphql/mutations";

export async function getAnswers() {
  try {
    if (store.state.answers === null) {
      var {
        data: { get_answers: answers },
      } = await API.graphql({
        query: get_answers,
        variables: {
          sessionId: this.$store.state.sessionId,
        },
        authMode: "API_KEY",
      });
    } else {
      answers = store.state.answers;
    }
    store.dispatch("setAnswers", answers);
    return answers;
  } catch (e) {
    throw new Error(e.errors[0].message);
  }
}

export async function saveAnswer(payload) {
  var {
    data: { update_answers_pipeline: updatedAnswer },
  } = await API.graphql({
    query: update_answers_pipeline,
    variables: {
      updateAnswersInput: payload,
    },
    authMode: "API_KEY",
  });
  store.dispatch("setAnswer", updatedAnswer);
}
