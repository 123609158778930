import { API } from "aws-amplify";
import store from "../store";
import { update_case_appointment } from "./../graphql/mutations";

export async function updateCaseAppointment({ statusId }) {
  let payload = {
    patient_id: store.state.patient.patient_id,
    status_id: statusId,
  };
  var {
    data: { update_case_appointment: results },
  } = await API.graphql({
    query: update_case_appointment,
    variables: {
      updateCaseAppointmentInput: payload,
    },
    authMode: "API_KEY",
  });
  return results;
}
