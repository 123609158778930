<template>
  <b-overlay :show="loading">
    <b-form class="my-3" @submit.stop.prevent="onSubmit">
      <Breadcrumb :page="7"></Breadcrumb>
      <b-card title="Medical History" class="mb-3">
        <b-card-text>
          <div class="form-group row">
            <label class="col-sm-7 col-form-label">
              Have any of your first-degree relatives (father, mother, brother
              or sister) been diagnosed with angina, hypertension (high blood
              pressure), heart attack, stroke or type 2 diabetes?
            </label>
            <div class="col-sm-5">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.relativeWithChestHeartStroke.value"
                  :options="relativeWithChestHeartStrokeOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-7 col-form-label">
              Have you ever been diagnosed with angina, hypertension (high blood
              pressure), heart attack, stroke or type 2 diabetes?
            </label>
            <div class="col-sm-5">
              <b-button-group>
                <b-form-radio-group
                  v-model="form.diagnosedWithChestHeartStroke.value"
                  :options="diagnosedWithChestHeartStrokeOptions"
                  buttons
                ></b-form-radio-group>
              </b-button-group>
            </div>
          </div>
          <!-- <b-container class="my-2 mb-3 d-block d-sm-none">
            <p>
              Please indicate below if you, or a first degree relative (mother
              father, brother or sister) have been diagnosed with any of the
              following. We know that people with a family history of these
              condition are at an increased risk of developing coronary heart
              disease.
            </p>
            <b-row>
              <b-col></b-col>
              <b-col
                ><small><b>Personal Medical History</b></small>
              </b-col>
            </b-row>
            <b-row>
              <b-col><small>Angina</small></b-col>
              <b-col>
                <b-form-radio-group
                  v-model="form.angina1.value"
                  :options="angina1Options"
                  name="angina1-small-options"
                  size="sm"
                ></b-form-radio-group>
              </b-col>
              <div class="w-100 mb-2"></div>
              <b-col><small>Hypertension (high blood pressure)</small></b-col>
              <b-col>
                <b-form-radio-group
                  v-model="form.bp1.value"
                  :options="bp1Options"
                  name="diet1-small-options"
                  size="sm"
                ></b-form-radio-group>
              </b-col>
              <div class="w-100 mb-2"></div>
              <b-col><small>Heart Attack</small></b-col>
              <b-col>
                <b-form-radio-group
                  v-model="form.heartAttack1.value"
                  :options="heartAttack1Options"
                  name="smoking1-small-options"
                  size="sm"
                ></b-form-radio-group>
              </b-col>
              <div class="w-100 mb-2"></div>
              <b-col><small>Stroke</small></b-col>
              <b-col>
                <b-form-radio-group
                  v-model="form.stroke1.value"
                  :options="stroke1Options"
                  name="alcohol1-small-options"
                  size="sm"
                ></b-form-radio-group>
              </b-col>
              <div class="w-100 mb-2"></div>
              <b-col><small>Type 2 Diabetes</small></b-col>
              <b-col>
                <b-form-radio-group
                  v-model="form.type2Diabetes1.value"
                  :options="type2Diabetes1Options"
                  name="stress1-small-options"
                  size="sm"
                ></b-form-radio-group>
              </b-col>
              <div class="w-100"></div>
            </b-row>
            <b-row class="mt-3">
              <b-col></b-col>
              <b-col
                ><small><b>Family History</b></small>
              </b-col>
            </b-row>
            <b-row>
              <b-col><small>Angina</small></b-col>
              <b-col>
                <b-form-radio-group
                  v-model="form.angina2.value"
                  :options="angina2Options"
                  name="angina2-small-options"
                  size="sm"
                ></b-form-radio-group>
              </b-col>
              <div class="w-100 mb-2"></div>
              <b-col><small>Hypertension (high blood pressure)</small></b-col>
              <b-col>
                <b-form-radio-group
                  v-model="form.bp2.value"
                  :options="bp2Options"
                  name="diet2-small-options"
                  size="sm"
                ></b-form-radio-group>
              </b-col>
              <div class="w-100 mb-2"></div>
              <b-col><small>Heart Attack</small></b-col>
              <b-col>
                <b-form-radio-group
                  v-model="form.heartAttack2.value"
                  :options="heartAttack2Options"
                  name="smoking2-small-options"
                  size="sm"
                ></b-form-radio-group>
              </b-col>
              <div class="w-100 mb-2"></div>
              <b-col><small>Stroke</small></b-col>
              <b-col>
                <b-form-radio-group
                  v-model="form.stroke2.value"
                  :options="stroke2Options"
                  name="alcohol2-small-options"
                  size="sm"
                ></b-form-radio-group>
              </b-col>
              <div class="w-100 mb-2"></div>
              <b-col><small>Type 2 Diabetes</small></b-col>
              <b-col>
                <b-form-radio-group
                  v-model="form.type2Diabetes2.value"
                  :options="type2Diabetes2Options"
                  name="stress2-small-options"
                  size="sm"
                ></b-form-radio-group>
              </b-col>
              <div class="w-100"></div>
            </b-row>
          </b-container>

          <b-container class="my-2 mb-3 d-none d-sm-block">
            <p>
              Please indicate below if you, or a first degree relative (mother
              father, brother or sister) have been diagnosed with any of the
              following. We know that people with a family history of these
              condition are at an increased risk of developing coronary heart
              disease.
            </p>
            <b-row>
              <b-col></b-col>
              <b-col><small>Personal Medical History</small> </b-col>
              <b-col><small>Family History</small> </b-col>
            </b-row>
            <b-row>
              <b-col><small>Angina</small></b-col>
              <b-col>
                <b-form-radio-group
                  v-model="form.angina1.value"
                  :options="angina1Options"
                  name="angina1-options"
                  size="sm"
                ></b-form-radio-group>
              </b-col>
              <b-col>
                <b-form-radio-group
                  v-model="form.angina2.value"
                  :options="angina2Options"
                  name="angina2-options"
                  size="sm"
                ></b-form-radio-group>
              </b-col>
              <div class="w-100 mb-2"></div>
              <b-col><small>Hypertension (high blood pressure)</small></b-col>
              <b-col>
                <b-form-radio-group
                  v-model="form.bp1.value"
                  :options="bp1Options"
                  name="diet1-options"
                  size="sm"
                ></b-form-radio-group>
              </b-col>
              <b-col>
                <b-form-radio-group
                  v-model="form.bp2.value"
                  :options="bp2Options"
                  name="diet2-options"
                  size="sm"
                ></b-form-radio-group>
              </b-col>
              <div class="w-100 mb-2"></div>
              <b-col><small>Heart Attack</small></b-col>
              <b-col>
                <b-form-radio-group
                  v-model="form.heartAttack1.value"
                  :options="heartAttack1Options"
                  name="smoking1-options"
                  size="sm"
                ></b-form-radio-group>
              </b-col>
              <b-col>
                <b-form-radio-group
                  v-model="form.heartAttack2.value"
                  :options="heartAttack2Options"
                  name="smoking2-options"
                  size="sm"
                ></b-form-radio-group>
              </b-col>
              <div class="w-100 mb-2"></div>
              <b-col><small>Stroke</small></b-col>
              <b-col>
                <b-form-radio-group
                  v-model="form.stroke1.value"
                  :options="stroke1Options"
                  name="alcohol1-options"
                  size="sm"
                ></b-form-radio-group>
              </b-col>
              <b-col>
                <b-form-radio-group
                  v-model="form.stroke2.value"
                  :options="stroke2Options"
                  name="alcohol2-options"
                  size="sm"
                ></b-form-radio-group>
              </b-col>
              <div class="w-100 mb-2"></div>
              <b-col><small>Type 2 Diabetes</small></b-col>
              <b-col>
                <b-form-radio-group
                  v-model="form.type2Diabetes1.value"
                  :options="type2Diabetes1Options"
                  name="stress1-options"
                  size="sm"
                ></b-form-radio-group>
              </b-col>
              <b-col>
                <b-form-radio-group
                  v-model="form.type2Diabetes2.value"
                  :options="type2Diabetes2Options"
                  name="stress2-options"
                  size="sm"
                ></b-form-radio-group>
              </b-col>
              <div class="w-100"></div>
            </b-row>
          </b-container> -->
        </b-card-text>
        <b-card-footer>
          <b-button
            @click="setStep(6)"
            class="btn btn-hruk-btn"
            variant="primary"
            >Previous</b-button
          >
          <b-button
            type="submit"
            class="btn btn-hruk-btn mb-2 float-right"
            variant="primary"
            >Continue</b-button
          >
        </b-card-footer>
      </b-card>
    </b-form>
  </b-overlay>
</template>
<script>
import * as QuestionTemplate from "./../modules/QuestionTemplate";
import * as Answer from "./../modules/Answer";
// import * as CaseAppointment from "./../modules/CaseAppointment";
import Breadcrumb from "../components/Breadcrumb";
export default {
  components: {
    Breadcrumb,
  },
  data: () => ({
    form: {
      // angina1: { id: 502, value: null },
      // angina2: { id: 507, value: null },
      // bp1: { id: 503, value: null },
      // bp2: { id: 508, value: null },
      // heartAttack1: { id: 504, value: null },
      // heartAttack2: { id: 509, value: null },
      // stroke1: { id: 505, value: null },
      // stroke2: { id: 510, value: null },
      // type2Diabetes1: { id: 506, value: null },
      // type2Diabetes2: { id: 511, value: null },
      relativeWithChestHeartStroke: { id: 548, value: null },
      diagnosedWithChestHeartStroke: { id: 549, value: null },
    },
    loading: false,
    // angina1Options: null,
    // angina2Options: null,
    // bp1Options: null,
    // bp2Options: null,
    // heartAttack1Options: null,
    // heartAttack2Options: null,
    // stroke1Options: null,
    // stroke2Options: null,
    // type2Diabetes1Options: null,
    // type2Diabetes2Options: null,
    // options: [
    //   { text: "Yes", value: "yes" },
    //   { text: "No", value: "no" },
    // ],
    relativeWithChestHeartStrokeOptions: null,
    diagnosedWithChestHeartStrokeOptions: null,
  }),
  async created() {
    this.$store.dispatch("setPageNumber", 7);
    await QuestionTemplate.questionTemplate();
    await Answer.getAnswers();

    this.relativeWithChestHeartStrokeOptions = this.getOptions(
      this.form.relativeWithChestHeartStroke.id,
      this.$store.state.template
    );
    this.diagnosedWithChestHeartStrokeOptions = this.getOptions(
      this.form.diagnosedWithChestHeartStroke.id,
      this.$store.state.template
    );
    this.form.relativeWithChestHeartStroke.value = this.getAnswer(
      this.form.relativeWithChestHeartStroke.id,
      this.$store.state.answers
    );
    this.form.diagnosedWithChestHeartStroke.value = this.getAnswer(
      this.form.diagnosedWithChestHeartStroke.id,
      this.$store.state.answers
    );
    // Angina
    // this.form.angina1.value = this.getAnswer(
    //   this.form.angina1.id,
    //   this.$store.state.answers
    // );
    // this.angina1Options = this.getOptions(
    //   this.form.angina1.id,
    //   this.$store.state.template
    // );
    // this.form.angina2.value = this.getAnswer(
    //   this.form.angina2.id,
    //   this.$store.state.answers
    // );
    // this.angina2Options = this.getOptions(
    //   this.form.angina2.id,
    //   this.$store.state.template
    // );
    // // BP
    // this.form.bp1.value = this.getAnswer(
    //   this.form.bp1.id,
    //   this.$store.state.answers
    // );
    // this.bp1Options = this.getOptions(
    //   this.form.bp1.id,
    //   this.$store.state.template
    // );
    // this.form.bp2.value = this.getAnswer(
    //   this.form.bp2.id,
    //   this.$store.state.answers
    // );
    // this.bp2Options = this.getOptions(
    //   this.form.bp2.id,
    //   this.$store.state.template
    // );
    // // HeartAttack
    // this.form.heartAttack1.value = this.getAnswer(
    //   this.form.heartAttack1.id,
    //   this.$store.state.answers
    // );
    // this.heartAttack1Options = this.getOptions(
    //   this.form.heartAttack1.id,
    //   this.$store.state.template
    // );
    // this.form.heartAttack2.value = this.getAnswer(
    //   this.form.heartAttack2.id,
    //   this.$store.state.answers
    // );
    // this.heartAttack2Options = this.getOptions(
    //   this.form.heartAttack2.id,
    //   this.$store.state.template
    // );
    // // Stroke
    // this.form.stroke1.value = this.getAnswer(
    //   this.form.stroke1.id,
    //   this.$store.state.answers
    // );
    // this.stroke1Options = this.getOptions(
    //   this.form.stroke1.id,
    //   this.$store.state.template
    // );
    // this.form.stroke2.value = this.getAnswer(
    //   this.form.stroke2.id,
    //   this.$store.state.answers
    // );
    // this.stroke2Options = this.getOptions(
    //   this.form.stroke2.id,
    //   this.$store.state.template
    // );
    // // Diabetes
    // this.form.type2Diabetes1.value = this.getAnswer(
    //   this.form.type2Diabetes1.id,
    //   this.$store.state.answers
    // );
    // this.type2Diabetes1Options = this.getOptions(
    //   this.form.type2Diabetes1.id,
    //   this.$store.state.template
    // );
    // this.form.type2Diabetes2.value = this.getAnswer(
    //   this.form.type2Diabetes2.id,
    //   this.$store.state.answers
    // );
    // this.type2Diabetes2Options = this.getOptions(
    //   this.form.type2Diabetes2.id,
    //   this.$store.state.template
    // );
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      let formDataSubmitted = await this.saveFormData();
      if (!formDataSubmitted) {
        // 2nd Attempt
        await new Promise((r) => setTimeout(r, 2000));
        formDataSubmitted = await this.saveFormData();
        if (!formDataSubmitted) {
          this.makeToast(
            "danger",
            "We have experienced a problem, please try again"
          );
          this.loading = false;
          return;
        }
      }
      // this.loading = true;
      // try {
      //   await Answer.saveAnswer({
      //     session_id: this.$store.state.sessionId,
      //     question_id: this.form.relativeWithChestHeartStroke.id,
      //     answer: this.form.relativeWithChestHeartStroke.value,
      //   });
      //   await Answer.saveAnswer({
      //     session_id: this.$store.state.sessionId,
      //     question_id: this.form.diagnosedWithChestHeartStroke.id,
      //     answer: this.form.diagnosedWithChestHeartStroke.value,
      //   });
      //   this.loading = false;
      //   this.setStep(8);
      // } catch (e) {
      //   this.makeToast("danger", e.errors[0].message);
      //   this.loading = false;
      // }
    },
    async saveFormData() {
      try {
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.relativeWithChestHeartStroke.id,
          answer: this.form.relativeWithChestHeartStroke.value,
        });
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.diagnosedWithChestHeartStroke.id,
          answer: this.form.diagnosedWithChestHeartStroke.value,
        });
        this.loading = false;
        this.setStep(8);
        return true;
      } catch (e) {
        return false;
      }
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
    getOptions(id, template) {
      const options = template
        .filter((template) => template.id === id)
        .map((template) => ({
          text: template.question_option,
          value: template.question_option_id,
        }));
      return options;
    },
    getAnswer(id, answers) {
      if (answers) {
        const answer = answers
          .filter((answer) => id === answer.question_id)
          .map((answer) => answer.answer);
        return answer.length > 0 ? answer[0] : null;
      }
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(`${message}`, {
        title: `Error`,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
<style>
.btn.active {
  background: #6300bd !important;
}
</style>
