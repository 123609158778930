<template>
  <div class="my-3">
    <b-form @submit.stop.prevent="onSubmit">
      <!-- <Breadcrumb :page="3"></Breadcrumb> -->

      <b-card title="How we use your data" class="mb-3">
        <b-card-text>
          <div class="row">
            <div class="col">
              <p>
                The answers you provide will remain confidential and will be
                used to generate a personalised report, which will be sent to
                you securely by email. Heart Research UK will not share any of
                your personal information with your employer, however the
                answers you provide will be collated and summarised in an
                anonymised report, which will be used to guide your organisation
                on how to help you and your colleagues to stay healthy. None of
                the employees taking part will be identifiable in the report
                shared with your organisation.
              </p>
              <p>
                To find out more about the ways in which we process your
                personal data, you can view our privacy policy
                <a :href="privacy" target="_blank">here</a>.
              </p>
              <hr style="background-color: #6300bd" />
              <p>
                Please confirm below if you are happy for Heart Research UK to
                process your personal data in the way described above; you can
                change your mind at any time.
              </p>

              <b-form-group class="mt-3">
                <b-form-checkbox
                  id="consentPersonalData"
                  v-model="form.consentPersonalData.value"
                  name="consentPersonalData"
                  :state="validateState('consentPersonalData')"
                  trim
                >
                  <label class="form-check-label" for="consentPersonalData"
                    >I consent to Heart Research UK processing my personal data
                    including information about my health as described
                    above.</label
                  >
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <b-card-footer>
            <!-- <b-button
              @click="setStep(2)"
              class="btn btn-hruk-btn"
              variant="primary"
              >Previous</b-button
            > -->
            <b-button
              type="submit"
              class="btn btn-hruk-btn mb-2 float-right"
              variant="primary"
              >Continue</b-button
            >
          </b-card-footer>
        </b-card-text>
      </b-card>
    </b-form>
  </div>
</template>

<script>
import { sameAs } from "vuelidate/lib/validators";
import * as QuestionTemplate from "./../modules/QuestionTemplate";
import * as Answer from "./../modules/Answer";
import * as Patient from "./../modules/Patient";
export default {
  name: "yourConsent",
  components: {
    // Breadcrumb,
  },
  data() {
    return {
      form: {
        consentPersonalData: { id: 519, value: null },
      },
      privacy: `${window.location.protocol}//${window.location.host}/privacy`,
    };
  },
  async created() {
    this.$store.dispatch("setPageNumber", 3);
    this.form.consentPersonalData.value = this.getAnswerBoolean(
      this.form.consentPersonalData.id,
      this.$store.state.answers
    );
  },
  methods: {
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.loading = true;
      let formDataSubmitted = await this.saveFormData();
      if (!formDataSubmitted) {
        // 2nd Attempt
        await new Promise((r) => setTimeout(r, 2000));
        formDataSubmitted = await this.saveFormData();
        if (!formDataSubmitted) {
          this.makeToast(
            "danger",
            "We have experienced a problem, please try again"
          );
          this.loading = false;
          return;
        }
        this.$store.dispatch("setStep", 4);
      }
    },
    async saveFormData() {
      try {
        await QuestionTemplate.questionTemplate();
        if (this.$store.state.patient === null) {
          await Patient.savePatient({
            email: "",
            campaign_id: this.$store.state.campaign[0].id,
          });
        }
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.consentPersonalData.id,
          answer: this.form.consentPersonalData.value,
        });
        this.$store.dispatch("setStep", 4);
        return true;
      } catch (e) {
        return false;
      }
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
    getAnswerBoolean(id, answers) {
      if (answers) {
        const answer = answers
          .filter((answer) => id === answer.question_id)
          .map((answer) => answer.answer);
        return answer[0] === "true" ? true : false;
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(`${message}`, {
        title: `Error`,
        variant: variant,
        solid: true,
      });
    },
  },
  validations: {
    form: {
      consentPersonalData: {
        value: { sameAs: sameAs(() => true) },
      },
    },
  },
};
</script>