<template>
  <div>
    <LifestyleQuestions v-if="this.$store.state.template != null" />
    <!-- <IdleModal /> -->
    <b-modal
      ref="idle-modal"
      hide-footer
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      title="Your session is about to expire"
    >
      <div class="d-block text-center">
        <h3>If you need more time please click below</h3>
      </div>
      <b-button class="mt-3" variant="outline-danger" block @click="addTime"
        >Add {{ extraTimeLimit }} minutes</b-button
      >
    </b-modal>
  </div>
</template>

<script>
import * as QuestionTemplate from "./../modules/QuestionTemplate";
import LifestyleQuestions from "../components/LifestyleQuestions";
// import IdleModal from "../components/IdleModal";

const softLimit = 20; // How many minutes before soft limit kicks in.
const hardLimit = 5; // How many more minutes for the hard limit.
const extraTimeLimit = 5; // How many minutes to add to the current time from the popup.
const hardLimitUrl = "/timeout"; // Url to redirect to on hard limit reached.
const timerCheck = 5; // How many seconds between each 'limit check' ( Also checks when window comes into focus ).
const lastStep = 20; // Last step ( timer only works if step is BELOW this )

export default {
  name: "Questionnaire",

  components: {
    LifestyleQuestions,
    // IdleModal,
  },
  async created() {
    await QuestionTemplate.questionTemplate();
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    this.$bvModal
      .msgBoxConfirm(
        "If you exit this page, your progress will be lost. Do you wish to proceed?",
        {
          title: "Warning",
          // size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        }
      )
      .then((value) => {
        if (value) {
          next(); // Yes
        } else {
          next(false); // No
        }
      })
      .catch((err) => {
        console.log(err);
        // An error occurred
      });
  },

  data: function () {
    return {
      timeCheck: null,
      softLimit: 0,
      extraTimeLimit: extraTimeLimit,
      leaveSite: true,
    };
  },

  computed: {
    hardLimit() {
      // Auto-Calculate.
      return this.softLimit + hardLimit * 60;
    },

    step() {
      return this.$store.state.step;
    },
  },

  methods: {
    getTimestamp() {
      return Math.floor(Date.now() / 1000);
    },

    handleVisibility(e) {
      if (document.visibilityState === "hidden") {
        // Tab now hidden, do nothing.
      } else {
        this.checkLimits(); // Tab now visible, checkLimits.
      }
    },

    checkLimits() {
      //console.log('checkLimits'); // For debugging, log to console everytime this check is called.
      if (this.step < lastStep) {
        let currentTime = this.getTimestamp(); // Get the current timestamp.
        if (currentTime > this.hardLimit) {
          // Hard limit reached, redirect.
          this.leaveSite = false;
          window.location.href = hardLimitUrl;
        } else if (currentTime > this.softLimit) {
          // Soft limit reached, Show the modal.
          this.$refs["idle-modal"].show();
        }
      } else {
        //console.log('On complete step.')
      }
    },
    addTime() {
      // Update softLimit and close the modal.
      let currentTime = this.getTimestamp(); // Get the current timestamp.
      this.softLimit = currentTime + extraTimeLimit * 60;
      this.$refs["idle-modal"].hide(); // Hide the modal.
    },
    preventNav(event) {
      if (this.leaveSite) {
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = "";
      }
    },
  },

  mounted() {
    let currentTime = this.getTimestamp(); // Get the current timestamp.
    this.softLimit = currentTime + softLimit * 60; // Set the soft limit.

    document.addEventListener("visibilitychange", this.handleVisibility, false); // Add the event.
    this.timeCheck = setInterval(this.checkLimits, timerCheck * 1000); // Add the timer.

    // console.log(currentTime, this.softLimit, this.hardLimit); // For debugging, log to console the times.
  },

  beforeDestroy() {
    // Remove the event and the timer.
    document.removeEventListener("visibilitychange", this.handleVisibility);
    if (this.timeCheck !== null) {
      clearInterval(this.timeCheck);
    }
  },
};
</script>
