<template>
  <b-overlay :show="loading">
    <b-form class="my-3" @submit.stop.prevent="onSubmit">
      <Breadcrumb :page="18"></Breadcrumb>
      <b-card title="Your lifestyle concerns" class="mb-3">
        <b-card-text>
          <!-- <b-container class="mb-3 d-block d-sm-none">
            <p>
              <i
                >Please rank, in order of importance from 1 to 5 (with 1 being
                the most important), the lifestyle concern(s) which you would
                most like to address.</i
              >
            </p>
            <b-row>
              <b-col></b-col>
              <b-col
                ><small><b>Readiness</b></small>
                <div>
                  <b-modal
                    id="modal-feel-ready-to-change-scores"
                    centered
                    title="What do the scores mean?"
                    ok-only
                  >
                    <p>
                      (1= fully ready; 10 = Not at all ready or ‘I do not need
                      to change this area of my lifestyle')
                    </p>
                  </b-modal>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col><small>Diet</small></b-col>
              <b-col
                ><b-form-select
                  v-model="form.dietReadinessToChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <div class="w-100"></div>
              <b-col><small>Physical Activity</small></b-col>
              <b-col>
                <b-form-select
                  v-model="form.activityReadinessToChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select>
              </b-col>
              <div class="w-100"></div>
              <b-col><small>Smoking</small></b-col>
              <b-col
                ><b-form-select
                  v-model="form.smokingReadinessToChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <div class="w-100"></div>
              <b-col><small>Alcohol consumption</small></b-col>
              <b-col
                ><b-form-select
                  v-model="form.alcoholReadinessToChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <div class="w-100"></div>
              <b-col><small>Stress</small></b-col>
              <b-col
                ><b-form-select
                  v-model="form.stressReadinessToChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <div class="w-100"></div>
            </b-row>

            <b-row class="mt-3">
              <b-col></b-col>
              <b-col
                ><small><b>Confidence</b></small>
              </b-col>
            </b-row>
            <b-row>
              <b-col><small>Diet</small></b-col>
              <b-col
                ><b-form-select
                  v-model="form.dietConfidentToChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <div class="w-100"></div>
              <b-col><small>Physical Activity</small></b-col>
              <b-col
                ><b-form-select
                  v-model="form.activityConfidentToChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <div class="w-100"></div>
              <b-col><small>Smoking</small></b-col>
              <b-col
                ><b-form-select
                  v-model="form.smokingConfidentToChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <div class="w-100"></div>
              <b-col><small>Alcohol consumption</small></b-col>
              <b-col
                ><b-form-select
                  v-model="form.alcoholConfidentToChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <div class="w-100"></div>
              <b-col><small>Stress</small></b-col>
              <b-col
                ><b-form-select
                  v-model="form.stressConfidentToChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <div class="w-100"></div>
            </b-row>
            <b-row class="mt-3">
              <b-col></b-col>
              <b-col
                ><small><b>Importance</b></small>
                <div>
                  <b-modal
                    id="modal-mobile-important-to-change-confidence-scores"
                    centered
                    title="What do the scores mean?"
                    ok-only
                  >
                    <p>
                      (1= very important; 10 = Not important at all' or 'I do
                      not need to change this area of my lifestyle')
                    </p>
                  </b-modal>
                </div></b-col
              >
            </b-row>
            <b-row>
              <b-col><small>Diet</small></b-col>
              <b-col
                ><b-form-select
                  v-model="form.dietImportanceOfChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <div class="w-100"></div>
              <b-col><small>Physical Activity</small></b-col>
              <b-col
                ><b-form-select
                  v-model="form.activityImportanceOfChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <div class="w-100"></div>
              <b-col><small>Smoking</small></b-col>
              <b-col
                ><b-form-select
                  v-model="form.smokingImportanceOfChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <div class="w-100"></div>
              <b-col><small>Alcohol consumption</small></b-col>
              <b-col
                ><b-form-select
                  v-model="form.alcoholImportanceOfChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <div class="w-100"></div>
              <b-col><small>Stress</small></b-col>
              <b-col
                ><b-form-select
                  v-model="form.stressImportanceOfChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <div class="w-100"></div>
            </b-row>
          </b-container>

          <b-container class="mt-2 mb-3 d-none d-sm-block">
            <p>
              <i
                >Please rank, in order of importance from 1 to 5 (with 1 being
                the most important), the lifestyle concern(s) which you would
                most like to address.</i
              >
            </p>
            <b-row>
              <b-col></b-col>
              <b-col
                ><small><b>Readiness</b></small>
                <div>
                  <b-modal
                    id="modal-mobile-feel-ready-to-change-scores"
                    centered
                    title="What do the scores mean?"
                    ok-only
                  >
                    <p>
                      (1= fully ready; 10 = Not at all ready or 'I do not need
                      to change this area of my lifestyle')
                    </p>
                  </b-modal>
                </div>
              </b-col>
              <b-col
                ><small><b>Confidence</b></small>
                <div>
                  <b-modal
                    id="modal-ready-to-change-confidence-scores"
                    centered
                    title="What do the scores mean?"
                    ok-only
                  >
                    <p>
                      (1= very confident; 10 = Not at all confident; or 'I do
                      need to change this area of my lifestyle')
                    </p>
                  </b-modal>
                </div>
              </b-col>
              <b-col
                ><small><b>Importance</b></small>
                <div>
                  <b-modal
                    id="modal-important-to-change-confidence-scores"
                    centered
                    title="What do the scores mean?"
                    ok-only
                  >
                    <p>
                      (1= very important; 10 = Not important at all; or 'I do
                      not need to change this area of my lifestyle')
                    </p>
                  </b-modal>
                </div></b-col
              >
            </b-row>
            <b-row>
              <b-col><small>Diet</small></b-col>
              <b-col
                ><b-form-select
                  v-model="form.dietReadinessToChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <b-col
                ><b-form-select
                  v-model="form.dietConfidentToChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <b-col
                ><b-form-select
                  v-model="form.dietImportanceOfChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <div class="w-100"></div>
              <b-col><small>Physical Activity</small></b-col>
              <b-col
                ><b-form-select
                  v-model="form.activityReadinessToChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <b-col
                ><b-form-select
                  v-model="form.activityConfidentToChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <b-col
                ><b-form-select
                  v-model="form.activityImportanceOfChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <div class="w-100"></div>
              <b-col><small>Smoking</small></b-col>
              <b-col
                ><b-form-select
                  v-model="form.smokingReadinessToChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <b-col
                ><b-form-select
                  v-model="form.smokingConfidentToChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <b-col
                ><b-form-select
                  v-model="form.smokingImportanceOfChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <div class="w-100"></div>
              <b-col><small>Alcohol consumption</small></b-col>
              <b-col
                ><b-form-select
                  v-model="form.alcoholReadinessToChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <b-col
                ><b-form-select
                  v-model="form.alcoholConfidentToChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <b-col
                ><b-form-select
                  v-model="form.alcoholImportanceOfChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <div class="w-100"></div>
              <b-col><small>Stress</small></b-col>
              <b-col
                ><b-form-select
                  v-model="form.stressReadinessToChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <b-col
                ><b-form-select
                  v-model="form.stressConfidentToChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <b-col
                ><b-form-select
                  v-model="form.stressImportanceOfChange.value"
                  :options="activityReadinessToChangeOptions"
                ></b-form-select
              ></b-col>
              <div class="w-100"></div>
            </b-row>
          </b-container> -->
          <div class="row">
            <div class="col form-group">
              <b-form-group
                label="Please select the lifestyle concern(s) which you would like to
                address"
              >
                <b-form-checkbox-group
                  v-model="form.lifestyleConcern.value"
                  :options="lifestyleConcernOptions"
                  stacked
                  multiple
                  @change="onChange"
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>
          <div class="row" v-if="!form.lifestyleConcern.value.includes(452)">
            <div
              class="col form-group"
              v-if="form.lifestyleConcern.value.length > 0"
            >
              <label for="otherFoodHealthy"
                >Out of these which is the lifestyle concern you would most like
                to address</label
              >
              <!-- <b-form-input
                v-model="form.lifestyleConcernOther.value"
                maxlength="255"
                placeholder="Lifestyle concern"
              ></b-form-input> -->
              <select
                class="custom-select"
                v-model="form.lifestyleConcernOther.value"
              >
                <option
                  v-for="option in lifestyleConcernSelectedOptions"
                  :key="option.text"
                  :value="option.text"
                >
                  {{ option.text }}
                </option>
              </select>
              <!-- <b-form-select
                v-model="form.lifestyleConcernOther.value"
                :options="[
                  { text: 'Diet', value: 'Diet' },
                  { text: 'Physical activity', value: 'Physical activity' },
                  { text: 'Smoking', value: 'Smoking' },
                  { text: 'Alcohol', value: 'Alcohol' },
                  { text: 'Stress', value: 'Stress' },
                ]"
              ></b-form-select> -->
            </div>
          </div>
          <!-- <hr style="background-color: #6300bd" />
          <h4>You can be part of the next big breakthrough</h4>
          <p>
            We would like to keep in touch and send you updates about how our
            work is preventing heart disease and improving the lives of those
            affected. We can't do what we do without you, and we would love to
            keep you updated with what we do and other ways you can support
            Heart Research UK.
          </p> -->
        </b-card-text>
        <b-card-footer>
          <b-button
            @click="setStep(17)"
            class="btn btn-hruk-btn"
            variant="primary"
            >Previous</b-button
          >
          <b-button
            type="submit"
            class="btn btn-hruk-btn mb-2 float-right"
            variant="primary"
            >Continue</b-button
          >
        </b-card-footer>
      </b-card>
    </b-form>
  </b-overlay>
</template>
<script>
import * as QuestionTemplate from "./../modules/QuestionTemplate";
import * as Answer from "./../modules/Answer";
import Breadcrumb from "../components/Breadcrumb";
export default {
  components: {
    Breadcrumb,
  },
  data: () => ({
    form: {
      // activityReadinessToChange: { id: 486, value: null },
      // activityConfidentToChange: { id: 491, value: null },
      // activityImportanceOfChange: { id: 496, value: null },
      // dietReadinessToChange: { id: 487, value: null },
      // dietConfidentToChange: { id: 492, value: null },
      // dietImportanceOfChange: { id: 497, value: null },
      // smokingReadinessToChange: { id: 488, value: null },
      // smokingConfidentToChange: { id: 493, value: null },
      // smokingImportanceOfChange: { id: 498, value: null },
      // alcoholReadinessToChange: { id: 489, value: null },
      // alcoholConfidentToChange: { id: 494, value: null },
      // alcoholImportanceOfChange: { id: 499, value: null },
      // stressReadinessToChange: { id: 490, value: null },
      // stressConfidentToChange: { id: 495, value: null },
      // stressImportanceOfChange: { id: 500, value: null },
      lifestyleConcern: { id: 547, value: [] },
      lifestyleConcernOther: { id: 547, value: null },
    },
    // activityReadinessToChangeOptions: null,
    // activityConfidentToChangeOptions: null,
    // activityImportanceOfChangeOptions: null,
    // dietReadinessToChangeOptions: null,
    // dietConfidentToChangeOptions: null,
    // dietImportanceOfChangeOptions: null,
    // smokingReadinessToChangeOptions: null,
    // smokingConfidentToChangeOptions: null,
    // smokingImportanceOfChangeOptions: null,
    // alcoholReadinessToChangeOptions: null,
    // alcoholConfidentToChangeOptions: null,
    // alcoholImportanceOfChangeOptions: null,
    // stressReadinessToChangeOptions: null,
    // stressConfidentToChangeOptions: null,
    // stressImportanceOfChangOptions: null,
    lifestyleConcernOptions: null,
    lifestyleConcernSelectedOptions: null,
    loading: false,
  }),
  async created() {
    this.$store.dispatch("setPageNumber", 18);
    await QuestionTemplate.questionTemplate();
    await Answer.getAnswers();

    // this.activityReadinessToChangeOptions = this.getOptions(
    //   this.form.activityReadinessToChange.id,
    //   this.$store.state.template
    // );
    // this.form.activityReadinessToChange.value = this.getAnswer(
    //   this.form.activityReadinessToChange.id,
    //   this.$store.state.answers
    // );
    // this.activityConfidentToChangeOptions = this.getOptions(
    //   this.form.activityConfidentToChange.id,
    //   this.$store.state.template
    // );
    // this.form.activityConfidentToChange.value = this.getAnswer(
    //   this.form.activityConfidentToChange.id,
    //   this.$store.state.answers
    // );
    // this.activityImportanceOfChangeOptions = this.getOptions(
    //   this.form.activityImportanceOfChange.id,
    //   this.$store.state.template
    // );
    // this.form.activityImportanceOfChange.value = this.getAnswer(
    //   this.form.activityImportanceOfChange.id,
    //   this.$store.state.answers
    // );
    // this.dietReadinessToChangeOptions = this.getOptions(
    //   this.form.dietReadinessToChange.id,
    //   this.$store.state.template
    // );
    // this.form.dietReadinessToChange.value = this.getAnswer(
    //   this.form.dietReadinessToChange.id,
    //   this.$store.state.answers
    // );
    // this.dietConfidentToChangeOptions = this.getOptions(
    //   this.form.dietConfidentToChange.id,
    //   this.$store.state.template
    // );
    // this.form.dietConfidentToChange.value = this.getAnswer(
    //   this.form.dietConfidentToChange.id,
    //   this.$store.state.answers
    // );
    // this.dietImportanceOfChangeOptions = this.getOptions(
    //   this.form.dietImportanceOfChange.id,
    //   this.$store.state.template
    // );
    // this.form.dietImportanceOfChange.value = this.getAnswer(
    //   this.form.dietImportanceOfChange.id,
    //   this.$store.state.answers
    // );
    // this.smokingReadinessToChangeOptions = this.getOptions(
    //   this.form.smokingReadinessToChange.id,
    //   this.$store.state.template
    // );
    // this.form.smokingReadinessToChange.value = this.getAnswer(
    //   this.form.smokingReadinessToChange.id,
    //   this.$store.state.answers
    // );
    // this.smokingConfidentToChangeOptions = this.getOptions(
    //   this.form.smokingConfidentToChange.id,
    //   this.$store.state.template
    // );
    // this.form.smokingConfidentToChange.value = this.getAnswer(
    //   this.form.smokingConfidentToChange.id,
    //   this.$store.state.answers
    // );
    // this.smokingImportanceOfChangeOptions = this.getOptions(
    //   this.form.smokingImportanceOfChange.id,
    //   this.$store.state.template
    // );
    // this.form.smokingImportanceOfChange.value = this.getAnswer(
    //   this.form.smokingImportanceOfChange.id,
    //   this.$store.state.answers
    // );
    // this.alcoholReadinessToChangeOptions = this.getOptions(
    //   this.form.alcoholReadinessToChange.id,
    //   this.$store.state.template
    // );
    // this.form.alcoholReadinessToChange.value = this.getAnswer(
    //   this.form.alcoholReadinessToChange.id,
    //   this.$store.state.answers
    // );
    // this.alcoholConfidentToChangeOptions = this.getOptions(
    //   this.form.alcoholConfidentToChange.id,
    //   this.$store.state.template
    // );
    // this.form.alcoholConfidentToChange.value = this.getAnswer(
    //   this.form.alcoholConfidentToChange.id,
    //   this.$store.state.answers
    // );
    // this.alcoholImportanceOfChangeOptions = this.getOptions(
    //   this.form.alcoholImportanceOfChange.id,
    //   this.$store.state.template
    // );
    // this.form.alcoholImportanceOfChange.value = this.getAnswer(
    //   this.form.alcoholImportanceOfChange.id,
    //   this.$store.state.answers
    // );
    // this.stressReadinessToChangeOptions = this.getOptions(
    //   this.form.stressReadinessToChange.id,
    //   this.$store.state.template
    // );
    // this.form.stressReadinessToChange.value = this.getAnswer(
    //   this.form.stressReadinessToChange.id,
    //   this.$store.state.answers
    // );
    // this.stressConfidentToChangeOptions = this.getOptions(
    //   this.form.stressConfidentToChange.id,
    //   this.$store.state.template
    // );
    // this.form.stressConfidentToChange.value = this.getAnswer(
    //   this.form.stressConfidentToChange.id,
    //   this.$store.state.answers
    // );
    // this.stressImportanceOfChangeOptions = this.getOptions(
    //   this.form.stressImportanceOfChange.id,
    //   this.$store.state.template
    // );
    // this.form.stressImportanceOfChange.value = this.getAnswer(
    //   this.form.stressImportanceOfChange.id,
    //   this.$store.state.answers
    // );
    this.lifestyleConcernOptions = this.getOptions(
      this.form.lifestyleConcern.id,
      this.$store.state.template
    );
    this.form.lifestyleConcern.value = this.getMultiSelectAnswer(
      this.form.lifestyleConcern.id,
      this.$store.state.answers
    );
    this.form.lifestyleConcernOther.value = this.getAnswerDetails(
      this.form.lifestyleConcernOther.id,
      this.$store.state.answers
    );
    this.onChange();
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      let formDataSubmitted = await this.saveFormData();
      if (!formDataSubmitted) {
        // 2nd Attempt
        await new Promise((r) => setTimeout(r, 2000));
        formDataSubmitted = await this.saveFormData();
        if (!formDataSubmitted) {
          this.makeToast(
            "danger",
            "We have experienced a problem, please try again"
          );
          this.loading = false;
          return;
        }
      }
      // try {
      //   await Answer.saveAnswer({
      //     session_id: this.$store.state.sessionId,
      //     question_id: this.form.lifestyleConcern.id,
      //     answer: this.form.lifestyleConcern.value,
      //     details: this.form.lifestyleConcernOther.value,
      //   });
      //   this.loading = false;
      //   this.setStep(19);
      // } catch (e) {
      //   this.makeToast("danger", e.errors[0].message);
      //   this.loading = false;
      // }
    },
    async saveFormData() {
      try {
        await Answer.saveAnswer({
          session_id: this.$store.state.sessionId,
          question_id: this.form.lifestyleConcern.id,
          answer: this.form.lifestyleConcern.value,
          details: this.form.lifestyleConcernOther.value,
        });
        this.loading = false;
        this.setStep(19);
        return true;
      } catch (e) {
        return false;
      }
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
    getOptions(id, template) {
      const options = template
        .filter((template) => template.id === id)
        .map((template) => ({
          text: template.question_option,
          value: template.question_option_id,
        }));
      return options;
    },
    getAnswer(id, answers) {
      if (answers) {
        const answer = answers
          .filter((answer) => id === answer.question_id)
          .map((answer) => answer.answer);
        return answer.length > 0 ? answer[0] : null;
      }
    },
    getMultiSelectAnswer(id, answers) {
      const answer = answers
        .filter((answer) => id === answer.question_id && answer.answer !== null)
        .map((answer) => answer.answer);
      return answer.length > 0 ? JSON.parse(answer[0]) : [];
    },
    getAnswerDetails(id, answers) {
      const answer = answers
        .filter((answer) => id === answer.question_id)
        .map((answer) => answer.details);
      return answer.length > 0 ? answer[0] : null;
    },
    onChange() {
      if (this.form.lifestyleConcern.value.includes(452)) {
        this.form.lifestyleConcern.value = [452];
      } else {
        this.lifestyleConcernSelectedOptions =
          this.lifestyleConcernOptions.filter((lifestyleConcern) =>
            this.form.lifestyleConcern.value.includes(lifestyleConcern.value)
          );
      }
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(`${message}`, {
        title: `Error`,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
