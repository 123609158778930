import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Questionnaire from "../views/Questionnaire.vue";
import Terms from "../views/Terms.vue";
import Privacy from "../views/Privacy.vue";
import Timeout from "../views/Timeout.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/questionnaire/:code",
    name: "Questionnaire",
    component: Questionnaire,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/timeout",
    name: "Timeout",
    component: Timeout,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
